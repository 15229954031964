import "./Home.scss";

import React, { useEffect } from "react";

import herobg from "../../assets/images/herobg.jpg";
import StudentDash from "../../assets/images/StudentDash.png";
import exam from "../../assets/images/exam.png";
import pay from "../../assets/images/pay.png";
import study from "../../assets/images/study.png";
import adminDash from "../../assets/images/adminDash.png";
import information from "../../assets/images/information.png";
import chart from "../../assets/images/chart.png";
import database from "../../assets/images/database.png";
import { ReactComponent as Amsillustration } from "../../assets/images/amsillustration.svg";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const amsStudentsFeatures = [
  {
    id: 1,
    image: StudentDash,
    data: "An overall view of your academics at one place on your AMS dashboard.",
  },
  {
    id: 2,
    image: exam,
    data: "Login and view all exam related information in your AMS account, exam section.",
  },
  {
    id: 3,
    image: pay,
    data: "Check the status of your fees and make payments online through the portal.",
  },
  {
    id: 4,
    image: study,
    data: "Get recommended study material, important links to aid in your academic activities.",
  },
];

const amsAdminFeatures = [
  {
    id: 1,
    image: adminDash,
    data: "Powerful dashboard to get an overall view and manage the administration of the academy.",
  },
  {
    id: 2,
    image: information,
    data: "Simplify the admission process with digital applications for enquiries and scholarship.",
  },
  {
    id: 3,
    image: chart,
    data: "Generate comprehensive reports for admissions, management, financials, and more.",
  },
  {
    id: 4,
    image: database,
    data: "Centralized data from every departments. Store and manage all institutional data securely and access it conveniently.",
  },
];

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
    if (tokens) {
      const decodedToken = tokens && jwtDecode(tokens.accessToken);
      const role_id = decodedToken && decodedToken.role_id;

      if (role_id && role_id === 10) {
        navigate("/student");
      } else {
        navigate("/admin");
      }
    }
  }, []);

  const loginClick = () => {
    navigate("/login");
  };
  const enquiryClick = () => {
    navigate("/enquiry");
  };
  const aboutClick = () => {
    navigate("/about");
  };
  return (
    <div className="home">
      {/* Hero Section */}
      <div className="home-hero" style={{ backgroundImage: `url(${herobg})` }}>
        <div className="home-hero-name">Chaitainya's</div>
        <div className="home-hero-title">Academy Management System (AMS)</div>
        <div className="home-hero-text">
          Dive into a seamless digital experience with AMS - your all-in-one
          Academy Management System, designed to simplify, innovate, and elevate
          the educational journey in Chaitanya’s Academy.
        </div>
        <div className="home-hero-button">
          <Button text="Enquiry" onClick={enquiryClick} />
        </div>
      </div>

      {/* Intro section */}
      <div className="home-intro">
        <div className="home-intro-left">
          <Amsillustration />
        </div>
        <div className="home-intro-right">
          <div className="home-intro-right-ques">
            What is Academy Management System?
          </div>
          <div className="home-intro-right-ans">
            AMS is a next-gen Enterprise Resource Planning (ERP) solution
            tailored specifically for Chaitanya’s Academy, an easy-to-use
            platform that caters to both students and administrators. It is
            crafted to streamline administrative operations, enhance learning
            experiences, and foster a robust communication channel within the
            educational ecosystem.
          </div>
          <div className="home-intro-right-button">
            <Button text="Login" onClick={loginClick} />
          </div>
        </div>
      </div>

      {/* Students Section */}
      <div className="home-students">
        <div className="home-students-left">
          <hr />
          <div className="home-students-left-title">AMS for Students</div>
          <div className="home-students-left-text">
            Elevate Your Learning Experience with AMS, a platform designed to
            show you an overall picture of your academics and help you with your
            everyday activities.
          </div>
        </div>
        <div className="home-students-right">
          <div className="home-students-right-cards">
            {amsStudentsFeatures.map((feature) => (
              <div className="home-students-right-cards-card">
                <FeatureCard
                  id={feature.id}
                  image={feature.image}
                  data={feature.data}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Admins Section */}
      <div className="home-admins">
        <div className="home-admins-left">
          <div className="home-admins-left-cards">
            {amsAdminFeatures.map((feature) => (
              <div className="home-admins-left-cards-card">
                <FeatureCard
                  id={feature.id}
                  image={feature.image}
                  data={feature.data}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="home-admins-right">
          <hr />
          <div className="home-admins-right-title">AMS for admins</div>
          <div className="home-admins-right-text">
            Elevate Your Learning Experience with AMS, a platform designed to
            show you an overall picture of your academics and help you with your
            everyday activities.
          </div>
        </div>
      </div>

      {/* Visitors Section */}

      <div className="home-visitors">
        <div className="home-visitors-ques">New to Chaitainya’s Academy?</div>
        <div className="home-visitors-ans">
          Hello visitor, Welcome to next-gen digital management system of
          Chaitanya’s Academy. We are happy to have you here. Are you looking
          for a brighter future? Your search ends here. Chaitainya’s Academy has
          world class teachers, best teaching methodologies, lavish
          infrastructure and a next generation AMS for students and Faculty.
          Reach out to us for more information.
        </div>
        <div className="home-visitors-buttons">
          <Button text={`Enquiry`} onClick={enquiryClick} />
          <Button text={`About Us`} type="arrow" onClick={aboutClick} />
        </div>
      </div>
    </div>
  );
};

export default Home;

const FeatureCard = ({ image, data }) => {
  return (
    <div className="featureCard">
      <div className="featureCard-image">
        <img src={image} alt="icon8" />
      </div>
      <div className="featureCard-text">{data}</div>
    </div>
  );
};
