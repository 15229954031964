// DatePicker.js
import React, { useState } from "react";
import "./DatePicker.scss";

function DatePicker({ invalid, label, value, onChange }) {
  const [selected, setselected] = useState(false);
  return (
    <>
      <div className="dropdown-label">{label}</div>
      <div className={invalid ? "datepicker-invalid datepicker" : "datepicker"}>
        {selected ? (
          <input
            className="datepicker-selected"
            type="date"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        ) : (
          <input
            className="datepicker-unselected"
            type="date"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              setselected(true);
            }}
          />
        )}
        {/* <input
      className="datepicker-selected"
        type="date"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      /> */}
        {/* <span className="icon">📅</span> */}
      </div>
    </>
  );
}

export default DatePicker;
