import React from "react";
import "./DataTable.scss"; // Ensure to create and import this SCSS file
import { useNavigate } from "react-router-dom";

const DataTable = ({ data, navigateBaseUrl, sortOrder, setSortOrder }) => {
  const navigate = useNavigate();

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };
  return (
    <div className="enquiry-table">
      {/* <div className="enquiry-table-header">
        <div className="header-id">Id</div>
        <div className="header-name">Name</div>
        <div className="header-whatsapp">WhatsApp</div>
        <div className="header-class">Admission in Class</div>
        <div
          className="header-timestamp"
          onClick={() => setSortOrder(sortOrder === "desc" ? "asc" : "desc")}
        >
          TimeStamp {sortOrder === "desc" ? " ↑" : " ↓"}
        </div>
      </div> */}
      {data.map((item) => (
        <div
          key={item.id}
          className="enquiry-row"
          onClick={() => navigate(`${navigateBaseUrl}/${item.id}`)}
        >
          <div className="row-id">{item.id}</div>
          <div className="row-name">{item.full_name}</div>
          <div className="row-whatsapp">{item.whatsapp}</div>
          <div className="row-class">{item.admission_for_class}</div>
          <div className="row-timestamp">
            {formatDateAndTime(item.timestamp)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DataTable;
