import React, { useState, useEffect } from "react";
// import SubjectForm from "./SubjectForm";
// import SubjectList from "./SubjectList";
import "./Documents.scss"; // Assuming you have a corresponding SCSS file
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import DropDown from "../../components/DropDown/DropDown";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Documents = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Management")
    ?.dropdowns.find((dd) => dd.name === "Documents")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  // const [name, setname] = useState(null);
  // const [content_for, setcontent_for] = useState(null);
  // const [file, setfile] = useState(null);
  const [documents, setdocuments] = useState([]);
  const [alldocuments, setalldocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [contentForFilter, setcontentForFilter] = useState(null);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Fetch documents from API
  useEffect(() => {
    fetchdocuments();
  }, []);

  // useEffect(() => {
  //   if (!selectedItem) {
  //     return;
  //   }

  //   setname(selectedItem.name);
  //   setcontent_for(selectedItem.content_for);
  // }, [selectedItem]);

  useEffect(() => {
    setSelectedItem(null);
    applyFilters();
  }, [contentForFilter]);

  const applyFilters = () => {
    let filteredDocs = [...alldocuments];

    // Apply status filter
    if (contentForFilter && contentForFilter !== "All") {
      filteredDocs = filteredDocs.filter(
        (product) => product.content_for === contentForFilter
      );
    }

    setdocuments(filteredDocs); // Update the displayed products based on filters
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchdocuments = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await axios.get("http://localhost:8000/api/documents", {
      const response = await axios.get(`${BE_URL}/api/documents`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setalldocuments(response.data);
      setdocuments(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchdocuments(); // Retry fetching documents with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch documents.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (formData) => {
    setIsLoading(true);
    setError(null);
    setisAddModalOpen(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const config = {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
          "Content-Type": "multipart/form-data", // Important for sending files
        },
      };

      const response = await axios.post(
        `${BE_URL}/api/documents`,
        formData,
        config
      );

      fetchdocuments();

      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(formData); // Retry adding the document with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to add. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
      setisAddModalOpen(false);
    }
  };

  // const prepareAndHandleEdit = () => {
  //   const formData = new FormData();
  //   formData.append("name", name);
  //   formData.append("content_for", content_for);

  //   // Only append the file if a new one has been selected
  //   if (file) {
  //     formData.append("file", file);
  //   }

  //   // Assume `selectedItem.id` is the ID of the document being edited
  //   handleEdit(selectedItem.id, formData);
  // };

  // const handleEdit = async (id, formData) => {
  //   setIsLoading(true);
  //   setError(null);

  //   try {
  //     const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${tokens.accessToken}`,
  //         // Don't set 'Content-Type' to 'multipart/form-data' manually; let Axios do it
  //       },
  //     };

  //     const response = await axios.put(
  //       `${BE_URL}/api/documents/${id}`,
  //       formData,
  //       config
  //     );

  //     // Update the documents in state with the new data
  //     const updatedDocuments = documents.map((doc) =>
  //       doc.id === id ? response.data : doc
  //     );
  //     setdocuments(updatedDocuments);
  //     setSelectedItem(response.data); // Optionally update the selected item
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       const newAccessToken = await refreshToken();
  //       if (newAccessToken) {
  //         return handleEdit(id, formData); // Retry with new token
  //       } else {
  //         setError("Authentication failed. Please log in again.");
  //       }
  //     } else {
  //       setError(error.message || "Failed to update document.");
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleDelete = async (subjectId) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(`${BE_URL}/api/documents/${selectedItem.id}`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      // Remove the deleted subject from the documents list
      fetchdocuments();
      setSelectedItem(null);

      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(subjectId); // Retry deleting subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="halfpage">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="halfpage-header">
            <div className="halfpage-header-name">Documents</div>
            <div className="halfpage-header-filters">
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Content For</div>
                <DropDown
                  options={["All", "Everyone", "Staff", "Students"]}
                  value={contentForFilter}
                  onChange={(value) => setcontentForFilter(value)}
                />
              </div>
            </div>
          </div>
          <div className="halfpage-contents">
            <div className="halfpage-contents-list">
              <HalfList
                data={documents}
                fields={["name", "content_for"]}
                setSelectedItem={setSelectedItem}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                elem={
                  <AddDocument
                    handleAdd={(newDivision) => handleAdd(newDivision)}
                  />
                }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="halfpage-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={selectedItem.name}
                    // handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    can_delete={permissions?.can_delete}
                    can_update={false}
                  >
                    <DetailsChild selectedItem={selectedItem}></DetailsChild>
                  </HalfDetails>
                </div>
              ) : (
                <div className="halfpage-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Documents;

const AddDocument = ({ handleAdd }) => {
  const [name, setName] = useState("");
  const [contentFor, setContentFor] = useState("");
  const [file, setFile] = useState(null);

  const handleSubmit = () => {
    if (!name || !contentFor || !file) {
      alert("All fields are mandatory!");
      return;
    }
    // Use FormData to accommodate file data
    const formData = new FormData();
    formData.append("name", name);
    formData.append("content_for", contentFor);
    formData.append("file", file); // Assuming your backend is set up to handle 'file'

    handleAdd(formData); // Pass formData instead of JSON
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add a Document</div>
      <CustomTextInput
        label="Document Name"
        placeholder="Document Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <ObjectDropDown
        label="Content For"
        options={[
          { label: "Everyone", value: "Everyone" },
          { label: "Students", value: "Students" },
          { label: "Staff", value: "Staff" },
        ]}
        setId={() => {}}
        value={contentFor}
        setValue={setContentFor}
      />

      <input
        label="Select File"
        placeholder="Select File"
        type="file"
        onChange={(e) => setFile(e.target.files[0])}
      />
      <Button text="Add Document" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({ selectedItem }) => {
  return (
    <div className="dc">
      <div className="dc-text">
        <div className="dc-text-label">File Name</div>
        <div className="dc-text-data">{selectedItem.name}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Content For </div>
        <div className="dc-text-data">{selectedItem.content_for}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Size</div>
        <div className="dc-text-data">
          <div className="dc-text-data">
            {Number(selectedItem.size_bytes) / 1000} Kbs
          </div>
        </div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">File</div>
        <div className="dc-text-data">
          <a href={selectedItem.s3_url}>Download</a>
        </div>
      </div>
    </div>
  );
};
