import React, { useState } from "react";
import axios from "axios";

import "./UploadMarks.scss";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";

const UploadMarks = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Admissions")
    ?.dropdowns.find((dd) => dd.name === "Upload Marks")?.permissions;
  //RBAC Code block ends.

  const [file, setFile] = useState(null);
  const [scholarshipFile, setScholarshipFile] = useState(null);
  const [entranceFile, setEntranceFile] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const [activeTab, setActiveTab] = useState("scholarship");

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await axios.post("http://localhost:8000/refreshToken", {
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        {
          token: tokens.refreshToken,
        }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // navigate to login page if needed
      return null;
    }
  };

  const handleFileChange = (e, type) => {
    if (type === "scholarship") {
      setScholarshipFile(e.target.files[0]);
    } else if (type === "entrance") {
      setEntranceFile(e.target.files[0]);
    }
  };

  const downloadFile = async (type) => {
    setisLoading(true);
    let endpoint = "";
    if (type === "scholarship") {
      // endpoint = "http://localhost:8000/uploadMarksSample";
      endpoint = "https://server.classerp.in/uploadMarksSample";
    } else if (type === "entrance") {
      // endpoint = "http://localhost:8000/uploadMarksSampleEntrance";
      endpoint = "https://server.classerp.in/uploadMarksSampleEntrance";
    }

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(endpoint, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Sample_${type}_Data.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setisLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newTokens = await refreshToken();
        if (newTokens) {
          downloadFile(type); // Retry downloading the file with new token
        }
      } else {
        console.error("Error downloading the sample file:", error);
        alert("Failed to download the sample file.");
      }
      setisLoading(false);
    }
  };

  const uploadFile = async (type) => {
    let file = null;
    let endpoint = "";
    if (type === "scholarship") {
      file = scholarshipFile;
      // endpoint = "http://localhost:8000/updateScholarshipMarks";
      endpoint = "https://server.classerp.in/updateScholarshipMarks";
    } else if (type === "entrance") {
      file = entranceFile;
      // endpoint = "http://localhost:8000/updateEntranceMarks";
      endpoint = "https://server.classerp.in/updateEntranceMarks";
    }

    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    setisLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      alert("File uploaded successfully.");
      setFile(null);
      setisLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newTokens = await refreshToken();
        if (newTokens) {
          uploadFile(type); // Retry uploading the file with new token
        }
      } else {
        console.error("Error uploading the file:", error);
        alert("Failed to upload the file.");
      }
      setisLoading(false);
    }
  };

  return (
    <div className="umarks">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <div className="umarks-tabs">
            <button
              className={`tab-btn ${
                activeTab === "scholarship" ? "active" : ""
              }`}
              onClick={() => setActiveTab("scholarship")}
            >
              Scholarship Marks
            </button>
            <button
              className={`tab-btn ${activeTab === "entrance" ? "active" : ""}`}
              onClick={() => setActiveTab("entrance")}
            >
              Entrance Marks
            </button>
          </div>

          <div className="umarks-content">
            {activeTab === "scholarship" && (
              <div className="umarks-content-sch">
                <div className="umarks-content-sch-header">
                  Upload ScholarShip Marks
                </div>
                <div className="umarks-content-sch-sample">
                  <button
                    onClick={() => downloadFile("scholarship")}
                    text={`Sample`}
                  >
                    Download Sample File &#8594;
                  </button>
                </div>
                <div className="umarks-content-sch-upload">
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, "scholarship")}
                    accept=".xlsx, .xls, .csv"
                  />
                  <Button
                    onClick={() => uploadFile("scholarship")}
                    text={`Upload`}
                  />
                </div>
              </div>
            )}

            {activeTab === "entrance" && (
              <div className="umarks-content-ent">
                <div className="umarks-content-ent-header">
                  Upload Entrance Marks
                </div>
                <div className="umarks-content-sch-sample">
                  <button
                    onClick={() => downloadFile("entrance")}
                    text={`Sample`}
                  >
                    Download Sample File &#8594;
                  </button>
                </div>

                <div className="umarks-content-ent-upload">
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, "entrance")}
                    accept=".xlsx, .xls, .csv"
                  />
                  <Button
                    onClick={() => uploadFile("entrance")}
                    text={`Upload`}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="umarks-directions">
            <h3>Directions for Filling the File:</h3>
            <ul>
              <li>
                <strong>Date Format:</strong> Use 'DD-MM-YYYY' format. E.g.,
                01-12-2013.
              </li>
              <li>
                <strong>Marks:</strong> Enter marks out of 100. E.g., if the
                score is 85/100, enter 85.
              </li>
              <li>
                <strong>Percent:</strong> Mention the percentage without the '%'
                sign. E.g., enter 85 for 85%. Decimal numbers are allowed as
                well.
              </li>
            </ul>
            <p>Please ensure the data is accurate to avoid upload errors.</p>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default UploadMarks;
