import React, { useState, useEffect } from "react";

import "./Prices.scss"; // Assuming you have a corresponding SCSS file
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Prices = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Settings")
    ?.dropdowns.find((dd) => dd.name === "Prices")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [service, setservice] = useState(null);
  const [type, settype] = useState(null);
  const [price, setprice] = useState(null);
  const [prices, setprices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  const types = [
    {
      label: "Mess",
      value: "Mess",
    },
    {
      label: "Transport",
      value: "Transport",
    },
  ];

  // Fetch prices from API
  useEffect(() => {
    fetchprices();
  }, []);

  useEffect(() => {
    if (!selectedItem) {
      return;
    }
    setservice(selectedItem.service);
    settype(selectedItem.type);
    setprice(selectedItem.price_per_month);
  }, [selectedItem]);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchprices = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(`${BE_URL}/api/prices`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
        },
      });
      setprices(response.data); // Assuming the API returns an array of class objects
    } catch (error) {
      console.error("Error fetching prices:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch prices. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (newPrice) => {
    setIsLoading(true);
    setisAddModalOpen(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/api/prices`, newPrice, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      fetchprices();
      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
      // setprices([...prices, response.data]);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(newPrice); // Retry adding subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to Add. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
      setisAddModalOpen(false);
    }
  };

  const handleEdit = async () => {
    if (!service || !type || !price) {
      alert("Service, Type and Price is mandatory!");
      return;
    }
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.put(
        `${BE_URL}/api/prices/${selectedItem.id}`,
        {
          service,
          type,
          price_per_month: price,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      fetchprices();
      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);

      // setSelectedItem(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleEdit(); // Retry editing subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to edit. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(`${BE_URL}/api/prices/${selectedItem.id}`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setSelectedItem(null);
      fetchprices();

      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(id); // Retry deleting subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="halfpage">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="halfpage-header">
            <div className="halfpage-header-name">Prices</div>
          </div>
          <div className="halfpage-contents">
            <div className="halfpage-contents-list">
              <HalfList
                data={prices}
                fields={["service", "type"]}
                setSelectedItem={setSelectedItem}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                elem={
                  <AddPrice
                    handleAdd={(newDivision) => handleAdd(newDivision)}
                    types={types}
                  />
                }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="halfpage-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    service={selectedItem.service}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    can_delete={permissions?.can_delete}
                    can_update={permissions?.can_update}
                  >
                    <DetailsChild
                      selectedItem={selectedItem}
                      service={service}
                      type={type}
                      price={price}
                      setprice={setprice}
                      setservice={setservice}
                      settype={settype}
                      types={types}
                    ></DetailsChild>
                  </HalfDetails>
                </div>
              ) : (
                <div className="halfpage-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Prices;

const AddPrice = ({ handleAdd, types }) => {
  const [service, setservice] = useState(null);
  const [type, settype] = useState(null);
  const [price, setprice] = useState(null);

  const handleSubmit = () => {
    if (!service || !type || !price) {
      alert("Service, Type and Price is mandatory!");
      return;
    }
    const newPrice = {
      service: service,
      type: type,
      price_per_month: price,
    };
    handleAdd(newPrice);
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add a Price</div>
      <CustomTextInput
        label="Service Name"
        placeholder="Service Name"
        value={service}
        onChange={(e) => setservice(e.target.value)}
      />
      <ObjectDropDown
        label="Select Type"
        options={types.map(({ label, value }) => ({
          label,
          value,
        }))}
        value={type}
        setId={() => {}}
        setValue={settype}
      />
      <CustomNumberInput
        label="Price"
        placeholder="Price"
        value={price}
        onChange={setprice}
      />
      <Button text="Add Price" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({
  service,
  type,
  setservice,
  price,
  setprice,
  settype,
  types,
}) => {
  return (
    <div className="dc">
      <div className="dc-input">
        <CustomTextInput
          value={service}
          label={`Service`}
          onChange={(e) => setservice(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <ObjectDropDown
          label="Type"
          options={types.map(({ label, value }) => ({
            label,
            value,
          }))}
          value={type}
          setId={() => {}}
          setValue={settype}
        />
      </div>
      <div className="dc-input">
        <CustomNumberInput
          label="Price"
          placeholder="Price"
          value={price}
          onChange={setprice}
        />
      </div>
    </div>
  );
};
