import React, { useState, useEffect } from "react";
import "./StudentTimetable.scss";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
// import { Select } from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import CustomTimePicker from "../../components/CustomTimePicker/CustomTimePicker";
import DropDown from "../../components/DropDown/DropDown";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const StudentTimetable = () => {
  // const { timetableId } = useParams();
  const timetableId = 1;

  const navigate = useNavigate();
  const [timetable, setTimetable] = useState({ details: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchTimetableDetails();
  }, []);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchTimetableDetails = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `${BE_URL}/api/general/studenttimetable`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setTimetable(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await refreshToken();
        fetchTimetableDetails(); // Retry after refreshing token
      } else {
        setError("Failed to load timetable details. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const renderDayEntries = (day) => {
    const dayEntries = timetable.details.filter(
      (entry) => entry.day_of_week === day
    );
    if (dayEntries.length === 0)
      return <div className="classCard">No Classes</div>;
    return dayEntries.map((entry, index) => (
      <div key={index} className="classCard">
        <div className="classCard-time">{`${entry.start_time.slice(
          0,
          -3
        )} - ${entry.end_time.slice(0, -3)}`}</div>
        <div className="classCard-subject">{entry.sub_name}</div>
        with
        <div className="classCard-teacher">{entry.name}</div>
      </div>
    ));
  };

  return (
    <div className="timetableDetails">
      {isLoading && <Loader />}

      <div className="timetableDetails-meta">
        <div className="timetableDetails-meta-title">
          Timetable for {timetable.meta?.class_name}{" "}
          {timetable.meta?.division_name}
        </div>
      </div>

      {!isLoading && (
        <div className="timetable-container">
          {daysOfWeek.map((day) => (
            <div key={day} className="day-column">
              <h3 className="day-header">{day}</h3>
              {renderDayEntries(day)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StudentTimetable;
