import React, { useEffect, useState } from "react";
import "./SeniorStudents.scss"; // Importing CSS file
import axios from "axios";

import { utils, writeFile } from "xlsx";
import { DateRangePicker } from "rsuite";
import { Input, SelectPicker } from "rsuite";

import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import EntranceTable from "../../components/EntranceTable/EntranceTable";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const SeniorStudents = ({ is_Senior }) => {
  const location = useLocation();

  useEffect(() => {
    fetchStudents();
  }, [location]);

  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  let permissions = null;
  if (is_Senior) {
    permissions = allPermissions
      .find((p) => p.name === "Students")
      ?.dropdowns.find((dd) => dd.name === "Senior Students")?.permissions;
  } else {
    permissions = allPermissions
      .find((p) => p.name === "Students")
      ?.dropdowns.find((dd) => dd.name === "Junior Students")?.permissions;
  }

  //RBAC Code block ends.

  const [sortOrder, setSortOrder] = useState("desc"); // 'desc' or 'asc'

  const hostelOptions = ["All", "Yes", "No"];

  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelled, setcancelled] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  // const [selectedClass, setSelectedClass] = useState("All Classes");
  const [hostelFilter, setHostelFilter] = useState("All");
  const [messFilter, setmessFilter] = useState("All");
  const [transportFilter, settransportFilter] = useState("All");

  const [hostelAllocatedFilter, setHostelAllocatedFilter] = useState("All");
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(""); // Initialize with "" for "All Classes"

  // Dropdown options
  const classOptions = [
    "All Classes",
    "Class 6th",
    "Class 7th",
    "Class 8th",
    "Class 9th",
    "Class 10th",
    "Class 11th",
    "Class 12th",
  ];

  const classData = classOptions.map((cls) => ({
    label: cls,
    value: cls,
    role: cls === "All Classes" ? "All" : "Class",
  }));

  const handleDateRangeChange = (item) => {
    if (!item) {
      setDateRange([]);
    } else {
      setDateRange(item);
    }
  };

  const fetchStudents = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      console.log("Is senior is ", is_Senior);
      const url = is_Senior
        ? `${BE_URL}/api/students?senior=${is_Senior}&is_cancelled=${cancelled}`
        : `${BE_URL}/api/students/junior?senior=${is_Senior}&is_cancelled=${cancelled}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setStudents(null);
      setStudents(response.data);
      // setFilteredStudents(response.data); // Initially, all students are shown
      setFilteredStudents([]); // Initially, all students are shown
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newTokens = await refreshToken();
        if (newTokens) {
          fetchStudents(is_Senior); // Retry fetching students with the new token
        } else {
          // Handle failed refresh token scenario (e.g., redirect to login)
        }
      } else {
        console.error("Error fetching students:", error);
        // Handle other errors
      }
    } finally {
      setIsLoading(false);
    }
  };
  const fetchClasses = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/classes`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      const classOptions = [
        { label: "All Classes", value: "" },
        ...response.data.map((cls) => ({
          label: cls.class_name,
          value: cls.class_name,
        })),
      ];
      setClasses(classOptions);
    } catch (error) {
      console.error("Error fetching classes:", error);
      // Add error handling here
    }
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/refreshToken`, {
        refreshToken: tokens.refreshToken,
      });
      if (response.data.accessToken) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data;
      } else {
        throw new Error("Failed to refresh token.");
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  };

  useEffect(() => {
    fetchStudents();
    fetchClasses();
  }, []);

  useEffect(() => {
    fetchStudents();
  }, [cancelled]);

  useEffect(() => {
    applyFilters();
  }, [
    searchTerm,
    dateRange,
    selectedClass,
    hostelFilter,
    hostelAllocatedFilter,
    students,
    messFilter,
    transportFilter,
  ]);

  const applyFilters = () => {
    let filtered = students.filter((student) => {
      const matchesSearchTerm = searchTerm
        ? student.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          student.roll_no.toLowerCase().includes(searchTerm.toLowerCase()) ||
          student.aadhar.toLowerCase().includes(searchTerm.toLowerCase()) ||
          student.whatsapp.toLowerCase().includes(searchTerm.toLowerCase())
        : true;
      const matchesClass = selectedClass
        ? student.adm_class.toString() === selectedClass.toString()
        : true;
      const matchesHostel =
        hostelFilter !== "All"
          ? hostelFilter === "Yes"
            ? student.hostel
            : !student.hostel
          : true;

      const matchesMess =
        messFilter !== "All"
          ? messFilter === "Yes"
            ? student.mess
            : !student.mess
          : true;
      const matchesTransport =
        transportFilter !== "All"
          ? transportFilter === "Yes"
            ? student.transport
            : !student.transport
          : true;
      const matchesHostelAllocated =
        hostelAllocatedFilter !== "All"
          ? hostelAllocatedFilter === "Yes"
            ? student.bed_id != null
            : student.bed_id == null
          : true;

      // const matchesDateRange =
      //   dateRange[0] && dateRange[1]
      //     ? new Date(student.timestamp) >= new Date(dateRange[0]) &&
      //       new Date(student.timestamp) <= new Date(dateRange[1])
      //     : true;

      return (
        matchesSearchTerm &&
        matchesClass &&
        matchesHostel &&
        matchesHostelAllocated &&
        matchesMess &&
        matchesTransport
      );
    });

    setFilteredStudents(filtered);
  };

  useEffect(() => {
    setIsLoading(true);
    if (sortOrder === "desc") {
      setStudents((prevstudents) =>
        [...prevstudents].sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        )
      );
    } else {
      setStudents((prevstudents) =>
        [...prevstudents].sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        )
      );
    }
    setIsLoading(false);
  }, [sortOrder]);

  const handleClassFilterChange = (value) => {
    setSelectedClass(value);
    console.log("selected class is ", value);
  };

  const downloadExcel = () => {
    setIsLoading(true);
    const ws = utils.json_to_sheet(filteredStudents);
    const wb = utils.book_new();
    if (is_Senior) {
      utils.book_append_sheet(wb, ws, "SeniorStudents");
      writeFile(wb, "SeniorStudents.xlsx");
    } else {
      utils.book_append_sheet(wb, ws, "JuniorStudents");
      writeFile(wb, "JuniorStudents.xlsx");
    }

    setIsLoading(false);
  };

  return (
    <div className="sstu">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <div className="sstu-header">
            <div className="sstu-heading">
              {is_Senior ? "Senior" : "Junior"} Students List
            </div>
            {cancelled && (
              <div className="sstu-header-warn">Displaying Cancelled List</div>
            )}
            <div className="sstu-header-buttons">
              <Button
                type={"arrow"}
                text={cancelled ? "Show Normal List" : "Show Cancelled List"}
                onClick={() => setcancelled(!cancelled)}
              />
              <Button type={"curved"} text={"Excel"} onClick={downloadExcel} />
            </div>
          </div>

          <div className="sstu-filters">
            <div className="sstu-filters-search">
              <div className="sstu-filters-label">
                Search by first name, roll no, Aadhar or whatsapp.
              </div>
              <Input
                placeholder="Search by first name, roll no, Aadhar or whatsapp..."
                onChange={(event) => setSearchTerm(event)}
                className="search-bar"
              />
            </div>
            {/* <div className="sstu-filters-datepicker">
              <div className="sstu-filters-label">Select Date Range.</div>
              <DateRangePicker
                format="yyyy-MM-dd"
                onChange={handleDateRangeChange}
                value={dateRange}
              />
            </div> */}
            <div className="sstu-filters-class">
              <div className="sstu-filters-label">Select Class.</div>
              <SelectPicker
                data={classes}
                onChange={handleClassFilterChange}
                value={selectedClass}
                cleanable={false}
                searchable={false}
                placeholder="Select Class"
              />
            </div>

            <div className="sstu-filters-hostel">
              <div className="sstu-filters-label">Hostel</div>
              <SelectPicker
                data={hostelOptions.map((opt) => ({ label: opt, value: opt }))}
                onChange={setHostelFilter}
                value={hostelFilter}
                cleanable={false}
                searchable={false}
              />
            </div>

            <div className="sstu-filters-hostel">
              <div className="sstu-filters-label">Hostel Allocated</div>
              <SelectPicker
                data={hostelOptions.map((opt) => ({ label: opt, value: opt }))}
                onChange={setHostelAllocatedFilter}
                value={hostelAllocatedFilter}
                cleanable={false}
                searchable={false}
              />
            </div>
          </div>
          <div className="sstu-filters2">
            <div className="sstu-filters-mess">
              <div className="sstu-filters-label">Mess</div>
              <SelectPicker
                data={hostelOptions.map((opt) => ({ label: opt, value: opt }))}
                onChange={setmessFilter}
                value={messFilter}
                cleanable={false}
                searchable={false}
              />
            </div>
            <div className="sstu-filters-transport">
              <div className="sstu-filters-label">Transport</div>
              <SelectPicker
                data={hostelOptions.map((opt) => ({ label: opt, value: opt }))}
                onChange={settransportFilter}
                value={transportFilter}
                cleanable={false}
                searchable={false}
              />
            </div>
          </div>
          <div className="sstu-table">
            <div className="sstu-table-header">
              <div className="header-id">Roll No</div>
              <div className="header-name">Name</div>
              <div className="header-whatsapp">Aadhar</div>
              <div className="header-class">Class</div>
              <div
                className="header-timestamp"
                onClick={() =>
                  setSortOrder(sortOrder === "desc" ? "asc" : "desc")
                }
              >
                TimeStamp {sortOrder === "desc" ? " ↑" : " ↓"}
              </div>
            </div>

            <EntranceTable
              data={filteredStudents}
              navigateBaseUrl={
                is_Senior ? `/admin/seniorStudent` : `/admin/juniorStudent`
              }
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
            />
            <div className="sstu-table-footer">
              {filteredStudents.length} items found.
            </div>
          </div>
        </>
      ) : (
        ""
      )}{" "}
    </div>
  );
};

export default SeniorStudents;
