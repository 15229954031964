import React from "react";
import { NumberInput } from "@mantine/core";

const CustomNumberInput = ({ value, onChange, ...props }) => {
  const customStyles = {
    input: {
      padding: "2.2rem 1rem",
      fontSize: "1.6rem",
      border: "1px solid #4585e5",
      borderRadius: "1rem",
      boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      marginBottom: "15px",
    },
    label: {
      fontSize: "1.5rem",
      color: "#6c6c6c",
      // marginRight: "auto",
    },
    root: {
      textAlign: "left",
    },
    // Add more custom styles if needed
  };

  return (
    <NumberInput
      value={value}
      onChange={onChange}
      styles={customStyles}
      hideControls
      {...props} // This allows you to pass additional props
    />
  );
};

export default CustomNumberInput;
