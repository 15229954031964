import React, { useState, useEffect } from "react";
import "./Subjects.scss"; // Assuming you have a corresponding SCSS file
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Subjects = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Students")
    ?.dropdowns.find((dd) => dd.name === "Subjects")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [sub_code, setsub_code] = useState(null);
  const [sub_name, setsub_name] = useState(null);
  const [sub_desc, setsub_desc] = useState(null);
  const [is_additional, setis_additional] = useState(false);
  const [fees, setfees] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Fetch subjects from API
  useEffect(() => {
    fetchSubjects();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      setsub_code(selectedItem.sub_code);
      setsub_name(selectedItem.sub_name);
      setsub_desc(selectedItem.sub_desc);
      setis_additional(selectedItem.is_additional);
      setfees(selectedItem.fees);
      console.log("Selected item changed ", selectedItem);
    }
  }, [selectedItem]);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/refreshToken`, {
        token: tokens.refreshToken,
      });

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchSubjects = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await axios.get("http://localhost:8000/api/subjects", {
      const response = await axios.get(`${BE_URL}/api/subjects`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setSubjects(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchSubjects(); // Retry fetching subjects with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch subjects.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (newSubject) => {
    setIsLoading(true);
    setError(null);
    setisAddModalOpen(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        // `http://localhost:8000/api/subjects`,
        `${BE_URL}/api/subjects`,
        newSubject,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setSubjects([...subjects, response.data]);

      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
      setisAddModalOpen(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(newSubject); // Retry adding subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else if (error.response && error.response.status === 403) {
        alert("This subject code already exists.");
      } else {
        setresponseType("Error");
        setmsg("Failed to Add. Please try again.");
        setisResponseOpen(true);
        setisAddModalOpen(false);
      }
    } finally {
      setIsLoading(false);
      setisAddModalOpen(false);
    }
  };

  const handleEdit = async () => {
    setIsLoading(true);
    setError(null);

    console.log("Handling the edit");

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.put(
        // `http://localhost:8000/api/subjects/${selectedItem.id}`,
        `${BE_URL}/api/subjects/${selectedItem.id}`,
        {
          sub_code: sub_code,
          sub_name: sub_name,
          sub_desc: sub_desc,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Update the subjects list with the edited subject
      const updatedSubjects = subjects.map((sub) =>
        sub.id === response.data.id ? response.data : sub
      );
      setSubjects(updatedSubjects);
      setSelectedItem(response.data);

      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleEdit(); // Retry editing subject with new token
        } else {
          alert("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to edit. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (subjectId) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(
        // `http://localhost:8000/api/subjects/${selectedItem.id}`,
        `${BE_URL}/api/subjects/${selectedItem.id}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Remove the deleted subject from the subjects list
      const updatedSubjects = subjects.filter((sub) => sub.id !== subjectId);
      setSubjects(updatedSubjects);
      setSelectedItem(null);
      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(subjectId); // Retry deleting subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="subjects">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="subjects-header">
            <div className="subjects-header-name">Subjects</div>
          </div>
          <div className="subjects-contents">
            <div className="subjects-contents-list">
              <HalfList
                data={subjects}
                fields={["id", "sub_code", "sub_name"]}
                setSelectedItem={setSelectedItem}
                isAddModalOpen={isAddModalOpen}
                handleAdd={handleAdd}
                can_create={permissions?.can_create}
                elem={
                  <AddSubject
                    handleAdd={(newSubject) => handleAdd(newSubject)}
                  />
                }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="subjects-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={selectedItem.sub_name}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    can_delete={permissions?.can_delete}
                    can_update={permissions?.can_update}
                  >
                    <DetailsChild
                      selectedItem={selectedItem}
                      sub_code={sub_code}
                      sub_name={sub_name}
                      sub_desc={sub_desc}
                      is_additional={is_additional}
                      fees={fees}
                      setsub_code={setsub_code}
                      setsub_name={setsub_name}
                      setsub_desc={setsub_desc}
                      setis_additional={setis_additional}
                      setfees={setfees}
                    ></DetailsChild>
                  </HalfDetails>
                </div>
              ) : (
                <div className="subjects-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Subjects;

const AddSubject = ({ handleAdd }) => {
  const [sub_code, setsub_code] = useState(null);
  const [sub_name, setsub_name] = useState(null);
  const [sub_desc, setsub_desc] = useState(null);

  const handleSubmit = () => {
    const newSubject = { sub_name, sub_code, sub_desc };
    handleAdd(newSubject);
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add a Subject</div>
      <CustomTextInput
        label={`Subject Code`}
        placeholder="Subject Code"
        value={sub_code}
        onChange={(e) => setsub_code(e.target.value)}
      />
      <CustomTextInput
        label={`Subject Name`}
        placeholder="Subject Name"
        value={sub_name}
        onChange={(e) => setsub_name(e.target.value)}
      />
      <CustomTextInput
        label={`Subject Description`}
        placeholder="Subject Description"
        value={sub_desc}
        onChange={(e) => setsub_desc(e.target.value)}
      />

      <Button text="Add Subject" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({
  selectedItem,
  sub_code,
  sub_name,
  sub_desc,
  setsub_code,
  setsub_name,
  setsub_desc,
}) => {
  //   const [sub_code, setsub_code] = useState(selectedItem.sub_code);
  //   const [sub_name, setsub_name] = useState(selectedItem.sub_name);
  //   const [sub_desc, setsub_desc] = useState(selectedItem.sub_desc);
  return (
    <div className="dc">
      <div className="dc-input">
        <CustomTextInput
          value={sub_code}
          label={`Subject Code`}
          onChange={(e) => setsub_code(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={sub_name}
          label={`Subject Name`}
          onChange={(e) => setsub_name(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={sub_desc}
          label={`Subject Description`}
          onChange={(e) => setsub_desc(e.target.value)}
        />
      </div>
    </div>
  );
};
