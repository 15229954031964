import React, { useState, useEffect } from "react";
import axios from "axios";
import "./StaffDashboard.scss";
import { useNavigate } from "react-router-dom";
import user from "../../assets/images/user.jpg";
import logo from "../../assets/images/logo.png";

import AnnouncementsDisplay from "../../components/AnnouncementsDisplay/AnnouncementsDisplay";
import AssignedInventory from "../../components/AssignedInventory/AssignedInventory";
import StudentFee from "../../components/StudentFee/StudentFee";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const StaffDashboard = () => {
  const [staffDetails, setStaffDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [timetable, setTimetable] = useState({ details: [] });
  const navigate = useNavigate();

  // Additional step: Determine today's day of the week
  const today = new Date().toLocaleString("en-us", { weekday: "long" });

  // Filter classes for today
  const todayClasses = timetable.details.filter(
    (classDetail) => classDetail.day_of_week === today
  );

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      navigate("/login");
    }
  };
  // const fetchTimetableDetails = async () => {
  //   setIsLoading(true);
  //   try {
  //     const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
  //     const response = await axios.get(
  //       `${BE_URL}/api/general/studenttimetable`,
  //       {
  //         headers: { Authorization: `Bearer ${tokens.accessToken}` },
  //       }
  //     );
  //     setTimetable(response.data);
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       await refreshToken();
  //       fetchTimetableDetails(); // Retry after refreshing token
  //     } else {
  //       alert("Failed to load timetable details. Please try again later.");
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    const fetchStaffDetails = async () => {
      try {
        const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
        const response = await axios.get(`${BE_URL}/api/general/staff`, {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        });

        if (response.data) {
          setStaffDetails(response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          const newTokens = await refreshToken();
          if (newTokens) {
            fetchStaffDetails();
          } else {
            alert("User logged out.");
            navigate("/login");
          }
        } else {
          console.error("Error fetching scholarship details:", error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchStaffDetails();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="stfdash">
      <div className="stfdash-contents">
        <div className="stfdash-contents-info">
          <div className="stfdash-contents-info-brand">
            <img src={logo} alt="Chaitanyas Academy Logo" />
            <div>Chaitanyas Academy</div>
          </div>
          <div className="stfdash-contents-info-name">
            <h3>{staffDetails.name}</h3>{" "}
          </div>
          <img
            src={staffDetails.image_url || user}
            alt="student"
            className="student-photo"
          />
          <div className="stfdash-contents-info-details">
            {/* <h1>{staffDetails.name}</h1> */}
            <p>
              <strong> Employee Id: </strong>
              {staffDetails.employee_id}
            </p>
            <p>
              <strong> Phone: </strong>
              {staffDetails.phone_number}
            </p>
            <p>
              <strong> Email:</strong> {staffDetails.email}
            </p>

            <p>
              <strong> Manager :</strong> {staffDetails.manager}
            </p>

            {/* Show hostel details if hostel is true */}
          </div>
        </div>
        <div className="stfdash-contents-ancmnts">
          <div className="stfdash-contents-ancmnts-title">Announcements</div>
          <div className="stfdash-contents-ancmnts-title">
            <AnnouncementsDisplay />
          </div>
        </div>
        <div className="stfdash-contents-exam">
          <div className="stfdash-contents-exam-title">Assinged Inventory</div>
          <AssignedInventory type={"user"} />
        </div>
      </div>
      {/* <div className="stfdash-fee">
        <StudentFee />
      </div> */}
    </div>
  );
};

export default StaffDashboard;
