import React, { useState, useEffect } from "react";

import "./Categories.scss"; // Assuming you have a corresponding SCSS file
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Categories = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Settings")
    ?.dropdowns.find((dd) => dd.name === "Categories")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [name, setname] = useState(null);
  const [type, settype] = useState(null);
  const [categories, setcategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  const types = [
    {
      label: "Vendor",
      value: "Vendor",
    },
    {
      label: "Products",
      value: "Products",
    },
    {
      label: "Departments",
      value: "Departments",
    },
    {
      label: "Accounts",
      value: "Accounts",
    },
  ];

  // Fetch categories from API
  useEffect(() => {
    fetchcategories();
  }, []);

  useEffect(() => {
    if (!selectedItem) {
      return;
    }
    setname(selectedItem.name);
    settype(selectedItem.type);
    console.log("Selected item changed ", selectedItem);
  }, [selectedItem]);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchcategories = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(`${BE_URL}/api/settings/categories`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
        },
      });
      setcategories(response.data); // Assuming the API returns an array of class objects
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch categories. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (newCategory) => {
    setIsLoading(true);
    setisAddModalOpen(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        `${BE_URL}/api/settings/categories`,
        newCategory,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      fetchcategories();
      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
      // setcategories([...categories, response.data]);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(newCategory); // Retry adding subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to Add. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
      setisAddModalOpen(false);
    }
  };

  const handleEdit = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.put(
        `${BE_URL}/api/settings/categories/${selectedItem.id}`,
        {
          name: name,
          type: type,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Update the categories list with the edited subject
      // const updatedcategories = categories.map((sub) =>
      //   sub.id === response.data.id ? response.data : sub
      // );
      // setcategories(updatedcategories);
      fetchcategories();
      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);

      // setSelectedItem(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleEdit(); // Retry editing subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to edit. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(
        `${BE_URL}/api/settings/categories/${selectedItem.id}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // // Remove the deleted subject from the categories list
      // const updatedcategories = categories.filter((sub) => sub.id !== id);
      // setcategories(updatedcategories);

      setSelectedItem(null);
      fetchcategories();

      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(id); // Retry deleting subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="categories">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="categories-header">
            <div className="categories-header-name">Categories</div>
          </div>
          <div className="categories-contents">
            <div className="categories-contents-list">
              <HalfList
                data={categories}
                fields={["name", "type"]}
                setSelectedItem={setSelectedItem}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                elem={
                  <AddCategory
                    handleAdd={(newDivision) => handleAdd(newDivision)}
                    types={types}
                  />
                }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="categories-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={selectedItem.name}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    can_delete={permissions?.can_delete}
                    can_update={permissions?.can_update}
                  >
                    <DetailsChild
                      selectedItem={selectedItem}
                      name={name}
                      type={type}
                      setname={setname}
                      settype={settype}
                      types={types}
                    ></DetailsChild>
                  </HalfDetails>
                </div>
              ) : (
                <div className="categories-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Categories;

const AddCategory = ({ handleAdd, types }) => {
  const [name, setname] = useState(null);
  const [type, settype] = useState(null);

  const handleSubmit = () => {
    if (!name || !type) {
      alert("Category Name and Type is mandatory!");
      return;
    }
    const newCategory = {
      name,
      type,
    };
    handleAdd(newCategory);
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add a Category</div>
      <CustomTextInput
        label="Name"
        placeholder="Category Name"
        value={name}
        onChange={(e) => setname(e.target.value)}
      />
      <ObjectDropDown
        label="Select Type"
        options={types.map(({ label, value }) => ({
          label,
          value,
        }))}
        value={type}
        setId={() => {}}
        setValue={settype}
      />
      <Button text="Add Category" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({
  name,
  type,
  setname,

  settype,
  types,
}) => {
  return (
    <div className="dc">
      <div className="dc-input">
        <CustomTextInput
          value={name}
          label={`Name`}
          onChange={(e) => setname(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <ObjectDropDown
          label="Type"
          options={types.map(({ label, value }) => ({
            label,
            value,
          }))}
          value={type}
          setId={() => {}}
          setValue={settype}
        />
      </div>
    </div>
  );
};
