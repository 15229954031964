import React, { useState, useEffect } from "react";
import axios from "axios";

import "./ScholarshipSettings.scss";
import { useSelector } from "react-redux";

const BE_URL = "http://localhost:8000";
// const BE_URL = "https://server.classerp.in";

const ScholarshipSettings = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Settings")
    ?.dropdowns.find((dd) => dd.name === "Additional Scholarship")?.permissions;
  //RBAC Code block ends.

  const [settings, setSettings] = useState({
    scholarship_limit: "",
    approver1_email: "",
    approver2_email: "",
    approver3_email: "",
  });

  const [approvals, setApprovals] = useState([]);
  const [filteredApprovals, setFilteredApprovals] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchSettings = async () => {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const result = await axios.get(`${BE_URL}/api/settings/scholarship`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      if (result.data) {
        setSettings(result.data);
      }
    };

    const fetchApprovals = async () => {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `${BE_URL}/api/settings/scholarship/approvals`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setApprovals(response.data);
      setFilteredApprovals(response.data); // Initialize filtered data
    };

    fetchSettings();
    fetchApprovals();
  }, []);

  const handleSearch = () => {
    const lowerQuery = searchQuery.toLowerCase();
    const filtered = approvals.filter(
      (approval) =>
        approval.roll_no.toLowerCase().includes(lowerQuery) ||
        approval.requested_by.toLowerCase().includes(lowerQuery)
    );
    setFilteredApprovals(filtered);
  };

  useEffect(() => {
    handleSearch();
  }, [searchQuery]);

  const handleChange = (e) => {
    setSettings({
      ...settings,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
    try {
      const response = await axios.post(
        `${BE_URL}/api/settings/scholarship`,
        settings,
        { headers: { Authorization: `Bearer ${tokens.accessToken}` } }
      );
      alert("Settings updated successfully!");
    } catch (error) {
      alert("Failed to update settings. Please try again.");
    }
  };

  return (
    <div className="schSettings">
      {permissions?.can_read ? (
        <>
          <div className="schSettings-title">Scholarship Approval Settings</div>
          <form className="schSettings-form" onSubmit={handleSubmit}>
            <div className="schSettings-form-input">
              <label>Scholarship Limit:</label>
              <input
                type="number"
                name="scholarship_limit"
                value={settings.scholarship_limit}
                onChange={handleChange}
              />
            </div>
            <div className="schSettings-form-input">
              <label>Approver 1 Email:</label>
              <input
                type="email"
                name="approver1_email"
                value={settings.approver1_email}
                onChange={handleChange}
              />
            </div>
            <div className="schSettings-form-input">
              <label>Approver 2 Email:</label>
              <input
                type="email"
                name="approver2_email"
                value={settings.approver2_email}
                onChange={handleChange}
              />
            </div>
            <div className="schSettings-form-input">
              <label>Approver 3 Email:</label>
              <input
                type="email"
                name="approver3_email"
                value={settings.approver3_email}
                onChange={handleChange}
              />
            </div>
            {permissions?.can_update ? (
              <button type="submit">Update Settings</button>
            ) : (
              ""
            )}
          </form>
          <div className="schSettings-history">
            <div className="schSettings-history-title">History</div>
            <div className="schSettings-history-search">
              <input
                type="text"
                placeholder="Search using Roll No or Initiated By..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="approvals-list">
              <table>
                <thead>
                  <tr>
                    <th>Roll No</th>
                    <th>Name</th>
                    <th>Initiated By</th>
                    <th>Amount</th>
                    <th>Reason</th>
                    <th>Status</th>
                    <th>Initiated At</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredApprovals.map((approval) => (
                    <tr key={approval.created_at}>
                      <td>{approval.roll_no}</td>
                      <td>{approval.name}</td>
                      <td>{approval.requested_by}</td>
                      <td>Rs {approval.amount}</td>
                      <td>{approval.reason}</td>
                      <td>{approval.status}</td>
                      <td>
                        {new Date(approval.created_at).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ScholarshipSettings;
