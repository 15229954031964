import React, { useEffect, useState } from "react";
import axios from "axios";
import { Input, Dropdown } from "rsuite";

import "./UserAdministration.scss";
import TextField from "../../components/TextField/TextField";
import DropDown from "../../components/DropDown/DropDown";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";

import deleteicon from "../../assets/images/Delete.png";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import HalfList from "../../components/HalfList/HalfList";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import { useNavigate } from "react-router-dom";
import ResponseModal from "../../components/ResponseModal/ResponseModal";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const bloodgroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

const UserAdministration = () => {
  //RBAC Code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Management")
    ?.dropdowns.find((dd) => dd.name === "Users")?.permissions;
  //RBAC Code block ends.

  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRoleFilter, setSelectedRoleFilter] = useState("");
  const [filteredUsers, setfilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null); // Used for editing
  const [roleOptions, setRoleOptions] = useState([]);
  const [allRoles, setallRoles] = useState([]);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  const filtereUsers = users.filter((user) => {
    return (
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedRoleFilter === "All" || user.role === selectedRoleFilter)
    );
  });

  // Fetch users
  useEffect(() => {
    fetchRoles();
    fetchUsers();
    setfilteredUsers(filtereUsers);
  }, []);

  useEffect(() => {
    setfilteredUsers(filtereUsers);
  }, [searchTerm, selectedRoleFilter]);

  const fetchRoles = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/roles`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });
      setRoleOptions(response.data.map((role) => role.name)); // Adjust according to your API response
      const allRoles = ["All", ...response.data.map((role) => role.name)];
      setallRoles(allRoles);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching roles:", error);
      // Add error handling logic here
      setIsLoading(false);
    }
  };

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await axios.get("http://localhost:8000/getAllUsers", {
      const response = await axios.get(`${BE_URL}/getAllUsers`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });
      setUsers(response.data);
      setfilteredUsers(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Token expired, refresh token
        const newTokens = await refreshToken();
        if (newTokens) {
          return fetchUsers(); // Retry fetching users with new token
        } else {
          alert("Session expired. Please login again.");
        }
      } else {
        console.error("Error fetching users:", error);
        alert("Failed to fetch user data. Please try again.");
      }
    }
    setIsLoading(false);
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post("/api/refreshToken", {
        token: tokens.refreshToken,
      });

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  };

  const handleAddUser = async (newUserData) => {
    setIsLoading(true);
    setisAddModalOpen(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.post(`${BE_URL}/addUser`, newUserData, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      fetchUsers(); // Refresh user list
      setIsModalOpen(false); // Close the modal
      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error;
      if (
        errorMessage.includes(
          'duplicate key value violates unique constraint "users_employee_id_key"'
        )
      ) {
        setmsg("This employee id already exists.");
      } else if (
        errorMessage.includes(
          'duplicate key value violates unique constraint "users_email_key"'
        )
      ) {
        setmsg("This email already exists.");
      } else if (
        errorMessage.includes(
          'duplicate key value violates unique constraint "unique_phone_number"'
        )
      ) {
        setmsg("This phone number already exists.");
      } else {
        setmsg("Failed to add. Please try again later.");
      }
      setresponseType("Error");
      setisResponseOpen(true);
    }
    setIsLoading(false);
    setisAddModalOpen(false);
  };

  return (
    <div className="ua">
      {isLoading && <Loader />}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddUserForm onAddUser={handleAddUser} roleOptions={roleOptions} />
      </Modal>
      <Modal isOpen={isResponseOpen} onClose={() => setisResponseOpen(false)}>
        <ResponseModal
          type={responseType}
          msg={msg}
          onClick={() => setisResponseOpen(false)}
        />
      </Modal>
      {permissions?.can_read ? (
        <>
          <div className="ua-header">
            <div className="ua-header-info">
              <div className="ua-header-info-title">User Administration</div>
              <div className="ua-header-filters-roles">
                <div className="ua-header-filters-label">Choose Role</div>
                <DropDown
                  options={allRoles}
                  value={selectedRoleFilter}
                  onChange={(value) => setSelectedRoleFilter(value)}
                />
              </div>
              {/* {permissions?.can_create && (
                <Button
                  text={`Add User`}
                  type={`Primary`}
                  onClick={() => setIsModalOpen(true)}
                />
              )} */}
            </div>
          </div>
          <div className="ua-content">
            <div className="ua-content-list">
              <HalfList
                data={filteredUsers}
                fields={["name", "role"]}
                setSelectedItem={setSelectedUser}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                handleAdd={() => setIsModalOpen(true)}
                elem={
                  <AddUserForm
                    onAddUser={handleAddUser}
                    roleOptions={roleOptions}
                    users={users}
                  />
                }
              />
            </div>
            <div className="ua-content-details">
              {selectedUser && (
                <UserForm
                  user={selectedUser}
                  refreshToken={refreshToken}
                  fetchUsers={fetchUsers}
                  setSelectedUser={setSelectedUser}
                  roleOptions={roleOptions}
                  permissions={permissions}
                  users={users}
                />
              )}
            </div>
          </div>{" "}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserAdministration;

const UserForm = ({
  user,
  refreshToken,
  fetchUsers,
  setSelectedUser,
  roleOptions,
  permissions,
  users,
}) => {
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [phone, setPhone] = useState(user?.phone || "");
  const [role, setRole] = useState(user?.role || "");
  const [designation, setDesignation] = useState(user?.designation || "");
  const [employeeId, setEmployeeId] = useState(user?.employee_id || "");
  const [salary, setSalary] = useState(user?.salary || "");
  const [pf_number, setpf_number] = useState(user?.pf_number || "");
  const [pan, setpan] = useState(user?.pan || "");
  const [joining_date, setjoining_date] = useState(user?.joining_date || "");
  const [marital_state, setmarital_state] = useState(
    user?.marital_status || ""
  );
  const [dob, setdob] = useState(user?.dob || "");
  const [blood_group, setblood_group] = useState(user?.blood_group || "");

  const [subjectId, setSubjectId] = useState(user?.subject_id || "");
  const [isLoading, setIsLoading] = useState(false);
  const [managerId, setManagerId] = useState(user?.manager_id || "");
  const navigate = useNavigate();

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  const formatDate = (timestamp) => {
    if (!timestamp) {
      return "";
    }
    const date = new Date(timestamp);
    const formatteddate = date.toISOString().split("T")[0];
    return formatteddate;
  };

  useEffect(() => {
    if (user) {
      setName(user.name || "");
      setEmail(user.email || "");
      setPhone(user.phone || "");
      setRole(user.role || null);
      setDesignation(user.designation || "");
      setEmployeeId(user.employee_id || "");
      setSalary(user.salary || "");
      setpf_number(user.pf_number || "");
      setmarital_state(user.marital_status || "");
      setpan(user.pan || "");
      setjoining_date(user.joining_date || "");
      setManagerId(user.manager_id || null);
      setblood_group(user.blood_group || null);
      setdob(user.dob || null);
    }
  }, [user]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      console.log("Employee id is :", employeeId);
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const updatedUser = {
        name,
        email,
        phone,
        role,
        designation,
        employee_id: employeeId ? employeeId : null,
        salary: salary ? salary : null,
        subject_id: role === "Teacher" ? subjectId : null,
        manager_id: managerId,
        pf_number,
        pan,
        marital_status: marital_state,
        joining_date: joining_date ? joining_date : null,
        dob: dob ? dob : null,
        blood_group,
      };
      await axios.put(`${BE_URL}/updateUser/${user.id}`, updatedUser, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      fetchUsers(); // Refresh the user list after update
      // setSelectedUser(null); // Optionally clear the selected user
      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newTokens = await refreshToken();
        if (newTokens) {
          handleSubmit(); // Retry the submission with new tokens
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to edit. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (userId) => {
    if (!window.confirm("Are you sure you want to delete this user?")) return;
    setIsLoading(true);
    try {
      console.log(userId);
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(`${BE_URL}/deleteUser/${userId}`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      setSelectedUser(null);
      fetchUsers();
      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newTokens = await refreshToken();
        if (newTokens) {
          handleDelete(userId); // Retry with new token
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete. Please try again later.");
        setisResponseOpen(true);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setPhone(user.phone);
      // setPassword(""); // Clear password for security reasons
      setRole(user.role);
    }
  }, [user]);

  return (
    <div className="uf">
      {isLoading && <Loader />}
      <Modal isOpen={isResponseOpen} onClose={() => setisResponseOpen(false)}>
        <ResponseModal
          type={responseType}
          msg={msg}
          onClick={() => setisResponseOpen(false)}
        />
      </Modal>
      <div className="uf-header">
        <div className="uf-header-name">{user.name}</div>
        {/* <Button text={`Delete`} type={`arrow`} /> */}
        {permissions?.can_delete && (
          <img
            src={deleteicon}
            alt="Delete"
            onClick={() => handleDelete(user.id)}
          />
        )}
      </div>
      <div className="uf-form">
        <div className="uf-form-visit">
          <Button
            text={`View Details`}
            type={`primary`}
            onClick={() =>
              navigate(`/admin/users/userDetails`, {
                state: { userId: user.id },
              })
            }
          />
        </div>
        <div className="uf-form-field">
          <TextField
            placeholder={`Full Name`}
            value={name}
            onChange={setName}
          />
        </div>
        <div className="uf-form-field">
          <CustomDropDown
            data={users}
            searchable={true}
            label="Manager"
            placeholder="Manager"
            onChange={setManagerId}
            value={managerId}
          />
        </div>
        <div className="uf-form-field">
          <TextField
            placeholder={"Employee ID"}
            label="Employee ID"
            value={employeeId}
            onChange={setEmployeeId}
          />
        </div>
        <div className="uf-form-field">
          <TextField
            label="Designation"
            placeholder={"Designation"}
            value={designation}
            onChange={setDesignation}
          />
        </div>

        <div className="uf-form-field">
          <TextField placeholder={`Email`} value={email} onChange={setEmail} />
        </div>
        <div className="uf-form-field">
          <TextField placeholder={`Phone`} value={phone} onChange={setPhone} />
        </div>

        <div className="uf-form-field">
          <DropDown
            options={roleOptions}
            label={`Role`}
            value={role}
            onChange={setRole}
          />
        </div>
        <div className="uf-form-field">
          <DropDown
            options={bloodgroups}
            label={`Blood Group`}
            value={blood_group}
            onChange={setblood_group}
          />
        </div>
        <div className="uf-form-field">
          <CustomTextInput
            label="Date of Birth"
            type="date"
            value={formatDate(dob)}
            onChange={(e) => setdob(e.target.value)}
          />
        </div>
        <div className="uf-form-field">
          <CustomNumberInput
            label="Salary"
            placeholder="Salary"
            value={salary}
            onChange={setSalary}
          />
        </div>

        <div className="uf-form-field">
          <TextField placeholder={`PAN`} value={pan} onChange={setpan} />
        </div>
        <div className="uf-form-field">
          <TextField
            placeholder={`PF Number`}
            value={pf_number}
            onChange={setpf_number}
          />
        </div>
        <div className="uf-form-field">
          <DropDown
            options={["Single", "Married"]}
            label={`Marital Status`}
            value={marital_state}
            onChange={setmarital_state}
          />
        </div>
        <div className="uf-form-field">
          <CustomTextInput
            label="Joining Date"
            type="date"
            value={formatDate(joining_date)}
            onChange={(e) => setjoining_date(e.target.value)}
          />
        </div>
      </div>
      {permissions?.can_delete && (
        <Button text={`Save Changes`} type={"primary"} onClick={handleSubmit} />
      )}
    </div>
  );
};

const AddUserForm = ({ onAddUser, roleOptions, users }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [designation, setDesignation] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [salary, setSalary] = useState("");
  const [pf_number, setpf_number] = useState("");
  const [pan, setpan] = useState("");
  const [joining_date, setjoining_date] = useState("");
  const [marital_state, setmarital_state] = useState("");
  const [dob, setdob] = useState("");
  const [blood_group, setblood_group] = useState("");
  const [managerId, setManagerId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (!role || !name || !email || !password) {
      alert("Name, role, email and password are mandatory!");
      return;
    }

    const newUser = {
      name,
      email,
      password,
      phone,
      role,
      designation,
      employee_id: employeeId ? employeeId : email,
      salary: salary ? salary : null,
      manager_id: managerId,
      pf_number,
      pan,
      marital_state,
      joining_date: joining_date ? joining_date : null,
      blood_group,
      dob: dob ? dob : null,
    };
    onAddUser(newUser);
  };

  return (
    <div className="add-user-form">
      <TextField placeholder="Full Name" value={name} onChange={setName} />
      <CustomDropDown
        data={users}
        searchable={true}
        label="Select Manager"
        placeholder="Select Manager"
        onChange={setManagerId}
        value={managerId}
      />
      <TextField placeholder="Email" value={email} onChange={setEmail} />
      <TextField
        placeholder="Password"
        value={password}
        onChange={setPassword}
      />
      <TextField placeholder="Phone" value={phone} onChange={setPhone} />
      <DropDown
        options={roleOptions}
        label="Role"
        value={role}
        onChange={setRole}
      />

      <CustomTextInput
        label="Date of Birth"
        type="date"
        value={dob}
        onChange={(e) => setdob(e.target.value)}
      />

      <DropDown
        options={bloodgroups}
        label={`Blood Group`}
        value={blood_group}
        onChange={setblood_group}
      />

      <TextField
        placeholder="Designation"
        value={designation}
        onChange={setDesignation}
      />
      <TextField
        placeholder="Employee ID"
        value={employeeId}
        onChange={setEmployeeId}
      />
      <CustomNumberInput
        label="Salary"
        placeholder="Salary"
        value={salary}
        onChange={setSalary}
      />

      <TextField placeholder={`PAN`} value={pan} onChange={setpan} />

      <TextField
        placeholder={`PF Number`}
        value={pf_number}
        onChange={setpf_number}
      />

      <DropDown
        options={["Single", "Married"]}
        label={`Marital Status`}
        value={marital_state}
        onChange={setmarital_state}
      />

      <CustomTextInput
        label="Joining Date"
        type="date"
        value={joining_date}
        onChange={(e) => setjoining_date(e.target.value)}
      />
      <Button text="Add User" onClick={handleSubmit} />
    </div>
  );
};
