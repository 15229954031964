import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchPermissions = createAsyncThunk(
  "permissions/fetchPermissions",
  async (_, { rejectWithValue }) => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        "https://server.classerp.in/api/permissions",
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPermissions.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchPermissions.rejected, (state, action) => {
      // handle the case when fetching permissions fails
      //   alert("User logged out. Please login again.");
    });
  },
});

export default permissionsSlice.reducer;
