import "./AssignedInventory.scss";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const AssignedInventory = ({ type, admin = false, id = null }) => {
  const [assignments, setAssignments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Function to refresh the access token
  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/refreshToken`, {
        refreshToken: tokens.refreshToken,
      });
      localStorage.setItem("ERPTokens", JSON.stringify(response.data));
      return response.data.accessToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      // Redirect to login or handle accordingly
      // navigate("/login");
      return null;
    }
  };

  const fetchAssignedProducts = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      if (!admin) {
        const decodedToken = jwtDecode(tokens.accessToken);
        const userId = decodedToken.user_id;
        const response = await axios.get(
          `${BE_URL}/api/general/assignedProducts/${type}/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${tokens.accessToken}`,
            },
          }
        );
        const data = response.data;
        const response2 = await axios.get(
          `${BE_URL}/api/general/assignedProductDevices/${type}/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${tokens.accessToken}`,
            },
          }
        );
        const data2 = response2.data;
        // Combining the two arrays of data
        const totalData = data.concat(data2);
        setAssignments(totalData);
      } else {
        const response = await axios.get(
          `${BE_URL}/api/general/assignedProducts/${type}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${tokens.accessToken}`,
            },
          }
        );
        const data = response.data;
        const response2 = await axios.get(
          `${BE_URL}/api/general/assignedProductDevices/${type}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${tokens.accessToken}`,
            },
          }
        );
        const data2 = response2.data;

        const totalData = data.concat(data2);
        setAssignments(totalData);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          fetchAssignedProducts();
        }
      } else {
        console.error("Error fetching assigned products:", error);
        // Handle other errors
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssignedProducts();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  if (!assignments.length) return <div>No products assigned.</div>;

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="assignments">
      {assignments.map((assignment) => (
        <div key={assignment.id} className="assignment-card">
          <div className="card-header">
            <h3>{assignment.name}</h3>
            {assignment.serial_no && (
              <span>Serial No: {assignment.serial_no}</span>
            )}
          </div>
          <div className="card-body">
            <p>
              <strong> Assign Date</strong> <br />{" "}
              {formatDateAndTime(assignment.assign_date)}
            </p>
            {assignment.remarks && (
              <p>
                <strong> Remarks </strong> <br /> {assignment.remarks}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AssignedInventory;
