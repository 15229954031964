import React, { useState, useEffect } from "react";
// import productForm from "./productForm";
// import productList from "./productList";
import "./Products.scss"; // Assuming you have a corresponding SCSS file
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import Modal from "../../components/Modal/Modal";
import DropDown from "../../components/DropDown/DropDown";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Products = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Inventory")
    ?.dropdowns.find((dd) => dd.name === "Products")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [is_available, setis_available] = useState(null);
  const [name, setname] = useState(null);
  const [description, setdescription] = useState(null);
  const [category_id, setcategory_id] = useState(null);
  const [category, setcategory] = useState(null);
  const [status, setstatus] = useState(null);
  const [products, setproducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [price, setprice] = useState(null);
  const [serial_no, setserial_no] = useState(null);
  const [purchase_date, setpurchase_date] = useState(null);
  const [warrenty_date, setwarrenty_date] = useState(null);
  const [bought_from, setbought_from] = useState(null);
  const [remark, setremark] = useState(null);
  const [categories, setcategories] = useState([]); // categories list for dropdown

  const [isApiAddOpen, setisApiAddOpen] = useState(true);
  const [allProducts, setAllProducts] = useState([]);

  const [selectedCategoryFilterName, setSelectedCategoryFilterName] =
    useState("All Categories");
  const [selectedCategoryFilterid, setSelectedCategoryFilterid] =
    useState(null);
  const [selectedAvailabilityFilter, setSelectedAvailabilityFilter] =
    useState("");
  const [selectedRoleFilter, setSelectedRoleFilter] = useState("");

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Fetch products from API
  useEffect(() => {
    fetchcategories();
    fetchproducts();
  }, []);

  const applyFilters = () => {
    let filteredProducts = [...allProducts];

    // Apply category filter
    if (selectedCategoryFilterid) {
      filteredProducts = filteredProducts.filter(
        (product) => product.category_id == selectedCategoryFilterid
      );
    }

    // Apply availability filter
    if (selectedAvailabilityFilter === "Available") {
      filteredProducts = filteredProducts.filter(
        (product) => product.is_available === true
      );
    } else if (selectedAvailabilityFilter === "Allocated") {
      filteredProducts = filteredProducts.filter(
        (product) => product.is_available === false
      );
    }

    // Apply status filter
    if (selectedRoleFilter && selectedRoleFilter !== "All") {
      filteredProducts = filteredProducts.filter(
        (product) => product.status === selectedRoleFilter
      );
    }

    setproducts(filteredProducts); // Update the displayed products based on filters
  };

  useEffect(() => {
    setSelectedItem(null);
    applyFilters();
  }, [
    selectedCategoryFilterid,
    selectedRoleFilter,
    selectedAvailabilityFilter,
    allProducts,
  ]);

  useEffect(() => {
    if (!selectedItem) {
      return;
    }

    setis_available(selectedItem.is_available);
    setname(selectedItem.name);
    setcategory_id(selectedItem.category_id);
    setcategory(
      selectedItem.category_id
        ? categories.filter((item) => item.id == selectedItem.category_id)[0]
            .name
        : null
    );
    setdescription(selectedItem.description || "");
    setserial_no(selectedItem.serial_no || "");
    setprice(selectedItem.price || "");
    setstatus(selectedItem.status);
    setpurchase_date(
      selectedItem.purchase_date
        ? selectedItem.purchase_date.split("T")[0]
        : null
    );
    setwarrenty_date(
      selectedItem.warrenty_date
        ? selectedItem.warrenty_date.split("T")[0]
        : null
    );
    setbought_from(selectedItem.bought_from || "");
    setremark(selectedItem.remark || "");
  }, [selectedItem]);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchcategories = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(
        `${BE_URL}/api/general/categories/Products`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
          },
        }
      );
      setcategories(response.data); // Assuming the API returns an array of class objects
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch categories. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchproducts = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/products`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setAllProducts(response.data); // Store all products fetched
      setproducts(response.data); // Initially, display all products
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchproducts(); // Retry fetching products with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch products.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (newVendor) => {
    setIsLoading(true);
    setError(null);
    setisAddModalOpen(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/api/products`, newVendor, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
      fetchproducts();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(newVendor); // Retry adding product with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else if (error.response && error.response.status === 400) {
        alert("Serial No already exists.");
      } else {
        setresponseType("Error");
        setmsg("Failed to Add Fee Structure");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
      setisAddModalOpen(false);
    }
  };

  const handleEdit = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.put(
        `${BE_URL}/api/products/${selectedItem.id}`,
        {
          name: name,
          category_id: category_id,
          description: description,
          price,
          serial_no,
          status,
          purchase_date,
          warrenty_date,
          bought_from,
          remark,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Update the products list with the edited product
      const updatedproducts = products.map((sub) =>
        sub.id === response.data.id ? response.data : sub
      );
      setproducts(updatedproducts);
      fetchproducts();
      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);
      setSelectedItem(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleEdit(); // Retry editing product with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to update. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (productId) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(`${BE_URL}/api/products/${selectedItem.id}`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      fetchproducts();
      setSelectedItem(null);
      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(productId); // Retry deleting product with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete Fee Structure");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="halfpage">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="halfpage-header">
            <div className="halfpage-header-name">Products</div>

            <div className="halfpage-header-filters">
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">
                  Choose Status
                </div>
                <DropDown
                  options={["All", "Good", "Damaged"]}
                  value={selectedRoleFilter}
                  onChange={(value) => setSelectedRoleFilter(value)}
                />
              </div>
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">
                  Choose Availability
                </div>
                <DropDown
                  options={["All", "Available", "Allocated"]}
                  value={selectedAvailabilityFilter}
                  onChange={(value) => setSelectedAvailabilityFilter(value)}
                />
              </div>
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">
                  Choose Category
                </div>
                <ObjectDropDown
                  options={[
                    { label: "", value: "All Categories" },
                    ...categories.map((category) => ({
                      label: category.id,
                      value: category.name,
                    })),
                  ]}
                  setId={setSelectedCategoryFilterid}
                  value={selectedCategoryFilterName}
                  setValue={setSelectedCategoryFilterName}
                />
              </div>
            </div>
          </div>
          <div className="halfpage-contents">
            <div className="halfpage-contents-list">
              <HalfList
                data={products}
                fields={["name", "serial_no"]}
                setSelectedItem={setSelectedItem}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                elem={
                  <AddProduct
                    handleAdd={(newDivision) => handleAdd(newDivision)}
                    categories={categories}
                    isApiAddOpen={isApiAddOpen}
                  />
                }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="halfpage-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={selectedItem.name}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    can_delete={permissions?.can_delete}
                    can_update={permissions?.can_update}
                  >
                    <DetailsChild
                      refreshToken={refreshToken}
                      selectedItem={selectedItem}
                      name={name}
                      is_available={is_available}
                      serial_no={serial_no}
                      price={price}
                      description={description}
                      purchase_date={purchase_date}
                      warrenty_date={warrenty_date}
                      bought_from={bought_from}
                      remark={remark}
                      category={category}
                      status={status}
                      setstatus={setstatus}
                      setname={setname}
                      setis_available={setis_available}
                      setcategory_id={setcategory_id}
                      setcategory={setcategory}
                      setdescription={setdescription}
                      categories={categories}
                      setprice={setprice}
                      setserial_no={setserial_no}
                      setpurchase_date={setpurchase_date}
                      setwarrenty_date={setwarrenty_date}
                      setbought_from={setbought_from}
                      setremark={setremark}
                    ></DetailsChild>
                  </HalfDetails>
                  <AssignmentHistory assignments={selectedItem.assignments} />
                </div>
              ) : (
                <div className="halfpage-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Products;

const AddProduct = ({ handleAdd, categories }) => {
  const [name, setname] = useState(null);
  const [category_id, setcategory_id] = useState(null);
  const [class_val, setclass_val] = useState(null);
  const [description, setdescription] = useState(null);
  const [price, setprice] = useState(null);
  const [status, setstatus] = useState(null);
  const [category, setcategory] = useState(null);
  const [serial_no, setserial_no] = useState(null);
  const [purchase_date, setpurchase_date] = useState(null);
  const [warrenty_date, setwarrenty_date] = useState(null);
  const [bought_from, setbought_from] = useState(null);
  const [remark, setremark] = useState(null);

  const handleSubmit = () => {
    if (!name || !category_id || !serial_no) {
      alert("Vendor Name, Category & Serial No is mandatory!");
      return;
    }
    const newVendor = {
      category_id,
      name,
      description,
      price,
      status,
      serial_no,
      purchase_date,
      warrenty_date,
      bought_from,
      remark,
    };
    handleAdd(newVendor);
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add a Product</div>
      <CustomTextInput
        label="Name"
        placeholder=" Name"
        value={name}
        onChange={(e) => setname(e.target.value)}
      />
      <ObjectDropDown
        label="Select Category"
        options={categories.map(({ name, id }) => ({
          label: id,
          value: name,
        }))}
        value={class_val}
        setId={setcategory_id}
        setValue={setclass_val}
      />
      <CustomTextInput
        value={description}
        label={`Description`}
        placeholder={`Description`}
        onChange={(e) => setdescription(e.target.value)}
      />
      <CustomTextInput
        value={serial_no}
        label={`Serial no`}
        placeholder={`Serial no`}
        onChange={(e) => setserial_no(e.target.value)}
      />
      <ObjectDropDown
        label="Select Product Status"
        options={[
          {
            label: "Good",
            value: "Good",
          },
          {
            label: "Damaged",
            value: "Damaged",
          },
        ].map(({ label, value }) => ({
          label: label,
          value: value,
        }))}
        value={status}
        setId={() => {}}
        setValue={setstatus}
      />
      <CustomNumberInput
        value={price}
        label={`Price`}
        placeholder={`Price`}
        onChange={(e) => setprice(e)}
      />

      <CustomTextInput
        type={`Date`}
        value={purchase_date}
        label={`Purchase Date`}
        placeholder={`Purchase Date`}
        onChange={(e) => setpurchase_date(e.target.value)}
      />

      <CustomTextInput
        type={`Date`}
        value={warrenty_date}
        label={`Warrenty Date`}
        placeholder={`Warrenty Date`}
        onChange={(e) => setwarrenty_date(e.target.value)}
      />

      <CustomTextInput
        value={bought_from}
        label={`Bought From`}
        placeholder={`Bought From`}
        onChange={(e) => setbought_from(e.target.value)}
      />

      <CustomTextInput
        value={remark}
        label={`Remarks`}
        placeholder={`Remarks`}
        onChange={(e) => setremark(e.target.value)}
      />

      <Button text="Add Product" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({
  refreshToken,
  selectedItem,
  status,
  name,
  is_available,
  serial_no,
  price,
  description,
  purchase_date,
  warrenty_date,
  bought_from,
  remark,
  category,
  categories,
  setname,
  setstatus,
  setis_available,
  setcategory_id,
  setcategory,
  setdescription,
  setserial_no,
  setprice,
  setpurchase_date,
  setwarrenty_date,
  setbought_from,
  setremark,
}) => {
  const [isAssignOpen, setisAssignOpen] = useState(false);
  const [isSubmitOpen, setisSubmitOpen] = useState(false);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);

  const handleAssign = async (assignmentData) => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        `${BE_URL}/api/productAssignments`,
        assignmentData,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );

      setisAssignOpen(false);
      setis_available(false);
      setresponseType("Success");
      setmsg("Product Assigned Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // If token is expired, try to refresh it
        const refreshed = await refreshToken();
        if (refreshed) {
          handleAssign(assignmentData); // Retry the request with the new token
        } else {
          // Handle failed token refresh here (e.g., redirect to login)
          alert("Session expired. Please login again.");
          // navigate("/login");
        }
      } else if (error.response && error.response.status === 404) {
        setisAssignOpen(false);
        setresponseType("Error");
        setmsg("Not Found");
        setisResponseOpen(true);
      } else {
        // Handle other errors
        setisAssignOpen(false);
        setresponseType("Error");
        setmsg("Failed to Assign Product");
        setisResponseOpen(true);
      }
    }
  };

  const handleSubmitBack = async (assignmentData) => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.put(
        `${BE_URL}/api/productAssignments/${selectedItem.assignment_id}`,
        assignmentData,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );

      // Success handling here
      setis_available(true);
      setisSubmitOpen(false);
      setresponseType("Success");
      setmsg("Product submitted back successfully");
      setisResponseOpen(true);
      // Possibly update state or redirect user
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // If token is expired, try to refresh it
        const refreshed = await refreshToken();
        if (refreshed) {
          handleSubmitBack(assignmentData); // Retry the request with the new token
        } else {
          // Handle failed token refresh here (e.g., redirect to login)
          alert("Session expired. Please login again.");
          // navigate("/login");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to submit product. Please try again later.");
        setisResponseOpen(true);
      }
    }
  };

  return (
    <div className="dc">
      <Modal isOpen={isResponseOpen} onClose={() => setisResponseOpen(false)}>
        <ResponseModal
          type={responseType}
          msg={msg}
          onClick={() => setisResponseOpen(false)}
        />
      </Modal>
      <div className="dc-header">
        {!is_available && (
          <div className="dc-header-info">
            &#9432; This product is allocated .
          </div>
        )}
        <div className="dc-header-cta">
          {is_available ? (
            <Button text={`Assign`} onClick={() => setisAssignOpen(true)} />
          ) : (
            <Button
              text={`Submit Back`}
              onClick={() => setisSubmitOpen(true)}
            />
          )}
        </div>
      </div>

      <Modal isOpen={isAssignOpen} onClose={() => setisAssignOpen(false)}>
        <ProductAssign
          product_id={selectedItem.id}
          handleAssign={handleAssign}
        />
      </Modal>
      <Modal isOpen={isSubmitOpen} onClose={() => setisSubmitOpen(false)}>
        <ProductSubmit
          product_id={selectedItem.id}
          handleSubmitBack={handleSubmitBack}
        />
      </Modal>
      <div className="dc-input">
        <CustomTextInput
          value={name}
          label={`Name`}
          onChange={(e) => setname(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <ObjectDropDown
          label="Type"
          options={categories.map(({ name, id }) => ({
            label: id,
            value: name,
          }))}
          value={category}
          setId={setcategory_id}
          setValue={setcategory}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={description}
          label={`Description`}
          placeholder={`Description`}
          onChange={(e) => setdescription(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={serial_no}
          label={`Serial No`}
          placeholder={`Serial No`}
          onChange={(e) => setserial_no(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <ObjectDropDown
          label="Select Product Status"
          options={[
            {
              label: "Good",
              value: "Good",
            },
            {
              label: "Damaged",
              value: "Damaged",
            },
          ].map(({ label, value }) => ({
            label: label,
            value: value,
          }))}
          value={status}
          setId={() => {}}
          setValue={setstatus}
        />
      </div>
      <div className="dc-input">
        <CustomNumberInput
          value={price}
          label={`Price`}
          placeholder={`Price`}
          onChange={(e) => setprice(e)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          type="date"
          value={purchase_date || ""} // Convert null to empty string for input compatibility
          label="Purchase Date"
          placeholder="Purchase Date"
          onChange={(e) => setpurchase_date(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          type="date"
          value={warrenty_date || ""} // Convert null to empty string for input compatibility
          label="Warranty Date"
          placeholder="Warranty Date"
          onChange={(e) => setwarrenty_date(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={bought_from}
          label={`Bought From`}
          placeholder={`Bought From`}
          onChange={(e) => setbought_from(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={remark}
          label={`Remarks`}
          placeholder={`Remarks`}
          onChange={(e) => setremark(e.target.value)}
        />
      </div>
    </div>
  );
};

const ProductAssign = ({ handleAssign, product_id }) => {
  const [assigneeId, setassigneeId] = useState(null);
  const [assignee, setassignee] = useState(null);
  const [assign_date, setassign_date] = useState(null);
  const [return_date, setreturn_date] = useState(null);
  const [status, setstatus] = useState(null);
  const [remarks, setremarks] = useState(null);

  const handleSubmit = () => {
    if (!assigneeId || !assignee || !assign_date) {
      alert("Assignee Type, Id/Roll No & Date  is mandatory!");
      return;
    }
    const newAssignment = {
      assignee,
      product_id,
      assigneeId,
      assign_date,
      return_date,
      remarks,
      status,
    };
    handleAssign(newAssignment);
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Assign Product</div>
      <ObjectDropDown
        label="Select Assignee Type"
        options={[
          {
            label: "Student",
            value: "Student",
          },
          {
            label: "Staff",
            value: "Staff",
          },
        ].map(({ label, value }) => ({
          label: label,
          value: value,
        }))}
        value={assignee}
        setId={() => {}}
        setValue={setassignee}
      />
      <div className="halfAdd-person">
        <div className="halfadd-person-fields">
          <CustomTextInput
            label="Assignee Id / Roll No"
            placeholder="Name"
            value={assigneeId}
            onChange={(e) => setassigneeId(e.target.value)}
          />
          {/* <Button text={`Check`} type={`mini`} /> */}
        </div>
        {/* {useravailablity && <div className="halfAdd-person-status">useravailablity</div> } */}
      </div>

      <CustomTextInput
        type={`Date`}
        value={assign_date}
        label={`Assign Date`}
        placeholder={`Assign Date`}
        onChange={(e) => setassign_date(e.target.value)}
      />

      {/* <CustomTextInput
        type={`Date`}
        value={return_date}
        label={`Return Date`}
        placeholder={`Return Date`}
        onChange={(e) => setreturn_date(e.target.value)}
      /> */}

      <CustomTextInput
        value={remarks}
        label={`Remarks`}
        placeholder={`Remarks`}
        onChange={(e) => setremarks(e.target.value)}
      />

      <Button text="Assign" onClick={handleSubmit} />
    </div>
  );
};

const ProductSubmit = ({ handleSubmitBack, product_id }) => {
  const [return_date, setreturn_date] = useState(null);
  const [status, setstatus] = useState(null);

  const handleSubmit = () => {
    if (!return_date || !status) {
      alert("Please select status and return Date");
      return;
    }
    const submitAssignment = {
      product_id,
      return_date,
      status,
    };
    handleSubmitBack(submitAssignment);
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Submit Back Product</div>
      <ObjectDropDown
        label="Select Product Status"
        options={[
          {
            label: "Good",
            value: "Good",
          },
          {
            label: "Damaged",
            value: "Damaged",
          },
        ].map(({ label, value }) => ({
          label: label,
          value: value,
        }))}
        value={status}
        setId={() => {}}
        setValue={setstatus}
      />

      <CustomTextInput
        type={`Date`}
        value={return_date}
        label={`Return Date`}
        placeholder={`Return Date`}
        onChange={(e) => setreturn_date(e.target.value)}
      />

      <Button text="Submit Back" onClick={handleSubmit} />
    </div>
  );
};

const AssignmentHistory = ({ assignments }) => {
  return (
    <div className="assignhistory">
      <div className="assignhistory-title">Product Assignment History</div>
      {assignments && assignments.length > 0 ? (
        <div className="assignments-table">
          <div className="table-header">
            <div>Assignee</div>
            <div>Assign Date</div>
            <div>Return Date</div>
            <div>Status</div>
            <div>Remarks</div>
          </div>
          {assignments.map((assignment) => (
            <div
              key={assignment.id}
              className={
                assignment.return_date ? "table-row" : "table-row-green"
              }
            >
              <div>{assignment.assignee_id}</div>
              <div>
                {assignment.assign_date /* Use format function if needed */}
              </div>
              <div>{assignment.return_date || "N/A"}</div>
              <div>{assignment.status}</div>
              <div>{assignment.remarks || "None"}</div>
            </div>
          ))}
        </div>
      ) : (
        <p>No assignments found for this product.</p>
      )}
    </div>
  );
};
