import React, { useState } from "react";
import axios from "axios";
import "./ExamDataUpload.scss";
import DatePicker from "../../components/DatePicker/DatePicker";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const ExamDataUpload = () => {
  const [examType, setExamType] = useState("");
  const [examName, setExamName] = useState(""); // State for exam name
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [examDate, setExamDate] = useState("");

  const examtypes = [
    { name: "JEE Main", id: "jee_main" },
    { name: "JEE Adv", id: "jee_adv" },
    { name: "NEET", id: "neet" },
    { name: "CET", id: "cet" },
  ];

  const examOptions = [
    { id: "JEE Main", name: "JEE Main" },
    { id: "JEE Adv", name: "JEE Adv" },
    { id: "NEET", name: "NEET" },
    { id: "CET", name: "CET" },
  ];

  const downloadSample = async () => {
    if (!examType) {
      alert("Please select an exam type first.");
      return;
    }
    setLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `${BE_URL}/api/exams/sample/${examType}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${examType}_sample.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading sample file:", error);
      alert("Failed to download the sample file.");
    }
    setLoading(false);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async () => {
    if (!file || !examType || !examDate || !examName) {
      alert("Please fill all fields before uploading.");
      return;
    }
    if (
      !window.confirm(
        `Are you sure you want to upload this file as ${examType}?`
      )
    ) {
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("examDate", examDate);
    formData.append("examType", examType);
    formData.append("examName", examName); // Append exam name to the form data

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.post(`${BE_URL}/api/exams/upload/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });
      alert("Data uploaded successfully!");
      setFile(null);
      setExamDate("");
      setExamType("");
      setExamName(""); // Reset exam name
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload the file.");
    }
    setLoading(false);
  };

  return (
    <div className="exam-upload">
      <h2>Exam Data Management</h2>
      <div className="section">
        <h3>Download Sample File</h3>
        <CustomDropDown
          label="Exam Type"
          data={examtypes}
          value={examType}
          onChange={setExamType}
          searchable={false}
          // clearable={true}
        />
        {/* <select onChange={(e) => setExamType(e.target.value)} value={examType}>
          <option value="">Select Exam Type</option>
          {examtypes.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select> */}
        <button
          className="exam-button"
          onClick={downloadSample}
          disabled={!examType || loading}
        >
          Download Sample File
        </button>
      </div>
      <div className="section">
        <h3>Upload Exam Data</h3>
        {/* <DatePicker
          label={`Exam Date`}
          value={examDate}
          onChange={setExamDate}
        /> */}
        <CustomTextInput
          label="Date"
          type="date"
          value={examDate}
          onChange={(e) => setExamDate(e.target.value)}
        />
        {/* <input
          type="date"
          value={examDate}
          onChange={(e) => setExamDate(e.target.value)}
        /> */}
        <CustomTextInput
          value={examName}
          label={`Exam Name`}
          onChange={(e) => setExamName(e.target.value)}
          placeholder="Enter exam name"
        />
        {/* <input
          type="text"
          value={examName}
          onChange={(e) => setExamName(e.target.value)}
          label="Enter exam name"
          placeholder="Enter exam name"
        /> */}
        <CustomDropDown
          label="Exam Type"
          data={examOptions}
          value={examType}
          onChange={setExamType}
          searchable={false}
          // clearable={true}
        />
        {/* <select onChange={(e) => setExamType(e.target.value)} value={examType}>
          <option value="">Select Exam Type</option>
          {examOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select> */}
        <input type="file" onChange={handleFileChange} />
        <button
          className="exam-button"
          onClick={uploadFile}
          disabled={!examType || !file || !examDate || !examName || loading}
        >
          Upload Exam Data
        </button>
      </div>
      {loading && <p>Loading...</p>}
    </div>
  );
};

export default ExamDataUpload;
