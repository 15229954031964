import React, { useState, useEffect } from "react";
import "./ExamMarks.scss"; // Assuming you have a corresponding SCSS file
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import DropDown from "../../components/DropDown/DropDown";
import { DateRangePicker } from "rsuite";
import ResponseModal from "../../components/ResponseModal/ResponseModal";
import Modal from "../../components/Modal/Modal";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const ExamMarks = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Students")
    ?.dropdowns.find((dd) => dd.name === "Exam Marks")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [exams, setexams] = useState([]);
  const [allexams, setallexams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [audienceFilter, setaudienceFilter] = useState(null);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  const examOptions = [
    { name: "JEE Main", id: "JEE Main" },
    { name: "JEE Adv", id: "JEE Adv" },
    { name: "NEET", id: "NEET" },
    { name: "CET", id: "CET" },
  ];

  // Initialize the date range state
  const [dateRange, setDateRange] = useState(() => {
    const today = new Date();
    const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));
    return [lastMonth, today];
  });

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      fetchexams(dateRange[0], dateRange[1]);
    }
  }, [dateRange]);

  useEffect(() => {
    setSelectedItem(null);
    applyFilters();
  }, [audienceFilter]);

  const applyFilters = () => {
    let filteredDocs = [...allexams];

    // Apply status filter
    if (audienceFilter && audienceFilter !== "All") {
      filteredDocs = filteredDocs.filter(
        (item) => item.exam_type === audienceFilter
      );
    }

    setexams(filteredDocs); // Update the displayed products based on filters
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchexams = async (startDate, endDate) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const formattedStartDate = startDate.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
      const formattedEndDate = endDate.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'

      const response = await axios.get(`${BE_URL}/api/exams/getExams`, {
        params: { startDate: formattedStartDate, endDate: formattedEndDate },
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setallexams(response.data);
      setexams(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchexams(); // Retry fetching exams with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch exams.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="halfpage">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="halfpage-header">
            <div className="halfpage-header-name">Exams</div>
            <div className="halfpage-header-filters">
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Date Range</div>
                <DateRangePicker
                  format="yyyy-MM-dd"
                  onChange={handleDateRangeChange}
                  cleanable={false}
                  value={dateRange}
                />
              </div>
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Exam Type</div>
                {/* <CustomDropDown
                  data={examOptions}
                  value={audienceFilter}
                  onChange={setaudienceFilter}
                  searchable={false}
                /> */}
                <DropDown
                  options={["All", "JEE Main", "JEE Adv", "NEET", "CET"]}
                  value={audienceFilter}
                  onChange={(value) => setaudienceFilter(value)}
                />
              </div>
            </div>
          </div>
          <div className="halfpage-contents">
            <div className="halfpage-contents-list">
              <HalfList
                data={exams}
                fields={["exam_name", "exam_type"]}
                setSelectedItem={setSelectedItem}
                // isAddModalOpen={isAddModalOpen}
                // can_create={permissions?.can_create}
                // elem={
                //   <AddDocument
                //     handleAdd={(newDivision) => handleAdd(newDivision)}
                //   />
                // }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="halfpage-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={selectedItem.exam_name}
                    // handleEdit={handleEdit}
                    // handleDelete={handleDelete}
                    can_delete={false}
                    can_update={false}
                  >
                    <DetailsChild selectedItem={selectedItem}></DetailsChild>
                  </HalfDetails>
                </div>
              ) : (
                <div className="halfpage-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ExamMarks;

const DetailsChild = ({ selectedItem }) => {
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    // const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate}`;
  };
  return (
    <div className="dc">
      <div className="dc-text">
        <div className="dc-text-label">File Name</div>
        <div className="dc-text-data">{selectedItem.exam_name}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Exam Date</div>
        <div className="dc-text-data">{formatDate(selectedItem.exam_date)}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Type</div>
        <div className="dc-text-data">
          <div className="dc-text-data">{selectedItem.exam_type}</div>
        </div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">File</div>
        <div className="dc-text-data">
          <a href={selectedItem.file_url}>Download</a>
        </div>
      </div>
    </div>
  );
};
