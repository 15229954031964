import "./Button.scss";

import React from "react";

function Button({ text, onClick, type, disabled }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled ? true : false}
      className={`button button-${type}`}
    >
      {text}
      {`   `}
    </button>
  );
}

export default Button;
