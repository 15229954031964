import "./About.scss";

import React from "react";
import aboutbg from "../../assets/images/aboutbg.jpg";
import result23 from "../../assets/images/result23.jpg";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

const difference = [
  {
    id: 1,
    data: "150+ incredible teachers from top IITs/NITs/AIIMS.",
  },
  {
    id: 2,
    data: "400+ students cracked IITs/NITs/AIIMS & other top institutions.",
  },
  {
    id: 3,
    data: "World class lavish infrastructure for holistic growth.",
  },
  {
    id: 4,
    data: "Futuristic digital ARM for advanced learning and management.",
  },
  {
    id: 5,
    data: "An ambiance of growth, support and mentorship for our students.",
  },
];

const About = () => {
  const navigate = useNavigate();

  const enquiryClicked = () => {
    navigate("/enquiry");
  };

  return (
    <div className="about">
      <div
        className="about-hero"
        style={{ backgroundImage: `url(${aboutbg})` }}
      >
        <div className="about-hero-title">About Us</div>
        <div className="about-hero-sep"></div>
        <div className="about-hero-name">Chaitanya's Academy</div>
        <div className="about-hero-subtext">Crafting stories of Success</div>
      </div>

      <div className="about-result">
        {/* <div className="about-result-sep"></div> */}

        <div className="about-result-content">
          <div className="about-result-content-left">
            <img src={result23} alt="Chaitanyas Academy 2023 result" />
            <div className="about-result-content-left-caption">
              A glimpse of 2023 result
            </div>
          </div>

          <div className="about-result-content-right">
            <div className="about-result-content-right-content">
              <div className="about-result-content-right-content-heading">
                Igniting Minds, Shaping Futures
              </div>
              <div className="about-result-content-right-content-info">
                Welcome to Chaitainya’s Academy, where every learner finds their
                path. At Chaitainya’s, we don’t just educate; we ignite
                curiosity, inspire leaders, and cultivate innovators. Embark on
                a journey where your educational aspirations meet our passion
                for nurturing minds.
              </div>
              <div className="about-result-content-right-content-button">
                <Button text={`Enquiry`} onClick={enquiryClicked} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="about-result-sep"></div> */}
      </div>

      <div className="about-philosophy">
        <div className="about-philosophy-heading">Our Philosophy</div>
        <div className="about-philosophy-content">
          At Chaitanyas, we believe that education is the seed from which dreams
          sprout. Our philosophy revolves around holistic education, where we
          cultivate not just academicians but individuals who thrive in every
          aspect of life. We nurture minds with knowledge, hearts with empathy,
          and spirits with determination, ensuring that our learners are
          equipped to harvest their dreams in the future.
        </div>
      </div>

      <div className="about-difference">
        <div className="about-difference-heading">What makes us different?</div>
        <div className="about-difference-content">
          {difference.map((value) => (
            <DifferenceCard key={value.id} id={value.id} data={value.data} />
          ))}
        </div>
      </div>

      <div className="about-mv">
        <div className="about-mv-heading">Mission and Vision</div>
        <div className="about-mv-content">
          <div className="about-mv-content-mission">
            <div className="about-mv-content-mission-title">Our Mission</div>
            <div className="about-mv-content-mission-data">
              At Chaitainya's Academy, we are steadfastly dedicated to
              cultivating a nurturing environment where aspirations meet
              expertise and dreams translate into success. Our mission is to
              provide unparalleled coaching, fostering an educational realm
              where students are empowered with the knowledge, skills, and
              confidence to conquer the pivotal exams of JEE Mains and Advanced,
              NEET, and MHT-CET. Through meticulous curriculum design,
              personalized mentoring, and a blend of traditional and innovative
              teaching methodologies, we strive to illuminate the path for our
              students, ensuring that every individual experiences academic
              excellence, personal growth, and triumph in their educational
              journey.
            </div>
          </div>

          <div className="about-mv-content-vision">
            <div className="about-mv-content-vision-title">Our Vision</div>
            <div className="about-mv-content-vision-data">
              We envision Chaitanya's Academy as a beacon of educational
              excellence and a catalyst for developing future leaders in Pune,
              India, and beyond. Our sight is set on being the epitome of
              transformative education, where students from diverse backgrounds
              are equipped with the knowledge and skills to navigate through the
              challenging landscapes of JEE, NEET, and MHT-CET. We aspire to
              sculpt individuals who are not only academically brilliant but
              also embody values, resilience, and a lifelong zest for learning.
              By intertwining our expertise with their dreams, we aim to craft
              futures where our students emerge as triumphant leaders, making
              significant contributions to society and the global community.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

const DifferenceCard = ({ id, data }) => {
  return (
    // <div
    //   className={`${id === 2 ? "differenceCard-movedown" : "differenceCard"}`}
    // >
    <div className="differenceCard">
      <div className="differenceCard-id">{id}</div>
      <div className="differenceCard-data">{data}</div>
    </div>
  );
};
