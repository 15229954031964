import React from "react";
import "./LeavesList.scss"; // Update your SCSS file path as needed

const LeavesList = ({ leaveApplications, cancel_allowed }) => {
  const handleCancelLeave = (leaveId) => {
    // Implement cancel leave functionality
    console.log(`Cancelling leave with ID: ${leaveId}`);
    // Likely making an API call here
  };

  return (
    <div className="leaves-list-container">
      {leaveApplications && leaveApplications.length > 0 ? (
        <div className="leaves-list">
          <div className="list-header">
            {leaveApplications[0].leave_type !== "staff" && (
              <div className="header-item type">Type</div>
            )}
            <div className="header-item start-date">Start Date</div>
            <div className="header-item end-date">End Date</div>
            <div className="header-item approver">Approver</div>
            <div className="header-item reason">Reason</div>
            <div className="header-item remarks">Remarks</div>
            <div className="header-item status">Status</div>
            {cancel_allowed && <div className="header-item action">Action</div>}
          </div>
          {leaveApplications.map((leave) => (
            <div key={leave.id} className="list-row">
              {leave.leave_type !== "staff" && (
                <div className="row-item type">{leave.leave_type}</div>
              )}
              <div className="row-item start-date">
                {new Date(leave.start_date).toLocaleDateString()}
              </div>
              <div className="row-item end-date">
                {new Date(leave.end_date).toLocaleDateString()}
              </div>
              <div className="row-item approver">{leave.approver_name}</div>
              <div className="row-item reason">{leave.reason}</div>
              <div className="row-item remarks">{leave.remarks}</div>
              <div
                className={
                  leave.status === "Approved"
                    ? "row-item statusgreen"
                    : "row-item statusred"
                }
              >
                {leave.status}
              </div>
              {cancel_allowed && leave.status === "Pending" && (
                <div className="row-item action">
                  <button onClick={() => handleCancelLeave(leave.id)}>
                    Cancel
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className="no-leaves">No leaves yet.</p>
      )}
    </div>
  );
};

export default LeavesList;
