import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, DatePicker, Input, Panel, List } from "rsuite";
import "./LeaveApplication.scss"; // Import SCSS for styling
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import DropDown from "../../components/DropDown/DropDown";
import { nodeName } from "rsuite/esm/DOMHelper";
import LeavesList from "../../components/LeavesList/LeavesList";
import Loader from "../../components/Loader/Loader";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const LeaveApplication = () => {
  // Initialize state
  const [userDetails, setUserDetails] = useState({});
  const [approverOptions, setApproverOptions] = useState([]);
  const [isApplyOpen, setisApplyOpen] = useState(false);
  const [leaveType, setleaveType] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [leaves, setleaves] = useState([]);

  useEffect(() => {
    fetchUserDetails();
  }, []);

  // Fetch user details
  const fetchUserDetails = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `${BE_URL}/api/general/preLeaveDetails`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setUserDetails(response.data);
      determineApproverOptions(response.data.type);
      setleaves(response.data.lea);
    } catch (error) {
      console.error("Error fetching user details:", error);
      alert("Failed to fetch User Details.");
    } finally {
      setIsLoading(false);
    }
  };

  // Determine approver options based on user type
  const determineApproverOptions = (userType) => {
    if (userType === "student") {
      setApproverOptions([
        { value: "Student-Academic", label: "Academic" },
        { value: "Student-Hostel", label: "Hostel" },
      ]);
    } else if (userType === "staff") {
      setApproverOptions([{ value: "Staff", label: "Manager" }]);
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="leaves">
      {isLoading && <Loader />}
      <Modal isOpen={isApplyOpen} onClose={() => setisApplyOpen(false)}>
        <LeaveApplicationForm
          approverOptions={approverOptions}
          userDetails={userDetails.details}
          fetchUserDetails={fetchUserDetails}
          setisApplyOpen={setisApplyOpen}
        />
      </Modal>
      <div className="leaves-header">
        <div className="leaves-header-title"> My Leaves</div>
        <Button
          type={`primary`}
          text={`Apply`}
          onClick={() => setisApplyOpen(true)}
        />
      </div>
      <div className="leaves-list">
        <LeavesList
          leaveApplications={userDetails.leaveApplications}
          cancel_allowed={false}
        />
      </div>
    </div>
  );
};

export default LeaveApplication;

const LeaveApplicationForm = ({
  userDetails,
  approverOptions,
  fetchUserDetails,
  setisApplyOpen,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [reason, setReason] = useState("");
  const [leaveType, setLeaveType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const leaveOptions = ["Class", "Hostel"];

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Handle form submission
  const handleSubmit = async () => {
    const type = userDetails.type === "staff" ? "staff" : leaveType;
    if (!startDate || !endDate || !type || !reason) {
      alert("All fields are mandatory");
      return;
    }

    setIsLoading(true);

    const approver_id =
      type === "staff"
        ? userDetails.approver_id
        : leaveType === "Class"
        ? userDetails.teacher_id
        : userDetails.warden_id;

    console.log(approver_id);
    const leaveApplication = {
      leaveType: type,
      startDate,
      endDate,
      reason,
      requester_code: userDetails.requester_code,
      approver_id,
    };

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        `${BE_URL}/api/general/applyLeave`,
        leaveApplication,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      fetchUserDetails();
      setisApplyOpen(false);

      setresponseType("Success");
      setmsg(
        "Leave applied  Successfully. You will be notified when it is reviewed."
      );
      setisResponseOpen(true);
      setIsLoading(false);
    } catch (error) {
      setresponseType("Error");
      setmsg("Something went wrong. Please try again later.");
      setisResponseOpen(true);
    }
  };

  return (
    <div className="leave-application-form">
      {isLoading && <Loader />}
      <Form fluid>
        {userDetails.type !== "staff" && (
          <Form.Group controlId="leaveType">
            {userDetails.type}
            <Form.ControlLabel>Leave Type</Form.ControlLabel>
            <DropDown
              options={leaveOptions}
              value={leaveType}
              onChange={setLeaveType}
            />
          </Form.Group>
        )}
        <Form.Group controlId="startDate">
          <CustomTextInput
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="endDate">
          <CustomTextInput
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="reason">
          <Form.ControlLabel>Reason</Form.ControlLabel>
          <Input as="textarea" rows={3} value={reason} onChange={setReason} />
        </Form.Group>
        <Button type="primary" text={`Submit`} onClick={handleSubmit}>
          Submit
        </Button>
      </Form>
    </div>
  );
};
