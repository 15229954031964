// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user_id: null,
    name: null,
    email: null,
    role_id: null,
    // Initial user state is null
  },
  reducers: {
    setUser: (state, action) => {
      state.user_id = action.payload.user_id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.role_id = action.payload.role_id;
    },
    clearUser: (state) => {
      state.user_id = null;
      state.name = null;
      state.email = null;
      state.role_id = null;
    },
  },
});

export const { setUser, clearUser } = authSlice.actions;

export default authSlice.reducer;
