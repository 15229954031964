import "./HalfDetails.scss";

import deleteicon from "../../assets/images/Delete.png";

import React, { useState } from "react";
import Modal from "../Modal/Modal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Button from "../Button/Button";

const HalfDetails = ({
  id,
  name,
  handleDelete,
  handleEdit,
  children,
  can_delete,
  can_update,
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const handTest = () => {
    handleEdit();
    setIsEditOpen(false);
  };
  const handleDeleteHere = () => {
    handleDelete(id);
    setIsDeleteOpen(false);
  };

  return (
    <div className="hdetails">
      <Modal isOpen={isDeleteOpen} onClose={() => setIsDeleteOpen(false)}>
        <ConfirmationModal
          onConfirm={handleDeleteHere}
          onCancel={() => setIsDeleteOpen(false)}
          ques="Are you sure you want to delete?"
        />
      </Modal>
      <Modal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)}>
        <ConfirmationModal
          onConfirm={handTest}
          onCancel={() => setIsEditOpen(false)}
          ques="Are you sure you want to save the changes?"
        />
      </Modal>
      <div className="hdetails-header">
        <div className="hdetails-header-name">{name}</div>
        {/* <Button text={`Delete`} type={`arrow`} /> */}
        {can_delete && (
          <img
            src={deleteicon}
            alt="Delete"
            onClick={() => setIsDeleteOpen(true)}
          />
        )}
      </div>
      <div className="hdetails-contents">{children}</div>

      {can_update && (
        <div className="hdetails-footer">
          <Button
            type={`arrow`}
            text={`Save Changes`}
            onClick={() => {
              window.scrollTo(0, 0);
              setIsEditOpen(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default HalfDetails;
