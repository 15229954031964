import Button from "../Button/Button";
import "./ConfirmationModal.scss";

import React from "react";

const ConfirmationModal = ({ ques, onConfirm, onCancel }) => {
  return (
    <div className="cm">
      <div className="cm-ques">{ques}</div>
      <div className="cm-button">
        <Button text="Yes" onClick={onConfirm} type={`arred`} />
        <Button text="No" onClick={onCancel} type={`argreen`} />
      </div>
    </div>
  );
};

export default ConfirmationModal;
