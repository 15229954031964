import "./Contact.scss";

import React from "react";

import email from "../../assets/images/email.png";
import phone from "../../assets/images/phone.png";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
// import email from '../../assets/images/email.png';

const Contact = () => {
  const navigate = useNavigate();

  const enquiryClick = () => {
    navigate("/enquiry");
  };
  return (
    <div className="contact">
      <div className="contact-card">
        <div className="contact-card-left">
          <div className="contact-card-left-heading">Contact Us</div>
          <div className="contact-card-left-redirect">
            <div className="contact-card-left-redirect-text">
              Ready to take admission in a top notch academy of Maharashtra? Go
              to enquiry page to fill in the form and we will help you from
              there.
            </div>
            <div className="contact-card-left-redirect-button">
              <Button text={`Enquiry`} onClick={enquiryClick} />
            </div>
          </div>

          <div className="contact-card-left-content">
            <div className="contact-card-left-content-card">
              <div className="contact-card-left-content-card-title">
                <img src={email} alt="" />
                Email us at -
              </div>
              <div className="contact-card-left-content-card-option">
                info@chaitanyasacademy.in
              </div>
              <div className="contact-card-left-content-card-option">
                hr@chaitanyasacademy.in
              </div>
              <div className="contact-card-left-content-card-option">
                admin@chaitanyasacademy.in
              </div>
            </div>
            <div className="contact-card-left-content-card">
              <div className="contact-card-left-content-card-title">
                <img src={phone} alt="" />
                Call us at -
              </div>
              <div className="contact-card-left-content-card-option">
                +91 997 558 6727
              </div>
              <div className="contact-card-left-content-card-option">
                +91 942 387 4888
              </div>
              <div className="contact-card-left-content-card-option">
                +91 758 894 5064
              </div>
            </div>
          </div>
        </div>
        <div className="contact-card-right">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60641.11709279985!2d74.638994!3d18.2067151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc375a4b1697dcf%3A0x2bef5253ddb15514!2sChaitanya&#39;s%20International%20School!5e0!3m2!1sen!2sin!4v1696941949662!5m2!1sen!2sin"
            style={{ border: 0 }}
            title="GoogleMap"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
