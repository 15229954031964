import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import "./StudentPage.scss";
import StudentTimetable from "../StudentTimetable/StudentTimetable";
import StudentDashboard from "../StudentDashboard/StudentDashboard";
import Resources from "../Resources/Resources";
import StudentResults from "../StudentResults/StudentResults";
import LeaveApplication from "../LeaveApplication/LeaveApplication";

// Import other components as needed

const StudentPage = () => {
  return (
    <div className="admin">
      {/* <Topbar />
      <div className="admin-sidebar">
        <Sidebar />
      </div> */}
      <div className="admin-content">
        <Routes>
          {/* <Route path="dashboard" index element={<Dashboard />} /> */}
          <Route path="" index element={<StudentDashboard />} />
          <Route path="timetable" index element={<StudentTimetable />} />
          <Route path="resources" index element={<Resources />} />
          <Route path="leaves" index element={<LeaveApplication />} />
          <Route
            path="results"
            index
            element={<StudentResults is_admin={false} />}
          />

          {/* More routes for admin pages */}
        </Routes>
      </div>
    </div>
  );
};

export default StudentPage;
