import React, { useEffect, useState } from "react";
import "./EnquiryList.scss"; // Importing CSS file

import { utils, writeFile } from "xlsx";
import { DateRangePicker } from "rsuite";
import { Input, SelectPicker } from "rsuite";

import Button from "../../components/Button/Button";
import DataTable from "../../components/DataTable/DataTable";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";

const EnquiryList = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Admissions")
    ?.dropdowns.find((dd) => dd.name === "Enquiry List")?.permissions;
  //RBAC Code block ends.

  const [isLoading, setIsLoading] = useState(false);
  const [enquiries, setEnquiries] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("desc"); // 'desc' or 'asc'
  const [dateRange, setDateRange] = useState([]);

  const [selectedClass, setSelectedClass] = useState("All Classes");

  // Dropdown options
  const classOptions = [
    "All Classes",
    "Class 6th",
    "Class 7th",
    "Class 8th",
    "Class 9th",
    "Class 10th",
    "Class 11th",
    "Class 12th",
  ];

  const classData = classOptions.map((cls) => ({
    label: cls,
    value: cls,
    role: cls === "All Classes" ? "All" : "Class",
  }));

  // // Filter logic
  const filteredEnquiries = enquiries.filter((enquiry) => {
    const enquiryDate = new Date(enquiry.timestamp);
    const [start, end] = dateRange;
    return (
      (!start || enquiryDate >= start) &&
      (!end || enquiryDate <= end) &&
      (selectedClass === "All Classes" ||
        enquiry.admission_for_class === selectedClass) &&
      (enquiry.full_name.toLowerCase().includes(searchTerm) ||
        enquiry.whatsapp.toLowerCase().includes(searchTerm))
    );
  });

  // Handle class filter change
  const handleClassFilterChange = (value) => {
    // console.log(event);
    setSelectedClass(value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleDateRangeChange = (item) => {
    // Check if the date range is cleared and set it to an empty array
    if (!item) {
      setDateRange([]);
    } else {
      setDateRange(item);
    }
  };

  const fetchEnquiries = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await fetch("http://localhost:8000/getAllEnquiries", {
      const response = await fetch(
        "https://server.classerp.in/getAllEnquiries",
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 403) {
          alert("Unauthorized Access");
        }
        // Handle token expiry
        if (response.status === 401) {
          const newTokens = await refreshToken();
          if (newTokens) {
            return fetchEnquiries(); // Retry fetching enquiries
          }
        }
        throw new Error("Failed to fetch enquiries");
      }

      const data = await response.json();
      // Sorting logic...
      setEnquiries(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await fetch("http://localhost:8000/refreshToken", {
      const response = await fetch("https://server.classerp.in/refreshToken", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: tokens.refreshToken }),
      });

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const newTokens = await response.json();
      localStorage.setItem("ERPTokens", JSON.stringify(newTokens));
      return newTokens;
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("User loggod out. Please login again");
      // navigate("/login");
      return null;
    }
  };

  useEffect(() => {
    fetchEnquiries();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (sortOrder === "desc") {
      setEnquiries((prevEnquiries) =>
        [...prevEnquiries].sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        )
      );
    } else {
      setEnquiries((prevEnquiries) =>
        [...prevEnquiries].sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        )
      );
    }
    setIsLoading(false);
  }, [sortOrder]);

  const downloadExcel = () => {
    setIsLoading(true);
    const ws = utils.json_to_sheet(filteredEnquiries);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Enquiries");

    // Generate Excel file and download
    writeFile(wb, "Enquiries.xlsx");
    setIsLoading(false);
  };

  return (
    <div className="elist">
      {permissions?.can_read && (
        <>
          {isLoading && <Loader />}
          <div className="elist-header">
            <div className="elist-heading">Enquiry List</div>
            <Button type={"curved"} text={"Excel"} onClick={downloadExcel} />
          </div>

          <div className="elist-filters">
            <div className="elist-filters-search">
              <div className="elist-filters-label">
                Search by name or number.
              </div>
              <Input
                placeholder="Search by name or WhatsApp..."
                onChange={(value) =>
                  handleSearchChange({ target: { value: value.toLowerCase() } })
                }
                className="search-bar"
              />
            </div>
            <div className="elist-filters-datepicker">
              <div className="elist-filters-label">Select Date Range.</div>
              <DateRangePicker
                format="yyyy-MM-dd"
                onChange={handleDateRangeChange}
                value={dateRange}
              />
            </div>
            <div className="elist-filters-class">
              <div className="elist-filters-label">Select Class.</div>
              <SelectPicker
                data={classData}
                onChange={handleClassFilterChange}
                value={selectedClass}
                cleanable={false}
                searchable={false}
                placeholder="Select Class"
              />
            </div>
          </div>

          <div className="elist-table">
            <div className="elist-table-header">
              <div className="header-id">Id</div>
              <div className="header-name">Name</div>
              <div className="header-whatsapp">WhatsApp</div>
              <div className="header-class">Admission in Class</div>
              <div
                className="header-timestamp"
                onClick={() =>
                  setSortOrder(sortOrder === "desc" ? "asc" : "desc")
                }
              >
                TimeStamp {sortOrder === "desc" ? " ↑" : " ↓"}
              </div>
            </div>
            <DataTable
              data={filteredEnquiries}
              navigateBaseUrl={`/admin/enquiry`}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
            />
            <div className="elist-table-footer">
              {filteredEnquiries.length} items found.
            </div>
          </div>

          {/* <DataTable
        data={filteredEnquiries}
        navigateBaseUrl={`/admin/enquiry`}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
      /> */}
        </>
      )}
    </div>
  );
};

export default EnquiryList;
