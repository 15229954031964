// store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import permissionReducer from "./permissions/permissionsSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    permissions: permissionReducer,
    // Add other reducers if needed
  },
});

export default store;
