import React from "react";
import "./InstallmentsDisplay.scss";
import CustomTextInput from "../CustomTextInput/CustomTextInput";
import CustomNumberInput from "../CustomNumberInput/CustomNumberInput";
import Button from "../Button/Button";
import DropDown from "../DropDown/DropDown";
import CustomDropDown from "../CustomDropDown/CustomDropDown";

// Component to display installments
const InstallmentsDisplay = ({
  title,
  fee,
  installments,
  setInstallments,
  accounts,
}) => {
  console.log(accounts);
  accounts = accounts || [];

  // Handle changes in installment inputs
  const handleInstallmentChange = (index, field, value) => {
    const newInstallments = (installments || []).map((inst, idx) => {
      if (idx === index) {
        return { ...inst, [field]: value };
      }
      return inst;
    });
    setInstallments(newInstallments);
  };

  const handleAccountChange = (index, value) => {
    const acc = accounts.filter((item) => item.id === value);
    const newInstallments = (installments || []).map((inst, idx) => {
      if (idx === index) {
        return { ...inst, account: value };
      }
      return inst;
    });
    setInstallments(newInstallments);
  };

  // Function to add a new installment row
  const addInstallmentRow = () => {
    const newInstallment = {
      installment_number: (installments || []).length + 1,
      installment_name: "",
      amount: "",
      due_date: new Date().toISOString().split("T")[0], // Today's date as default
      components: [],
    };
    setInstallments([...(installments || []), newInstallment]);
  };

  // Function to handle removal of an installment
  const removeInstallmentRow = (indexToRemove) => {
    const filteredInstallments = (installments || []).filter(
      (_, index) => index !== indexToRemove
    );
    setInstallments(filteredInstallments);
  };

  const handleComponentChange = (parentIndex, index, field, value) => {
    const newInstallments = (installments || []).map((inst, idx) => {
      if (idx === parentIndex) {
        const newComponents = (inst.components || []).map((comp, compIdx) => {
          if (compIdx === index) {
            return { ...comp, [field]: value };
          }
          return comp;
        });
        return { ...inst, components: newComponents };
      }
      return inst;
    });
    setInstallments(newInstallments);
  };

  const addComponent = (index) => {
    const newInstallments = (installments || []).map((inst, idx) => {
      if (idx === index) {
        const newComp = { name: "", amount: "" };
        return {
          ...inst,
          components: [...(inst.components || []), newComp],
        };
      }
      return inst;
    });
    setInstallments(newInstallments);
  };

  const removeComponent = (parentIndex, index) => {
    const newInstallments = (installments || []).map((inst, idx) => {
      if (idx === parentIndex) {
        const filteredComponents = (inst.components || []).filter(
          (_, compIdx) => compIdx !== index
        );
        return { ...inst, components: filteredComponents };
      }
      return inst;
    });
    setInstallments(newInstallments);
  };

  // Validate installment components
  const validateComponents = (installment) => {
    if (installment.components && installment.components.length === 0) {
      return true;
    }
    const totalComponents = (installment.components || []).reduce(
      (acc, curr) => acc + Number(curr.amount || 0),
      0
    );
    return totalComponents === Number(installment.amount);
  };

  // Calculate total of installments
  const totalInstallments = (installments || []).reduce(
    (acc, curr) => acc + Number(curr.amount || 0),
    0
  );

  return (
    <div className="installments">
      <div className="installments-title">{title}</div>
      <div className="installments-total">
        <strong>Total Fee:</strong> Rs {fee}/-
      </div>
      {installments &&
        installments.map((inst, index) => (
          <div key={index} className="installments-row">
            <div className="installments-row-values">
              <div className="installments-row-values-name">
                <CustomTextInput
                  label="Installment Name"
                  placeholder="Installment Name"
                  value={inst.installment_name}
                  onChange={(e) =>
                    handleInstallmentChange(
                      index,
                      "installment_name",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="installments-row-values-amount">
                <CustomNumberInput
                  label="Amount"
                  placeholder="Amount"
                  type="number"
                  value={inst.amount}
                  onChange={(e) => handleInstallmentChange(index, "amount", e)}
                />
              </div>

              <div className="installments-row-values-due">
                <CustomTextInput
                  label="Due Date"
                  placeholder="Due Date"
                  type="date"
                  value={inst.due_date ? inst.due_date.split("T")[0] : null}
                  onChange={(e) =>
                    handleInstallmentChange(index, "due_date", e.target.value)
                  }
                />
              </div>
              <div className="installments-row-values-account">
                <DropDown
                  options={accounts.map((item) => item.name)}
                  label="Account"
                  value={inst.account || ""}
                  onChange={(e) => handleAccountChange(index, e)}
                />
              </div>
              <div className="installments-row-remove">
                <Button
                  type="minired"
                  text="X"
                  onClick={() => removeInstallmentRow(index)}
                />
              </div>
            </div>
            <div className="installments-row-comps">
              {inst.components &&
                inst.components.map((comp, compIdx) => (
                  <div key={compIdx} className="installments-row-comps-values">
                    <div className="installments-row-comps-values-name">
                      <CustomTextInput
                        label="Component Name"
                        placeholder="Component Name"
                        value={comp.name}
                        onChange={(e) =>
                          handleComponentChange(
                            index,
                            compIdx,
                            "name",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="installments-row-comps-values-amount">
                      <CustomNumberInput
                        label="Amount"
                        placeholder="Amount"
                        type="number"
                        value={comp.amount}
                        onChange={(e) =>
                          handleComponentChange(index, compIdx, "amount", e)
                        }
                      />
                    </div>
                    <div className="installments-row-comps-values-button">
                      <Button
                        text="Remove"
                        type={`minired`}
                        onClick={() => removeComponent(index, compIdx)}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <Button
              type="mini"
              text="Add Component"
              onClick={() => addComponent(index)}
            />
            {!validateComponents(inst) && (
              <div style={{ color: "red" }}>
                The sum of components does not match the installment amount.
              </div>
            )}
          </div>
        ))}
      {/* <Button text="Add Installment" onClick={addInstallmentRow} /> */}
      <div className="installments-button">
        <Button
          type={`mini`}
          onClick={addInstallmentRow}
          text={`Add Installment`}
        />
      </div>
      {totalInstallments !== Number(fee) && (
        <div style={{ color: "red" }}>
          Total of installments (Rs {totalInstallments}/-) does not match the
          total fee.
        </div>
      )}
    </div>
  );
};

export default InstallmentsDisplay;
