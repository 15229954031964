import "./Layout.scss";

import { Outlet, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Topbar from "../Topbar/Topbar";
import Footer from "../Footer/Footer";

import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as Cross } from "../../assets/images/Cross.svg";

import { fetchPermissions } from "../../state/permissions/permissionsSlice";

const { innerWidth: width } = window;

const MenuVariant = {
  initial: { x: width, scaleX: 0 },
  animate: { x: 0, scaleX: 1, transition: { duration: 0.4 } },
  exit: { x: width, transition: { duration: 0.4 } },
};

function Layout() {
  const [clicked, setClicked] = useState(false);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleMenu = () => {
    setClicked(!clicked);
  };

  useEffect(() => {
    const userData = localStorage.getItem("ERPTokens");

    if (userData) {
      setisLoggedIn(true);
      dispatch(fetchPermissions());
    }
  }, []);

  return (
    <div className="layout">
      {/* <div className="server-down">
        The server is down. Please come back later!
      </div> */}
      <AnimatePresence>
        {clicked ? <Menu handleMenu={handleMenu} /> : ""}
      </AnimatePresence>
      {auth.user_id || isLoggedIn ? (
        <Topbar />
      ) : (
        <>
          <Header onClick={handleMenu} />
          <DummyHeader />
        </>
      )}

      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;

function Menu({ handleMenu }) {
  const navigate = useNavigate();
  return (
    <motion.div
      className="menu"
      key="loader"
      variants={MenuVariant}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="menu-header">
        <div className="menu-header-content">
          <div className="menu-header-content-letter">A</div>
          <div className="menu-header-content-letter">M</div>
          <div className="menu-header-content-letter">S</div>
        </div>
      </div>
      <div className="menu-contents">
        <motion.div className="menu-contents-cross" onClick={handleMenu}>
          <Cross />
        </motion.div>

        <div className="menu-contents-links">
          <motion.div
            initial={{ opacity: 0, x: 150 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 0.4 }}
            viewport={{ once: true }}
            onClick={() => {
              navigate("/");
              handleMenu();
            }}
            className="menu-contents-links-link"
          >
            Home
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 150 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 0.4 }}
            viewport={{ once: true }}
            onClick={() => {
              navigate("/about");
              handleMenu();
            }}
            className="menu-contents-links-link"
          >
            About Us
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 150 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 0.4 }}
            viewport={{ once: true }}
            onClick={() => {
              navigate("/results");
              handleMenu();
            }}
            className="menu-contents-links-link"
          >
            Results
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 150 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 0.6 }}
            viewport={{ once: true }}
            onClick={() => {
              navigate("/enquiry");
              handleMenu();
            }}
            className="menu-contents-links-link"
            to="#Company"
          >
            Enquiry
          </motion.div>
          <Link to={`https://chaitanyas-academy.com/blog`}>
            <motion.div
              initial={{ opacity: 0, x: 150 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.8 }}
              viewport={{ once: true }}
              onClick={handleMenu}
              className="menu-contents-links-link"
              to="#Contact"
            >
              Blog
            </motion.div>
          </Link>
          <motion.div
            initial={{ opacity: 0, x: 150 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 1.0 }}
            viewport={{ once: true }}
            onClick={() => {
              navigate("/contact");
              handleMenu();
            }}
            className="menu-contents-links-link"
          >
            Contact Us
          </motion.div>
        </div>
        <div
          className="menu-contents-login"
          onClick={() => {
            navigate("/login");
            handleMenu();
          }}
        >
          Login
        </div>
      </div>
    </motion.div>
  );
}

//created to fill in the space for Header, as it is gived position: fixed.
function DummyHeader() {
  return <div className="dummyheader"></div>;
}
