import React, { useState, useEffect } from "react";
// import SubjectForm from "./SubjectForm";
// import SubjectList from "./SubjectList";
import "./Classes.scss"; // Assuming you have a corresponding SCSS file
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Classes = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Students")
    ?.dropdowns.find((dd) => dd.name === "Classes")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [class_name, setclass_name] = useState(null);
  const [description, setdescription] = useState(null);
  const [classes, setclasses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Fetch classes from API
  useEffect(() => {
    fetchclasses();
  }, []);

  useEffect(() => {
    if (!selectedItem) {
      return;
    }
    setclass_name(selectedItem.class_name);
    setdescription(selectedItem.description);
  }, [selectedItem]);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchclasses = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await axios.get("http://localhost:8000/api/classes", {
      const response = await axios.get(`${BE_URL}/api/classes`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setclasses(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchclasses(); // Retry fetching classes with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch classes.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (newSubject) => {
    setIsLoading(true);
    setError(null);
    setisAddModalOpen(true);
    console.log("Hello..");

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/api/classes`, newSubject, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      // Add the new subject to the classes list
      fetchclasses();

      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(newSubject); // Retry adding subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to Add. Please try again.");
        setisResponseOpen(true);
      }
    } finally {
      console.log("Setting it false");
      setisAddModalOpen(false);
      setIsLoading(false);
    }
  };

  const handleEdit = async () => {
    setIsLoading(true);
    setError(null);

    console.log("Handling the edit");

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.put(
        // `http://localhost:8000/api/classes/${selectedItem.id}`,
        `${BE_URL}/api/classes/${selectedItem.id}`,
        {
          class_name: class_name,
          description: description,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Update the classes list with the edited subject
      // const updatedclasses = classes.map((sub) =>
      //   sub.id === response.data.id ? response.data : sub
      // );
      // setclasses(updatedclasses);
      fetchclasses();
      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleEdit(); // Retry editing subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to edit. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (subjectId) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(
        // `http://localhost:8000/api/classes/${selectedItem.id}`,
        `${BE_URL}/api/classes/${selectedItem.id}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Remove the deleted subject from the classes list
      const updatedclasses = classes.filter((sub) => sub.id !== subjectId);
      setclasses(updatedclasses);
      setSelectedItem(null);
      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(subjectId); // Retry deleting subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="classes">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="classes-header">
            <div className="classes-header-name">Classes</div>
          </div>
          <div className="classes-contents">
            <div className="classes-contents-list">
              <HalfList
                data={classes}
                fields={["class_name", "description"]}
                setSelectedItem={setSelectedItem}
                can_create={permissions?.can_create}
                isAddModalOpen={isAddModalOpen}
                elem={
                  <AddClass handleAdd={(newClass) => handleAdd(newClass)} />
                }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="classes-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={selectedItem.class_name}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    can_delete={permissions?.can_delete}
                    can_update={permissions?.can_update}
                  >
                    <DetailsChild
                      selectedItem={selectedItem}
                      class_name={class_name}
                      description={description}
                      setclass_name={setclass_name}
                      setdescription={setdescription}
                    ></DetailsChild>
                  </HalfDetails>
                </div>
              ) : (
                <div className="classes-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Classes;

const AddClass = ({ handleAdd }) => {
  const [class_name, setclass_name] = useState(null);
  const [description, setdescription] = useState(null);

  const handleSubmit = () => {
    const newClass = {
      description,
      class_name,
    };
    handleAdd(newClass);
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add a Class</div>
      <CustomNumberInput
        placeholder="Class Number"
        value={class_name}
        onChange={(e) => setclass_name(e)}
      />
      <CustomTextInput
        placeholder="Description"
        value={description}
        onChange={(e) => setdescription(e.target.value)}
      />
      <Button text="Add Class" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({
  class_name,
  description,
  setclass_name,
  setdescription,
}) => {
  return (
    <div className="dc">
      <div className="dc-input">
        <CustomNumberInput
          value={class_name}
          label={`Class Name`}
          onChange={(e) => setclass_name(e)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={description}
          label={`Description`}
          onChange={(e) => setdescription(e.target.value)}
        />
      </div>
    </div>
  );
};
