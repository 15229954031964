import React, { useState, useEffect } from "react";
// import SubjectForm from "./SubjectForm";
// import SubjectList from "./SubjectList";
import "./FeeStructure.scss"; // Assuming you have a corresponding SCSS file
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import InstallmentsDisplay from "../../components/InstallmentsDisplay/InstallmentsDisplay";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const FeeStructure = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Management")
    ?.dropdowns.find((dd) => dd.name === "Fee Structures")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [name, setname] = useState(null);
  const [fee, setfee] = useState(null);
  const [class_id, setclass_id] = useState(null);

  // Fields for fee structure
  const [mess, setMess] = useState(false);
  const [transport, setTransport] = useState(false);
  const [bed_count, setbed_count] = useState(false);
  const [additional_subject_name, setadditional_subject_name] = useState(false);
  const [hostel, sethostel] = useState(false);
  const [additional_subject, setadditional_subject] = useState(false);
  const [classes, setclasses] = useState([]);
  const [className, setclassName] = useState(null);
  const [accounts, setaccounts] = useState([]);

  const [number_of_installments, setnumber_of_installments] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [feeStructures, setFeeStructures] = useState([]);

  // State for installment details
  const [installments, setInstallments] = useState([]);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Fetch classes from API
  useEffect(() => {
    fetchFeeStructures();
    fetchclasses();
    fetchcategories();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      // Update the form fields based on the selected item

      setclassName(
        classes.filter((item) => item.id === selectedItem.class_id)[0]
          .class_name
      );
      setname(selectedItem.name);
      setfee(selectedItem.fee);
      setclass_id(selectedItem.class_id);
      setMess(selectedItem.mess);
      setTransport(selectedItem.transport);
      setbed_count(selectedItem.bed_count);
      setadditional_subject_name(selectedItem.additional_subject_name);
      sethostel(selectedItem.hostel);
      setadditional_subject(selectedItem.additional_subject);
      setInstallments(selectedItem.installments || []);
    }
  }, [selectedItem]);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchclasses = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/classes`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setclasses(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchclasses(); // Retry fetching classes with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch classes.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const fetchFeeStructures = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/fees`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setFeeStructures(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return setFeeStructures(); // Retry fetching classes with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch classes.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchcategories = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(
        `${BE_URL}/api/general/categories/Accounts`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setaccounts(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch categories. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (newStructure) => {
    setIsLoading(true);
    setisAddModalOpen(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

      const response = await axios.post(`${BE_URL}/api/fees`, newStructure, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      alert("Added Successfully");
      // setresponseType("Success");
      // setmsg("Added Successfully");
      // setisResponseOpen(true);

      // fetchFeeStructures();
      // setSelectedItem(null);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(newStructure);
        } else {
          alert("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to Add Fee Structure");
        setisResponseOpen(true);
      }
    } finally {
      // setisAddModalOpen(false);
      setIsLoading(false);
    }
  };

  const handleEdit = async () => {
    setIsLoading(true);
    setError(null);

    const id = selectedItem.id;
    console.log("Selected itme ", selectedItem);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.put(
        `${BE_URL}/api/fees/${id}`,
        {
          name,
          fee,
          class_id,
          bed_count: hostel ? bed_count : null,
          additional_subject_name: additional_subject
            ? additional_subject_name
            : null,
          hostel,
          additional_subject,
          installments,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);

      fetchFeeStructures();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleEdit(); // Retry editing subject with new token
        } else {
          alert("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to edit. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(
        // `http://localhost:8000/api/classes/${selectedItem.id}`,
        `${BE_URL}/api/fees/${selectedItem.id}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // // Remove the deleted subject from the classes list
      // const updatedData = classes.filter((sub) => sub.id !== id);
      // setFeeStructures(updatedData);
      setSelectedItem(null);

      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);

      fetchFeeStructures();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(id);
        } else {
          alert("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete Fee Structure");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="classes">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="classes-header">
            <div className="classes-header-name">Fees Structures</div>
          </div>
          <div className="classes-contents">
            <div className="classes-contents-list">
              <HalfList
                data={feeStructures}
                fields={["name", "fee"]}
                setSelectedItem={setSelectedItem}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                elem={
                  <AddFeeStructure
                    handleAdd={(newClass) => handleAdd(newClass)}
                    classes={classes}
                    accounts={accounts}
                  />
                }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="classes-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={selectedItem.name}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    can_delete={permissions?.can_delete}
                    can_update={permissions?.can_update}
                  >
                    <DetailsChild
                      id={selectedItem.id}
                      name={name}
                      fee={fee}
                      class_id={class_id}
                      className={className}
                      bed_count={bed_count}
                      additional_subject_name={additional_subject_name}
                      hostel={hostel}
                      additional_subject={additional_subject}
                      installments={installments}
                      setclass_id={setclass_id}
                      setname={setname}
                      setfee={setfee}
                      setclassName={setclassName}
                      setbed_count={setbed_count}
                      setadditional_subject_name={setadditional_subject_name}
                      sethostel={sethostel}
                      setadditional_subject={setadditional_subject}
                      setInstallments={setInstallments}
                      classes={classes}
                      accounts={accounts}
                    />
                  </HalfDetails>
                </div>
              ) : (
                <div className="classes-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default FeeStructure;

const AddFeeStructure = ({ handleAdd, classes, accounts }) => {
  const [name, setname] = useState(null);
  const [fee, setfee] = useState(null);
  const [class_id, setclass_id] = useState(null);

  // Fields for fee structure
  const [mess, setMess] = useState(false);
  const [transport, setTransport] = useState(false);
  const [bed_count, setbed_count] = useState(false);

  const [additional_subject_name, setadditional_subject_name] = useState(false);
  const [hostel, sethostel] = useState(false);
  const [additional_subject, setadditional_subject] = useState(false);
  const [className, setclassName] = useState(null);

  // State for installment details
  const [installments, setInstallments] = useState([]);

  // Update installment fields based on number_of_installments
  useEffect(() => {
    setInstallments(
      Array.from({ length: 1 }, (_, index) => ({
        installment_number: index + 1,
        installment_name: "",
        amount: "",
        payment_due_date: "",
      }))
    );
  }, [fee]);

  const handleSubmit = () => {
    const newStructure = {
      fee,
      name,
      class_id,
      bed_count: hostel ? bed_count : null,
      additional_subject_name: additional_subject
        ? additional_subject_name
        : null,
      hostel,
      additional_subject,
      installments,
    };
    handleAdd(newStructure);
  };
  // Options for the dropdowns
  const booleanOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add a Fee Structure</div>
      <ObjectDropDown
        label="Class"
        options={classes.map(({ class_name, id }) => ({
          label: id,
          value: class_name,
        }))}
        value={className}
        setValue={setclassName}
        setId={setclass_id}
      />
      <CustomTextInput
        label="Name"
        placeholder="Name"
        value={name}
        onChange={(e) => setname(e.target.value)}
      />
      <CustomNumberInput
        value={fee}
        label={`Total Fees`}
        placeholder={`Total Fees`}
        onChange={(e) => setfee(e)}
      />

      <ObjectDropDown
        label="Hostel"
        options={[
          { value: "Yes", label: true },
          { value: "No", label: false },
        ].map(({ label, value }) => ({
          label: label,
          value: value,
        }))}
        value={hostel}
        setValue={(val) => (val === "Yes" ? sethostel(true) : sethostel(false))}
        setId={() => {}}
      />

      {hostel && (
        <ObjectDropDown
          label="Bed Count"
          options={[
            { value: 4, label: 4 },
            { value: 6, label: 6 },
          ].map(({ label, value }) => ({
            label: label,
            value: value,
          }))}
          value={bed_count}
          setValue={setbed_count}
          setId={() => {}}
        />
      )}

      <ObjectDropDown
        label="Additional Subject?"
        options={[
          { value: "Yes", label: true },
          { value: "No", label: false },
        ].map(({ label, value }) => ({
          label: label,
          value: value,
        }))}
        value={additional_subject}
        setValue={(val) =>
          val === "Yes"
            ? setadditional_subject(true)
            : setadditional_subject(false)
        }
        setId={() => {}}
      />

      {additional_subject && (
        <ObjectDropDown
          label="Additional Subject Name"
          options={[
            { value: "I.T.", label: "I.T." },
            { value: "Crop Science", label: "Crop Science" },
          ].map(({ label, value }) => ({
            label: label,
            value: value,
          }))}
          value={additional_subject_name}
          setValue={setadditional_subject_name}
          setId={() => {}}
        />
      )}

      {/* Installments Display/Edit Component */}
      <InstallmentsDisplay
        title="Installments"
        fee={fee}
        installments={installments}
        setInstallments={setInstallments}
        accounts={accounts}
      />

      <Button text="Add Fee Structure" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({
  id,
  name,
  fee,
  class_id,
  className,
  bed_count,
  additional_subject_name,
  hostel,
  additional_subject,
  installments,
  setname,
  setfee,
  setclass_id,
  setclassName,
  setbed_count,
  setadditional_subject_name,
  sethostel,
  setadditional_subject,
  setInstallments,
  classes,
  accounts,
}) => {
  // Options for the dropdowns
  const booleanOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  return (
    <div className="dc">
      <h2>Fee Structure Details</h2>
      {/* Class ID and other non-boolean details remain the same */}

      <div className="dc-input">
        <CustomTextInput
          label="Name"
          value={name}
          onChange={(e) => setname(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomNumberInput
          value={fee}
          label={`Total Fees`}
          placeholder={`Total Fees`}
          onChange={(e) => setfee(e)}
        />
      </div>
      <div className="dc-input">
        <ObjectDropDown
          label="Class"
          options={classes.map(({ class_name, id }) => ({
            label: id,
            value: class_name,
          }))}
          value={className}
          setValue={setclassName}
          setId={setclass_id}
        />
      </div>
      <div className="dc-input">
        <ObjectDropDown
          label="Hostel"
          options={[
            { value: "Yes", label: true },
            { value: "No", label: false },
          ].map(({ label, value }) => ({
            label: label,
            value: value,
          }))}
          value={hostel}
          setValue={(val) =>
            val === "Yes" ? sethostel(true) : sethostel(false)
          }
          setId={() => {}}
        />
      </div>
      {hostel && (
        <div className="dc-input">
          <ObjectDropDown
            label="Bed Count"
            options={[
              { value: 4, label: 4 },
              { value: 6, label: 6 },
            ].map(({ label, value }) => ({
              label: label,
              value: value,
            }))}
            value={bed_count}
            setValue={setbed_count}
            setId={() => {}}
          />
        </div>
      )}

      <div className="dc-input">
        <ObjectDropDown
          label="Additional Subject"
          options={[
            { value: "Yes", label: true },
            { value: "No", label: false },
          ].map(({ label, value }) => ({
            label: label,
            value: value,
          }))}
          value={additional_subject}
          setValue={(val) =>
            val === "Yes"
              ? setadditional_subject(true)
              : setadditional_subject(false)
          }
          setId={() => {}}
        />
      </div>

      {additional_subject && (
        <div className="dc-input">
          <ObjectDropDown
            label="Additional Subject Name"
            options={[
              { value: "I.T.", label: "I.T." },
              { value: "Crop Science", label: "Crop Science" },
            ].map(({ label, value }) => ({
              label: label,
              value: value,
            }))}
            value={additional_subject_name}
            setValue={setadditional_subject_name}
            setId={() => {}}
          />
        </div>
      )}

      {/* Installments Display/Edit Component */}
      <InstallmentsDisplay
        title="Installments"
        fee={fee}
        installments={installments}
        setInstallments={setInstallments}
        accounts={accounts}
      />
    </div>
  );
};
