// CustomDropdown.js
import React, { useState } from "react";
import "./DropDown.scss";

import { ReactComponent as Arrow } from "../../assets/images/arrowdd.svg";

function DropDown({ invalid, options, value, onChange, label }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    onChange(value);
    setIsOpen(false);
    setIsSelected(true);
  };

  return (
    <>
      <div className="dropdown-label">{label}</div>
      <div
        className={invalid ? "dropdown-invalid dropdown" : "dropdown"}
        onClick={handleToggle}
      >
        <div className={isSelected ? "selected-option" : "select-option"}>
          {value || "Select"}
          <span className={`arrow ${isOpen ? "open" : ""}`}>
            <Arrow />
          </span>
        </div>
        {isOpen && (
          <div className="options">
            {options &&
              options.map((option, index) => (
                <div
                  key={index}
                  className="option"
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
}

export default DropDown;
