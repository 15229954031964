import WIP from "../../components/WIP/WIP";
import "./Login.scss";

import loginbg from "../../assets/images/loginbg.png";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { clearUser, setUser } from "../../state/auth/authSlice";

import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import TextField from "../../components/TextField/TextField";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = `http://localhost:8000`;
const BE_URL = `https://server.classerp.in`;

const Login = () => {
  // const [isLoading, setisLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, setisModalOpen] = useState(false);

  const [forgotEmail, setForgotEmail] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("ERPTokens");

    if (userData) {
      console.log("User data is ", userData);
      localStorage.removeItem("ERPTokens");

      dispatch(clearUser());

      // const obj = jwtDecode(JSON.parse(userData).accessToken);
      // console.log(obj);
      // const { name, user_id } = obj;

      // const user = {
      //   name: name,
      //   user_id: user_id,
      // };

      // dispatch(setUser(user));
      // navigate("/");
    }
  }, []);

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = () => {
    setisLoading(true);
    const data = {
      email: email, // use email as the username for simplicity
      password: password,
    };

    // const BE_URL = `http://localhost:8000/login`;
    const BE_URL = `https://server.classerp.in/login`;

    axios
      .post(BE_URL, data)
      .then((response) => {
        // Handle successful login/signup
        console.log(response.data.tokens);
        localStorage.setItem("ERPTokens", JSON.stringify(response.data.tokens));

        const obj = jwtDecode(response.data.tokens.accessToken);
        console.log(obj);
        const { name, user_id, email, role_id } = obj;

        const user = {
          user_id: user_id,
          name: name,
          email: email,
          role_id: role_id,
        };

        dispatch(setUser(user));
        setisLoading(false);
        if (role_id === 10) {
          navigate("/student");
        } else {
          navigate("/admin");
        }
      })
      .catch((error) => {
        console.error(error);
        setisLoading(false);
        alert("Error loggin in, please try again.");
      });
  };

  const handleForgotPassword = () => {
    setisLoading(true);
    axios
      .post(`${BE_URL}/forgot-password`, {
        email: forgotEmail,
      })
      .then((response) => {
        alert(
          "If the email is associated with an account, a new password has been sent."
        );
        setisModalOpen(false);
        setisLoading(false);

        setresponseType("Success");
        setmsg("A new password has been send to your email.");
        setisResponseOpen(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          // alert("User not found");
          setresponseType("Error");
          setmsg("No user found with this email.");
          setisResponseOpen(true);
        } else {
          console.error("Error resetting password:", error);
          alert("Error resetting password, please try again.");
        }
        setisLoading(false);
      });
  };

  return (
    <div className="login" style={{ backgroundImage: `url(${loginbg})` }}>
      {isLoading && <Loader />}
      <Modal isOpen={isModalOpen} onClose={() => setisModalOpen(false)}>
        <ResetPassword
          setisModalOpen={setisModalOpen}
          handleForgotPassword={handleForgotPassword}
          forgotEmail={forgotEmail}
          setForgotEmail={setForgotEmail}
        />
      </Modal>
      <Modal isOpen={isResponseOpen} onClose={() => setisResponseOpen(false)}>
        <ResponseModal
          type={responseType}
          msg={msg}
          onClick={() => setisResponseOpen(false)}
        />
      </Modal>
      <div className="login-form">
        <div className="login-form-header">LOGIN</div>
        <input
          type="email"
          placeholder="EMAIL"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="PASSWORD"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => handleEnter(e)}
        />
        <Button text="Login" type={`primary`} onClick={handleLogin} />
        <div className="login-form-forgot" onClick={() => setisModalOpen(true)}>
          Reset Password?
        </div>
        <div className="login-form-nouser">Not a user? Contact Admin.</div>
      </div>
    </div>
  );
};

export default Login;

const ResetPassword = ({
  setisModalOpen,
  handleForgotPassword,
  forgotEmail,
  setForgotEmail,
}) => {
  // const [forgotEmail, setForgotEmail] = useState(null);
  // const [isLoading, setisLoading] = useState(false);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);

  return (
    <div className="resetPswd">
      <h3>Reset Password</h3>
      <CustomTextInput
        label="Email"
        placeholder="Email"
        value={forgotEmail}
        // onChange={setForgotEmail}
        onChange={(e) => setForgotEmail(e.target.value)}
      />
      <Button text="Submit" onClick={handleForgotPassword} />
    </div>
  );
};
