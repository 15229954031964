import React from "react";
import { TextInput } from "@mantine/core";

const CustomTextInput = ({ value, onChange, ...props }) => {
  const customStyles = {
    input: {
      padding: "2.2rem 1rem",
      fontSize: "1.6rem",
      border: "1px solid #4585e5",
      borderRadius: "1rem",
      boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      marginBottom: "15px",
    },
    label: {
      fontSize: "1.5rem",
      color: "#6c6c6c", //grey2 of index.scss
      textAlign: "left !important",
    },
    root: {
      textAlign: "left",
    },
    // Additional styles can be added here
  };

  return (
    <TextInput
      value={value}
      onChange={onChange}
      styles={customStyles}
      {...props}
    />
  );
};

export default CustomTextInput;
