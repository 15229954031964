import React, { useState } from "react";
import axios from "axios";
import "./ChangePassword.scss"; // Ensure this path is correct
import { clearUser } from "../../state/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

function ChangePassword({ onDone }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setrepeatPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/refreshToken`, {
        refreshToken: tokens.refreshToken,
      });
      if (response.data.accessToken) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        changePassword(response.data.accessToken);
      } else {
        throw new Error("Failed to refresh token.");
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      localStorage.removeItem("ERPTokens"); // Clear tokens from storage
      dispatch(clearUser()); // Clear user data from Redux store
      navigate("/login"); // Redirect to login page
      return false;
    }
  };

  const changePassword = async (accessToken) => {
    try {
      const response = await axios.post(
        `${BE_URL}/change-password/`,
        {
          currentPassword: oldPassword,
          newPassword: newPassword,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== repeatPassword) {
      alert("Passwords do not match!");
      return;
    }
    const authTokens = JSON.parse(localStorage.getItem("ERPTokens"));

    if (!authTokens) {
      setMessage("Not authenticated. Please log in.");
      setIsError(true);
      return;
    }

    try {
      const data = await changePassword(authTokens.accessToken);
      setMessage(data.success);
      alert("Password changed successfully.");
      onDone();
      setIsError(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await refreshToken();
      } else {
        setMessage(error.response?.data?.error || "An error occurred.");
        setIsError(true);
      }
    }
  };

  return (
    <div className="change-password">
      <div className="change-password-title">Change Passwod</div>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          placeholder="Old Password"
          required
        />
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          required
        />
        <input
          type="password"
          value={repeatPassword}
          onChange={(e) => setrepeatPassword(e.target.value)}
          placeholder="Repeat New Password"
          required
        />
        <button type="submit">Change Password</button>
      </form>
      {message && (
        <p className={isError ? "error-message" : "success-message"}>
          {message}
        </p>
      )}
    </div>
  );
}

export default ChangePassword;
