import React, { useState, useEffect } from "react";
import axios from "axios";
import "./IdCardPhotoList.scss";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import Loader from "../../components/Loader/Loader";
import { utils, writeFile } from "xlsx";
import Button from "../../components/Button/Button";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const IdCardPhotoList = () => {
  const [photos, setPhotos] = useState([]);
  const [filteredPhotos, setFilteredPhotos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchIdCardPhotos();
  }, []);

  const fetchIdCardPhotos = async () => {
    try {
      const response = await axios.get(`${BE_URL}/api/general/idCardPhotos`);
      const photosWithChecked = response.data.map((photo) => ({
        ...photo,
        // checked: false,
      }));
      setPhotos(photosWithChecked);
      setFilteredPhotos(photosWithChecked);
    } catch (error) {
      console.error("Error fetching ID card photos:", error);
    }
  };

  useEffect(() => {
    console.log("Triggered:", selectedClass);
    const filtered = photos.filter((photo) => {
      const name =
        `${photo.sir_name} ${photo.father_name} ${photo.student_name}`.toLowerCase();
      const rollNo = photo.roll_no.toLowerCase();
      const searchLower = searchTerm.toLowerCase();

      return (
        (name.includes(searchLower) || rollNo.includes(searchLower)) &&
        (selectedClass ? photo.class == selectedClass : true)
      );
    });

    setFilteredPhotos(filtered);
  }, [searchTerm, selectedClass, photos]);

  const classOptions = Array.from({ length: 12 - 6 + 1 }, (_, i) => ({
    id: i + 6,
    name: `Class ${i + 6}`,
  }));

  const downloadExcel = () => {
    setIsLoading(true);
    const ws = utils.json_to_sheet(filteredPhotos);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "StudentPhotoes");

    // Generate Excel file and download
    writeFile(wb, "StudentPhotoes.xlsx");
    setIsLoading(false);
  };

  return (
    <div className="idCardPhotoList">
      {isLoading && <Loader />}
      <div className="idCardPhotoList-header">
        <div className="idCardPhotoList-header-title">
          Students ID Photos (Total - {filteredPhotos && filteredPhotos.length})
        </div>
        <div className="filter">
          <Button type={"curved"} text={"Excel"} onClick={downloadExcel} />
          <CustomTextInput
            placeholder="Search by name / Roll No"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <CustomDropDown
            data={classOptions}
            value={selectedClass}
            onChange={setSelectedClass}
            searchable={false}
            placeholder="Select Class"
          />
        </div>
      </div>

      <div className="list">
        {filteredPhotos.map((photo, index) => (
          <div key={index} className="list-item">
            <div className="list-item-name">
              {photo.sir_name} {photo.father_name} {photo.student_name} -{" "}
              {photo.roll_no} - ({photo.class})
            </div>
            <div className="list-item-buttons">
              <a
                href={photo.photo_url}
                target="_blank"
                rel="noopener noreferrer"
                className="IDphotopreview"
              >
                Preview
              </a>
              <a
                href={`${BE_URL}/api/general/downloadImage?url=${encodeURIComponent(
                  photo.photo_url
                )}`}
                download
              >
                Download
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IdCardPhotoList;
