import React, { useState, useEffect } from "react";

import "./Hostels.scss";
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "../../components/Modal/Modal";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import DropDown from "../../components/DropDown/DropDown";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Hostels = () => {
  //RBAC Code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Management")
    ?.dropdowns.find((dd) => dd.name === "Hostels")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [hostel_name, sethostel_name] = useState(null);
  const [hostel_desc, sethostel_desc] = useState(null);
  const [warden_id, setwarden_id] = useState(null);
  const [users, setusers] = useState(null);
  const [gender, setgender] = useState(null);
  const [hostels, setHostels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [occupancy, setOccupancy] = useState(null);
  const [vacency, setVacency] = useState(null);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Fetch subjects from API
  useEffect(() => {
    fetchHostels();
    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      setgender(selectedItem.gender ? selectedItem.gender : "");
      sethostel_name(selectedItem.hostel_name ? selectedItem.hostel_name : "");
      sethostel_desc(selectedItem.hostel_desc ? selectedItem.hostel_desc : "");
      setwarden_id(selectedItem.warden_id ? selectedItem.warden_id : null);
      console.log("Selected item changed ", selectedItem);
    }
  }, [selectedItem]);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/refreshToken`, {
        token: tokens.refreshToken,
      });

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await axios.get("http://localhost:8000/getAllUsers", {
      const response = await axios.get(`${BE_URL}/api/general/getAllUsers`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });
      setusers(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Token expired, refresh token
        const newTokens = await refreshToken();
        if (newTokens) {
          return fetchUsers(); // Retry fetching users with new token
        } else {
          // Redirect to login or show an error
          alert("Session expired. Please login again.");
          // navigate("/login");
        }
      } else {
        console.error("Error fetching users:", error);
        alert("Failed to fetch user data. Please try again.");
      }
    }
    setIsLoading(false);
  };

  const fetchHostels = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await axios.get("http://localhost:8000/api/hostels", {
      const response = await axios.get(`${BE_URL}/api/hostels`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setHostels(response.data.hostels);
      setOccupancy(response.data.occupancy);
      setVacency(response.data.vacency);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchHostels(); // Retry fetching subjects with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch subjects.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (newHostel) => {
    setIsLoading(true);
    setError(null);
    setisAddModalOpen(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        // `http://localhost:8000/api/hostels`,
        `${BE_URL}/api/hostels`,
        newHostel,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Add the new subject to the hostels list
      fetchHostels();

      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(newHostel); // Retry adding subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to add. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
      setisAddModalOpen(false);
    }
  };

  const handleEdit = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.put(
        // `http://localhost:8000/api/hostels/${selectedItem.id}`,
        `${BE_URL}/api/hostels/${selectedItem.id}`,
        {
          gender: gender,
          hostel_name: hostel_name,
          hostel_desc: hostel_desc,
          warden_id: warden_id,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Update the Hostels list with the edited subject
      fetchHostels();
      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleEdit();
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to edit. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (hostelId) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(
        // `http://localhost:8000/api/hostels/${selectedItem.id}`,
        `${BE_URL}/api/hostels/${selectedItem.id}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Remove the deleted subject from the hostels list
      const updatedHostels = hostels.filter((sub) => sub.id !== hostelId);
      setHostels(updatedHostels);
      setSelectedItem(null);
      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(hostelId); // Retry deleting subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="hostels">
      {isLoading && <Loader />}

      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          ></Modal>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="hostels-header">
            <div className="hostels-header-name">Hostels</div>
            <div className="hostels-header-info">
              {/* <div className="hostels-info-cards"> */}
              <div className="hostels-header-info-card">
                <div className="hostels-header-info-card-title">
                  Total Hostels{" "}
                </div>
                <div className="hostels-header-info-card-number">
                  {parseInt(hostels.length)}
                </div>
              </div>
              <div className="hostels-header-info-card">
                <div className="hostels-header-info-card-title">
                  Total Capacity{" "}
                </div>
                <div className="hostels-header-info-card-number">
                  {parseInt(occupancy) + parseInt(vacency)}
                </div>
              </div>
              <div className="hostels-header-info-card">
                <div className="hostels-header-info-card-title">
                  Vacent Beds
                </div>
                <div className="hostels-header-info-card-number">
                  {parseInt(vacency)}
                </div>
              </div>
              <div className="hostels-header-info-card">
                <div className="hostels-header-info-card-title">
                  Occupied Beds{" "}
                </div>
                <div className="hostels-header-info-card-number">
                  {parseInt(occupancy)}
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          <div className="hostels-contents">
            <div className="hostels-contents-list">
              <HalfList
                data={hostels}
                fields={["id", "hostel_name", "hostel_desc"]}
                setSelectedItem={setSelectedItem}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                elem={
                  <AddHostel
                    handleAdd={(newHostel) => handleAdd(newHostel)}
                    users={users}
                  />
                }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="hostels-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={selectedItem.hostel_name}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    can_delete={permissions?.can_delete}
                    can_update={permissions?.can_update}
                  >
                    <DetailsChild
                      id={selectedItem.id}
                      hostel_name={hostel_name}
                      hostel_desc={hostel_desc}
                      warden_id={warden_id}
                      gender={gender}
                      sethostel_name={sethostel_name}
                      sethostel_desc={sethostel_desc}
                      setgender={setgender}
                      setwarden_id={setwarden_id}
                      users={users}
                    ></DetailsChild>
                  </HalfDetails>
                </div>
              ) : (
                <div className="hostels-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Hostels;

const AddHostel = ({ handleAdd, users }) => {
  // const [gender, setgender] = useState(null);
  const [hostel_name, sethostel_name] = useState(null);
  const [hostel_desc, sethostel_desc] = useState(null);
  const [gender, setgender] = useState(null);
  const [warden_id, setwarden_id] = useState(null);
  const genderOptions = ["Male", "Female"];

  const handleSubmit = () => {
    if (!hostel_name) {
      alert("Please fill in the Hostel Name");
      return;
    }
    const newHostel = { hostel_name, hostel_desc, gender, warden_id };
    handleAdd(newHostel);
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add a Hostel</div>

      <CustomTextInput
        label={`Hostel Name`}
        placeholder="Hostel Name"
        value={hostel_name}
        onChange={(e) => sethostel_name(e.target.value)}
      />
      {/* <CustomTextInput
        placeholder="Total Floors"
        value={gender}
        onChange={(e) => setgender(e.target.value)}
      /> */}
      <DropDown
        options={genderOptions}
        label={`Gender`}
        value={gender}
        onChange={setgender}
      />
      <CustomTextInput
        label={`Hostel Description`}
        placeholder="Description"
        value={hostel_desc}
        onChange={(e) => sethostel_desc(e.target.value)}
      />

      <CustomDropDown
        data={users}
        searchable={true}
        label="Warden"
        placeholder="Warden"
        onChange={setwarden_id}
        value={warden_id}
      />

      <Button text="Add Hostel" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({
  id,
  hostel_name,
  hostel_desc,
  warden_id,
  gender,
  sethostel_name,
  sethostel_desc,
  setwarden_id,
  setgender,
  users,
}) => {
  const navigate = useNavigate();
  const genderOptions = ["Male", "Female"];

  return (
    <div className="dc">
      <div className="dc-visit">
        <Button
          text={`View Details`}
          onClick={() => navigate(`/admin/hostels/${id}`)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={hostel_name}
          label={`Hostel Name`}
          onChange={(e) => sethostel_name(e.target.value)}
        />
      </div>

      <div className="dc-input">
        <CustomTextInput
          value={hostel_desc}
          label={`Hostel Description`}
          onChange={(e) => sethostel_desc(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <DropDown
          options={genderOptions}
          label={`Gender`}
          value={gender}
          onChange={setgender}
        />
      </div>
      <div className="dc-input">
        <CustomDropDown
          data={users}
          searchable={true}
          label="Select Warden"
          placeholder="Select Warden"
          onChange={setwarden_id}
          value={warden_id}
        />
      </div>
    </div>
  );
};
