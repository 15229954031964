import React from "react";
import "./TextField.scss";

function TextField({ placeholder, value, onChange, disabled }) {
  return (
    <>
      <div className="dropdown-label">{placeholder}</div>
      <input
        type="text"
        className="textField"
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </>
  );
}

export default TextField;
