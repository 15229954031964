import React, { useState, useEffect } from "react";
import "./AdminLeaves.scss"; // Assuming you have a corresponding SCSS file
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import DropDown from "../../components/DropDown/DropDown";
import { DateRangePicker } from "rsuite";
import noselection from "../../assets/images/noselection.png";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const AdminLeaves = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Management")
    ?.dropdowns.find((dd) => dd.name === "Leave Requests")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [leaves, setleaves] = useState([]);
  const [allleaves, setallleaves] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newStatus, setnewStatus] = useState(null);
  const [remarks, setremarks] = useState(null);
  const [statusFilter, setstatusFilter] = useState(null);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Initialize the date range state
  const [dateRange, setDateRange] = useState(() => {
    const today = new Date();
    const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));
    return [lastMonth, today];
  });

  // Fetch leaves from API
  // useEffect(() => {
  //   fetchleaves();
  // }, []);

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  useEffect(() => {
    setSelectedItem(null);

    if (dateRange[0] && dateRange[1]) {
      fetchleaves(dateRange[0], dateRange[1]);
    }
  }, [dateRange]);

  useEffect(() => {
    setSelectedItem(null);
    applyFilters();
  }, [statusFilter]);

  const applyFilters = () => {
    let filteredDocs = [...allleaves];

    // Apply status filter
    if (statusFilter && statusFilter !== "All") {
      filteredDocs = filteredDocs.filter(
        (product) => product.status === statusFilter
      );
    }

    setleaves(filteredDocs); // Update the displayed products based on filters
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchleaves = async (startDate, endDate) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const formattedStartDate = startDate.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
      const formattedEndDate = endDate.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'

      const response = await axios.get(`${BE_URL}/api/getLeavesForApprover`, {
        params: { startDate: formattedStartDate, endDate: formattedEndDate },
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setallleaves(response.data);
      setleaves(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchleaves(); // Retry fetching leaves with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch leaves.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (val) => {
    if (!remarks) {
      alert("Please add the remark.");
      return;
    }
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        `${BE_URL}/api/reviewLeaveApplication/${selectedItem.id}`,
        {
          status: val,
          remarks,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      fetchleaves(dateRange[0], dateRange[1]);
      setresponseType("Success");
      setmsg(`${val} Successfully`);
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleEdit(); // Retry adding the document with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to edit. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="halfpage">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="halfpage-header">
            <div className="halfpage-header-name">Leaves</div>
            <div className="halfpage-header-filters">
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Date Range</div>
                <DateRangePicker
                  format="yyyy-MM-dd"
                  onChange={handleDateRangeChange}
                  cleanable={false}
                  value={dateRange}
                />
              </div>
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Status</div>
                <DropDown
                  options={["All", "Pending", "Approved", "Denied"]}
                  value={statusFilter}
                  onChange={(value) => setstatusFilter(value)}
                />
              </div>
            </div>
          </div>
          <div className="halfpage-contents">
            <div className="halfpage-contents-list">
              <HalfList
                data={leaves}
                fields={["user_id", "name", "status"]}
                setSelectedItem={setSelectedItem}
                can_create={false}
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="halfpage-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={selectedItem.name}
                    can_delete={false}
                    can_update={false}
                  >
                    <DetailsChild
                      selectedItem={selectedItem}
                      remarks={remarks}
                      setremarks={setremarks}
                      handleReview={handleEdit}
                    ></DetailsChild>
                  </HalfDetails>
                </div>
              ) : (
                <div className="halfpage-contents-details-noselection">
                  {/* Select an item to see details */}
                  <img src={noselection} alt="" />
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default AdminLeaves;

const DetailsChild = ({ selectedItem, remarks, setremarks, handleReview }) => {
  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate}`;
  };

  return (
    <div className="dc">
      <div className="dc-text">
        <div className="dc-text-label">User ID</div>
        <div className="dc-text-data">{selectedItem.requester_code}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Name</div>
        <div className="dc-text-data">{selectedItem.name}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Status</div>
        <div className="dc-text-data">{selectedItem.status}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Reason</div>
        <div className="dc-text-data">{selectedItem.reason}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Start Date</div>
        <div className="dc-text-data">
          {formatDateAndTime(selectedItem.start_date)}
        </div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">End Date</div>
        <div className="dc-text-data">
          {formatDateAndTime(selectedItem.end_date)}
        </div>
      </div>
      {selectedItem.status !== "Pending" && (
        <>
          <div className="dc-text">
            <div className="dc-text-label">Remarks</div>
            <div className="dc-text-data">{selectedItem.remarks}</div>
          </div>
          <div className="dc-text">
            <div className="dc-text-label">Reviewed At</div>
            <div className="dc-text-data">
              {formatDateAndTime(selectedItem.reviewed_at)}
            </div>
          </div>
        </>
      )}

      {selectedItem.status === "Pending" && (
        <div className="dc-textfull">
          <CustomTextInput
            value={remarks}
            label={`Remark`}
            onChange={(e) => setremarks(e.target.value)}
          />
        </div>
      )}
      {selectedItem.status === "Pending" && (
        <div className="dc-buttons">
          <Button
            text={`Approve`}
            type={`argreen`}
            onClick={() => handleReview("Approved")}
          />
          <Button
            text={`Deny`}
            type={`arred`}
            onClick={() => handleReview("Denied")}
          />
        </div>
      )}
    </div>
  );
};
