import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./FilesPage.scss";
import Loader from "../../components/Loader/Loader";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const FilesPage = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Students")
    ?.dropdowns.find((dd) => dd.name === "Study App")?.permissions;
  //RBAC Code block ends.

  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const location = useLocation();
  const { classFolder, subjectFolder } = location.state;
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFiles = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BE_URL}/api/sma/classFiles/${classFolder}/${subjectFolder}`
        );
        setFiles(response.data);
        setIsLoading(false);
      } catch (error) {
        alert("Failed to fetch files:");
        console.error("Failed to fetch files:", error);
        setIsLoading(false);
      }
    };

    fetchFiles();
  }, [classFolder, subjectFolder]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Set the selected file
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("classFolder", classFolder);
    formData.append("subjectFolder", subjectFolder);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        `${BE_URL}/api/sma/folders-files/upload-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setFiles([...files, { name: file.name, url: response.data.url }]); // Update the file list
        alert("File uploaded successfully.");
        setFile(null);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to upload file:", error);
      alert("Error uploading file");
      setIsLoading(false);
    }
  };

  const handleFileDelete = async (fileName) => {
    if (!window.confirm("Are you sure you want to delete this file?")) {
      return;
    }

    setIsLoading(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.delete(
        `${BE_URL}/api/sma/folders-files/delete-file`,
        {
          data: { classFolder, subjectFolder, fileName },
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      if (response.status === 200) {
        setFiles(files.filter((file) => file.name !== fileName)); // Update the file list
        alert("File deleted successfully.");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to delete file:", error);
      alert("Error deleting file");
      setIsLoading(false);
    }
  };

  return (
    <>
      {permissions?.can_read && (
        <div className="filespage">
          {isLoading && <Loader />}
          <div className="filespage-header">
            {/* <div className="filespage-header-back">Back</div> */}
            <div className="filespage-header-title">
              {classFolder} / {subjectFolder}
            </div>
            {permissions?.can_create && (
              <div className="filespage-header-add">
                <input
                  label="Select File"
                  placeholder="Select File"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />

                <Button text="Upload PDF" onClick={handleFileUpload} />
              </div>
            )}
          </div>
          <ul>
            {files.map((file) => (
              <li key={file.name}>
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                  {file.name}
                </a>
                {permissions?.can_delete && (
                  <Button
                    text="Delete"
                    onClick={() => handleFileDelete(file.name)}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default FilesPage;
