import React, { useState, useEffect } from "react";
import "./Announcements.scss"; // Assuming you have a corresponding SCSS file
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import DropDown from "../../components/DropDown/DropDown";
import { DateRangePicker } from "rsuite";
import ResponseModal from "../../components/ResponseModal/ResponseModal";
import Modal from "../../components/Modal/Modal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Announcements = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Management")
    ?.dropdowns.find((dd) => dd.name === "Announcements")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [announcements, setannouncements] = useState([]);
  const [allannouncements, setallannouncements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [audienceFilter, setaudienceFilter] = useState(null);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Initialize the date range state
  const [dateRange, setDateRange] = useState(() => {
    const today = new Date();
    const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));
    return [lastMonth, today];
  });

  // Fetch announcements from API
  // useEffect(() => {
  //   fetchannouncements();
  // }, []);

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      fetchannouncements(dateRange[0], dateRange[1]);
    }
  }, [dateRange]);

  useEffect(() => {
    setSelectedItem(null);
    applyFilters();
  }, [audienceFilter]);

  const applyFilters = () => {
    let filteredDocs = [...allannouncements];

    // Apply status filter
    if (audienceFilter && audienceFilter !== "All") {
      filteredDocs = filteredDocs.filter(
        (product) => product.audience === audienceFilter
      );
    }

    setannouncements(filteredDocs); // Update the displayed products based on filters
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchannouncements = async (startDate, endDate) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const formattedStartDate = startDate.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
      const formattedEndDate = endDate.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'

      const response = await axios.get(`${BE_URL}/api/announcements`, {
        params: { startDate: formattedStartDate, endDate: formattedEndDate },
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setallannouncements(response.data);
      setannouncements(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchannouncements(); // Retry fetching announcements with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch announcements.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (data) => {
    setIsLoading(true);
    setError(null);
    setisAddModalOpen(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/api/announcements`, data, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      fetchannouncements(dateRange[0], dateRange[1]);

      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(data); // Retry adding the document with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to add. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
      setisAddModalOpen(false);
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(`${BE_URL}/api/announcements/${selectedItem.id}`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      // Remove the deleted subject from the announcements list
      const updatedannouncements = announcements.filter((sub) => sub.id !== id);
      setannouncements(updatedannouncements);
      setSelectedItem(null);

      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(id); // Retry deleting subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="halfpage">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="halfpage-header">
            <div className="halfpage-header-name">Announcements</div>
            <div className="halfpage-header-filters">
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Date Range</div>
                <DateRangePicker
                  format="yyyy-MM-dd"
                  onChange={handleDateRangeChange}
                  cleanable={false}
                  value={dateRange}
                />
              </div>
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Content For</div>
                <DropDown
                  options={["All", "Everyone", "Staff", "Students"]}
                  value={audienceFilter}
                  onChange={(value) => setaudienceFilter(value)}
                />
              </div>
            </div>
          </div>
          <div className="halfpage-contents">
            <div className="halfpage-contents-list">
              <HalfList
                data={announcements}
                fields={["subject", "audience"]}
                setSelectedItem={setSelectedItem}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                elem={
                  <AddDocument
                    handleAdd={(newDivision) => handleAdd(newDivision)}
                  />
                }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="halfpage-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={selectedItem.subject}
                    // handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    can_delete={permissions?.can_delete}
                    can_update={false}
                  >
                    <DetailsChild selectedItem={selectedItem}></DetailsChild>
                  </HalfDetails>
                </div>
              ) : (
                <div className="halfpage-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Announcements;

const AddDocument = ({ handleAdd }) => {
  const [subject, setsubject] = useState("");
  const [body, setbody] = useState("");
  const [audience, setaudience] = useState("");

  const handleSubmit = () => {
    if (!subject || !audience || !body) {
      alert("All fields are mandatory!");
      return;
    }
    // Use FormData to accommodate file data
    const data = {
      subject,
      body,
      audience,
    };
    handleAdd(data);
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add a Announcement</div>

      <ObjectDropDown
        label="Audience"
        options={[
          { label: "Everyone", value: "Everyone" },
          { label: "Students", value: "Students" },
          { label: "Staff", value: "Staff" },
        ]}
        setId={() => {}}
        value={audience}
        setValue={setaudience}
      />
      <CustomTextInput
        label="Subject"
        placeholder="Subject"
        value={subject}
        onChange={(e) => setsubject(e.target.value)}
      />

      <textarea
        label="Body"
        placeholder="Body"
        value={body}
        onChange={(e) => setbody(e.target.value)}
      />
      <Button text="Add Announcement" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({ selectedItem }) => {
  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="dc">
      <div className="dc-text">
        <div className="dc-text-label">Audience</div>
        <div className="dc-text-data">{selectedItem.audience}</div>
      </div>
      <div className="dc-textfull">
        <div className="dc-textfull-label">Subject</div>
        <div className="dc-textfull-data">{selectedItem.subject}</div>
      </div>

      <div className="dc-textfull">
        <div className="dc-textfull-label">Body</div>
        <div className="dc-textfull-data">
          <div className="dc-textfull-data">{selectedItem.body}</div>
        </div>
      </div>
      <div className="dc-textfull">
        <div className="dc-textfull-label">Create At</div>
        <div className="dc-textfull-data">
          {formatDateAndTime(selectedItem.created_at)}
        </div>
      </div>
    </div>
  );
};
