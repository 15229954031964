import "./Header.scss";

import React from "react";
import Logo from "../../assets/images/logo.png";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { ReactComponent as Burger } from "../../assets/images/Burger.svg";
import Button from "../Button/Button";
import { useDispatch } from "react-redux";
import { clearUser } from "../../state/auth/authSlice";

function Header({ onClick }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateHome = () => {
    navigate("/");
  };

  const isAuthenticated = () => {
    return localStorage.getItem("ERPTokens") !== null;
  };

  const handleAuthAction = () => {
    if (isAuthenticated()) {
      localStorage.removeItem("ERPTokens");
      navigate("/login");
    } else {
      dispatch(clearUser);
      navigate("/login");
    }
  };

  const loginClicked = () => {
    navigate("/login");
  };
  return (
    <header className="header Poppins">
      <div className="header-logo">
        <img
          src={Logo}
          alt="Chaitainya's Academy Logo"
          onClick={navigateHome}
        />
      </div>

      <div className="header-bar header-bar-full">
        <div className="header-bar-logo"></div>
        <div className="header-bar-links">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "activeLink header-bar-links-link"
                : "header-bar-links-link"
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive
                ? "activeLink header-bar-links-link"
                : "header-bar-links-link"
            }
          >
            About Us
          </NavLink>

          <NavLink
            to="/enquiry"
            className={({ isActive }) =>
              isActive
                ? "activeLink header-bar-links-link"
                : "header-bar-links-link"
            }
          >
            Enquiry
          </NavLink>
          <NavLink
            to="/results"
            className={({ isActive }) =>
              isActive
                ? "activeLink header-bar-links-link"
                : "header-bar-links-link"
            }
          >
            Results
          </NavLink>
          <Link
            to={`https://chaitanyas-academy.com/blog`}
            target="_blank"
            className="header-bar-links-link"
          >
            Blog
          </Link>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive
                ? "activeLink header-bar-links-link"
                : "header-bar-links-link"
            }
          >
            Contact Us
          </NavLink>
          {/* <Link to="/contact" className="header-bar-links-link">
            Blog
          </Link> */}
        </div>
        <div className="header-buttons">
          {isAuthenticated() ? (
            <Button text={"Logout"} onClick={handleAuthAction} type={`red`} />
          ) : (
            <Button text={"Login"} onClick={handleAuthAction} />
          )}
        </div>
      </div>

      <div className="header-bar header-bar-phone">
        <Burger onClick={onClick} />
      </div>
    </header>
  );
}

export default Header;
