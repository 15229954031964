import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./PaymentResult.scss";

const PaymentResult = () => {
  const location = useLocation();
  //   const history = useHistory();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get("status");
  const message = searchParams.get("message");
  const orderId = searchParams.get("order_id");

  const goToHome = () => {
    // history.push("/");
    navigate("/student");
  };

  return (
    <div className="payment-result">
      <div
        className={`result-card ${status === "success" ? "success" : "error"}`}
      >
        <h1>Payment {status === "success" ? "Successful" : "Failed"}</h1>
        <p>Message: {decodeURIComponent(message)}</p>
        <p>Order ID: {decodeURIComponent(orderId)}</p>
        <button onClick={goToHome}>Go to Home</button>
      </div>
    </div>
  );
};

export default PaymentResult;
