import React, { useState, useEffect } from "react";
import axios from "axios";
import "./StudentResults.scss";
import { jwtDecode } from "jwt-decode";
import Loader from "../../components/Loader/Loader";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const StudentResults = ({ is_admin, user }) => {
  let uid = null;
  const [user_id, setuser_id] = useState(null);
  const [results, setResults] = useState({
    cet: [],
    neet: [],
    jee_main: [],
    jee_adv: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true);
      try {
        const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
        const response = await axios.post(
          `${BE_URL}/api/exams/getStudentResults`,
          { user_id: uid }, // Passing user_id in the request body
          {
            headers: {
              Authorization: `Bearer ${tokens.accessToken}`,
            },
          }
        );
        setResults(response.data.data);
      } catch (error) {
        console.error("Failed to fetch results:", error);
        alert("Failed to load student results.");
      }
      setLoading(false);
    };

    const fetchUserData = () => {
      const tokenData = localStorage.getItem("ERPTokens");
      if (tokenData) {
        const { accessToken } = JSON.parse(tokenData);
        try {
          const decodedToken = jwtDecode(accessToken);
          console.log("user id is ", decodedToken.user_id);
          uid = decodedToken.user_id;
          setuser_id(decodedToken.user_id);
        } catch (error) {
          console.error("Token decoding failed:", error);
        }
      }
    };

    if (!is_admin) {
      console.log("is Admin is, ", is_admin);
      fetchUserData();
      fetchResults();
    } else {
      // setuser_id(user);
      uid = user;
      fetchResults();
    }
  }, []);

  const ExamSection = ({ data, title }) => {
    if (data.length === 0) return null;

    return (
      <div className="exam-section">
        <h3>{title}</h3>
        <table>
          <thead>
            <tr>
              {Object.keys(data[0]).map((key) => (
                <th key={key}>{key.replace(/_/g, " ").toUpperCase()}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                {Object.values(item).map((val, idx) => (
                  <td key={idx}>{val}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <div className="student-results">
        {loading && <Loader />}

        <div className="student-results-title">Results</div>
        <div className="student-results-container">
          {!results.cet.length &&
            !results.jee_adv.length &&
            !results.jee_main.length &&
            !results.neet.length && <>No Results found</>}
          <ExamSection data={results.cet} title="CET Results" />
          <ExamSection data={results.neet} title="NEET Results" />
          <ExamSection data={results.jee_main} title="JEE Main Results" />
          <ExamSection data={results.jee_adv} title="JEE Advanced Results" />
        </div>
      </div>
    </>
  );
};

export default StudentResults;
