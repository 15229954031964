import React, { useState, useEffect } from "react";
import "./TimetableDetails.scss";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
// import { Select } from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import CustomTimePicker from "../../components/CustomTimePicker/CustomTimePicker";
import DropDown from "../../components/DropDown/DropDown";

// const BE_URL = "http://localhost:8000"; // Backend URL
const BE_URL = "https://server.classerp.in"; // Use your backend URL

const TimetableDetails = () => {
  const { timetableId } = useParams();

  // RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Students")
    ?.dropdowns.find((dd) => dd.name === "Timetables")?.permissions;

  const navigate = useNavigate();
  const [timetable, setTimetable] = useState({ details: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for opening/closing the add class modal
  const [isAddClassOpen, setIsAddClassOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null); // For editing
  const [timetableDetails, setTimetableDetails] = useState([]);

  useEffect(() => {
    fetchTimetableDetails();
  }, []);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchTimetableDetails = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `${BE_URL}/api/timetables/${timetableId}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setTimetable(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await refreshToken();
        fetchTimetableDetails(); // Retry after refreshing token
      } else {
        setError("Failed to load timetable details. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete?")) {
      return;
    }
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.delete(
        `${BE_URL}/api/timetableDetails/${id}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      fetchTimetableDetails();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await refreshToken();
        handleDelete(id); // Retry after refreshing token
      } else {
        setError("Failed to delete. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Function to open the add/edit class form
  const openAddEditClassForm = (classToEdit = null) => {
    setSelectedClass(classToEdit); // If null, it's add; otherwise, it's edit
    setIsAddClassOpen(true);
  };

  // Function to handle add/edit class form submission
  const handleSubmitClass = async (classDetails) => {
    setIsLoading(true);
    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
    try {
      if (classDetails.id) {
        // Edit class
        await axios.put(
          `${BE_URL}/api/timetableDetails/${classDetails.id}`,
          { ...classDetails, timetableId },
          {
            headers: { Authorization: `Bearer ${tokens.accessToken}` },
          }
        );
      } else {
        // Add class
        await axios.post(
          `${BE_URL}/api/timetableDetails`,
          { ...classDetails, timetableId },
          {
            headers: { Authorization: `Bearer ${tokens.accessToken}` },
          }
        );
      }
      setIsAddClassOpen(false);
      fetchTimetableDetails(); // Refresh details
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await refreshToken();
        handleSubmitClass(classDetails); // Retry after refreshing token
      } else {
        alert("Failed to submit class details. Please try again.");
        console.error("Error submitting class:", error);
        setError("Failed to submit class details. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const renderDayEntries = (day) => {
    const dayEntries = timetable.details.filter(
      (entry) => entry.day_of_week === day
    );
    if (dayEntries.length === 0)
      return <div className="classCard">No Classes</div>;
    return dayEntries.map((entry, index) => (
      <div key={index} className="classCard">
        <div className="classCard-time">{`${entry.start_time.slice(
          0,
          -3
        )} - ${entry.end_time.slice(0, -3)}`}</div>
        <div className="classCard-subject">{entry.sub_name}</div>
        with
        <div className="classCard-teacher">{entry.name}</div>
        <div className="classCard-buttons">
          {permissions?.can_update && (
            <Button
              text={`Edit`}
              type={`mini`}
              onClick={() => openAddEditClassForm(entry)}
            />
          )}
          {permissions?.can_delete && (
            <Button
              text={`Delete`}
              type={`mini`}
              onClick={() => handleDelete(entry.id)}
            />
          )}
        </div>
        {/* <button
          className="edit-class-btn"
          onClick={() => openAddEditClassForm(entry)}
        >
          Edit
        </button> */}
      </div>
    ));
  };

  return (
    <div className="timetableDetails">
      {isLoading && <Loader />}

      <div className="timetableDetails-meta">
        <div className="timetableDetails-meta-title">
          Timetable for {timetable.meta?.class_name}{" "}
          {timetable.meta?.division_name}
        </div>
        {permissions?.can_create && (
          <Button
            onClick={() => openAddEditClassForm()}
            text={`Add Class`}
          ></Button>
        )}
      </div>

      {isAddClassOpen && (
        <Modal isOpen={isAddClassOpen} onClose={() => setIsAddClassOpen(false)}>
          <AddEditClassForm
            onSubmit={handleSubmitClass}
            classDetails={selectedClass} // Pass null for add, or class details for edit
          />
        </Modal>
      )}
      {!isLoading && (
        <div className="">
          <div className="timetable-meta"></div>
          <div className="timetable-container">
            {daysOfWeek.map((day) => (
              <div key={day} className="day-column">
                <h3 className="day-header">{day}</h3>
                {renderDayEntries(day)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimetableDetails;

const AddEditClassForm = ({ onSubmit, classDetails }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [startTime, setStartTime] = useState(classDetails?.start_time || "");
  const [endTime, setEndTime] = useState(classDetails?.end_time || "");
  const [subject_id, setsubject_id] = useState(
    classDetails?.subject_id || null
  );
  const [teacher_id, setteacher_id] = useState(
    classDetails?.teacher_id || null
  );
  const [subjects, setSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(
    classDetails?.sub_name || ""
  );

  const [selectedTeacher, setSelectedTeacher] = useState(
    classDetails?.name || ""
  );

  const [dayOfWeek, setDayOfWeek] = useState(classDetails?.day_of_week || null);

  const days = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    // { value: "Sunday", label: "Sunday" },
  ];

  useEffect(() => {
    const fetchSubjects = async () => {
      setIsLoading(true);
      try {
        const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
        const response = await axios.get(`${BE_URL}/api/general/subjects`, {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        });
        setSubjects(response.data);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchTeachers = async () => {
      setIsLoading(true);
      try {
        const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
        const response = await axios.get(`${BE_URL}/api/general/teachers`, {
          // Adjust the endpoint as necessary
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        });
        setTeachers(response.data);
      } catch (error) {
        console.error("Error fetching teachers:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeachers();
    fetchSubjects();
  }, []);

  useEffect(() => {}, []);

  const handleFormSubmit = () => {
    if (
      !selectedSubject ||
      !selectedTeacher ||
      !dayOfWeek ||
      !startTime ||
      !endTime
    ) {
      alert("All fields are mandatory.");
      return;
    }
    onSubmit({
      id: classDetails?.id, // Include id for edit
      day_of_week: dayOfWeek,
      start_time: startTime,
      end_time: endTime,
      subject_id: subject_id,
      teacher_id: teacher_id,
    });
  };

  return (
    <>
      {!subjects || !teachers || isLoading ? (
        <Loader />
      ) : (
        <div className="halfAdd">
          <div className="halfAdd-title">Add a Class</div>
          {/* Subject dropdown */}
          <ObjectDropDown
            label="Day of Week"
            placeholder="Select day"
            options={days.map((day) => ({
              label: day.label,
              value: day.value,
            }))}
            value={dayOfWeek}
            setValue={setDayOfWeek}
            setId={() => {}}
          />
          {/* <Select
            label="Day of Week"
            placeholder="Select day"
            data={days}
            value={dayOfWeek}
            onChange={setDayOfWeek}
          /> */}
          <ObjectDropDown
            label="Subject"
            options={subjects.map((subject) => ({
              label: subject.id,
              value: subject.name,
            }))}
            value={selectedSubject}
            setValue={setSelectedSubject}
            setId={setsubject_id}
          />
          {/* Teacher dropdown */}
          <ObjectDropDown
            label="Teacher"
            options={teachers.map((teacher) => ({
              label: teacher.id,
              value: teacher.name,
            }))}
            value={selectedTeacher}
            setValue={setSelectedTeacher}
            setId={setteacher_id}
          />
          {/* Start Time Picker */}
          <CustomTimePicker
            label="Start Time"
            value={startTime}
            onChange={setStartTime}
          />

          {/* End Time Picker */}
          <CustomTimePicker
            label="End Time"
            value={endTime}
            onChange={setEndTime}
          />
          <Button type="submit" text="Save Class" onClick={handleFormSubmit} />
        </div>
      )}
    </>
  );
};
