import "./FilesDownload.scss";

import React, { useState } from "react";
import axios from "axios";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";

const FilesDownload = () => {
  const [secretKey, setSecretKey] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleVerify = async () => {
    try {
      setIsLoading(true);
      // const response = await axios.post("http://localhost:8000/verify", {
      //   secretKey,
      // });
      const response = await axios.post("https://server.classerp.in/verify", {
        secretKey,
      });
      // const response = await axios.post(
      //   "https://ams-ki52.onrender.com/verify",
      //   {
      //     secretKey,
      //   }
      // );
      if (response.data.verified) {
        setIsVerified(true);
      } else {
        alert("Incorrect secret key");
      }
    } catch (error) {
      console.error("Error verifying secret key:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async (filename) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `https://server.classerp.in/download/${filename}`,
        { secretKey },
        { responseType: "blob" }
      );
      // const response = await axios.post(
      //   `http://localhost:8000/download/${filename}`,
      //   { secretKey },
      //   { responseType: "blob" }
      // );
      // const response = await axios.post(
      //   `https://ams-ki52.onrender.com/download/${filename}`,
      //   { secretKey },
      //   { responseType: "blob" }
      // );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      alert("An error occured");
      console.error("Error downloading file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="download">
      {isLoading && <Loader />}
      <div className="download-content">
        <div className="download-content-left">
          <div className="download-content-left-heading">Hello AMS Admin</div>
        </div>
        <div className="download-content-right">
          {isVerified ? (
            <div className="download-content-right-verified">
              <Button
                onClick={() => handleDownload("Enquiry.xlsx")}
                text={`Download Enquiry File`}
              />

              <Button
                onClick={() => handleDownload("Scholarship.xlsx")}
                text={`Download Scholarship File`}
              />
              <Button
                onClick={() => handleDownload("Entrance.xlsx")}
                text={`Download Entrance File`}
              />
            </div>
          ) : (
            <div className="download-content-right-unverified">
              <div className="download-content-right-unverified-msg">
                Please enter the secret Key.
              </div>
              <input
                type="password"
                placeholder="Enter secret key"
                value={secretKey}
                onChange={(e) => setSecretKey(e.target.value)}
              />
              <Button onClick={handleVerify} text="Verify" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilesDownload;
