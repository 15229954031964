import React from "react";
import { SelectPicker } from "rsuite";
import "./CustomDropDown.scss";
// import "rsuite/dist/styles/rsuite-default.css"; // Import rsuite styles

const CustomDropDown = ({
  data,
  value,
  label,
  onChange,
  placeholder,
  searchable = true,
}) => {
  console.log("Received data is ", data);
  const newData = data.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  return (
    <div className="custdrop">
      <div className="custdrop-label">{label}</div>
      <SelectPicker
        data={newData}
        value={value}
        onChange={onChange}
        searchable={searchable}
        placeholder={placeholder}
        menuClassName="custom-dropdown-menu" // Apply custom class to the dropdown menu
        // Add any additional props you need to customize
      />
    </div>
  );
};

export default CustomDropDown;
