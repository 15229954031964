import React, { useState, useEffect } from "react";
import "./Inventory.scss"; // Assuming you have a corresponding SCSS file
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import Modal from "../../components/Modal/Modal";
import DropDown from "../../components/DropDown/DropDown";
import ResponseModal from "../../components/ResponseModal/ResponseModal";
import { useSelector } from "react-redux";
import Button from "../../components/Button/Button";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Inventory = () => {
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Inventory")
    ?.dropdowns.find((dd) => dd.name === "Inventory")?.permissions;

  const [selectedItem, setSelectedItem] = useState(null);
  const [productName, setProductName] = useState("");
  const [originalQuantity, setOriginalQuantity] = useState("");
  const [remainingQuantity, setRemainingQuantity] = useState("");
  const [inventoryItems, setInventoryItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [isAddModalOpen, setisAddModalOpen] = useState(false);
  const [msg, setmsg] = useState("");
  const [responseType, setresponseType] = useState("");
  const [isResponseOpen, setisResponseOpen] = useState(false);

  useEffect(() => {
    fetchInventoryItems();
  }, []);

  useEffect(() => {
    if (!selectedItem) {
      return;
    }

    setProductName(selectedItem.product_name);
    setOriginalQuantity(selectedItem.original_quantity);
    setRemainingQuantity(selectedItem.remaining_quantity);
  }, [selectedItem]);

  const fetchInventoryItems = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/inventory`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      console.log(response.data.data);
      setInventoryItems(response.data.data);
    } catch (error) {
      console.error("Failed to fetch inventory items:", error);
      setError("Failed to fetch inventory items.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddInventoryItem = async (newItem) => {
    setIsLoading(true);
    setisAddModalOpen(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.post(`${BE_URL}/api/inventory`, newItem, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setmsg("Item added successfully");
      setresponseType("Success");
      setisResponseOpen(true);
      fetchInventoryItems();
    } catch (error) {
      console.error("Failed to add inventory item:", error);
      setmsg("Failed to add item");
      setresponseType("Error");
      setisResponseOpen(true);
    } finally {
      setIsLoading(false);
      setisAddModalOpen(false);
    }
  };

  const handleEdit = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const updateData = {
        product_name: productName,
        original_quantity: originalQuantity,
        remaining_quantity: remainingQuantity,
      };

      const response = await axios.put(
        `${BE_URL}/api/inventory/${selectedItem.id}`,
        updateData,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Update the inventory list with the edited inventory item
      const updatedItems = inventoryItems.map((item) =>
        item.id === response.data.id ? response.data : item
      );
      setInventoryItems(updatedItems);
      setSelectedItem(response.data);
      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);
    } catch (error) {
      console.error("Failed to update inventory item:", error);
      setresponseType("Error");
      setmsg("Failed to update inventory item. Please try again.");
      setisResponseOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (selectedItem.original_quantity !== selectedItem.remaining_quantity) {
      alert("This product cannot be deleted as it is already assigned.");
      return;
    }
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(`${BE_URL}/api/inventory/${selectedItem.id}`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      // Refresh the inventory list or remove the item from the local state
      setInventoryItems((prevItems) =>
        prevItems.filter((item) => item.id !== selectedItem.id)
      );
      setSelectedItem(null);
      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      console.error("Failed to delete inventory item:", error);
      setresponseType("Error");
      setmsg("Failed to delete inventory item. Please try again.");
      setisResponseOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="halfpage">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="halfpage-header">
            <div className="halfpage-header-name">Inventory Management</div>
          </div>
          <div className="halfpage-contents">
            <div className="halfpage-contents-list">
              <HalfList
                data={inventoryItems}
                fields={["product_name", "remaining_quantity"]}
                setSelectedItem={setSelectedItem}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                elem={<AddInventoryItem handleAdd={handleAddInventoryItem} />}
              />
              {error && <p className="error">{error}</p>}
            </div>

            <div className="halfpage-contents-details">
              {selectedItem ? (
                <HalfDetails
                  id={selectedItem.id}
                  name={selectedItem.product_name}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  can_delete={permissions?.can_delete}
                  can_update={permissions?.can_update}
                >
                  <DetailsChild
                    selectedItem={selectedItem}
                    productName={selectedItem.product_name}
                    originalQuantity={selectedItem.original_quantity}
                    remainingQuantity={selectedItem.remaining_quantity}
                    setProductName={setProductName}
                    setOriginalQuantity={setOriginalQuantity}
                    setRemainingQuantity={setRemainingQuantity}
                    fetchInventoryItems={fetchInventoryItems}
                  />
                </HalfDetails>
              ) : (
                <div className="no-selection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Inventory;

const AddInventoryItem = ({ handleAdd }) => {
  const [productName, setProductName] = useState("");
  const [originalQuantity, setOriginalQuantity] = useState("");

  const handleSubmit = () => {
    if (!productName || !originalQuantity) {
      alert("All fields are mandatory!");
      return;
    }
    const newItem = {
      product_name: productName,
      original_quantity: originalQuantity,
    };
    handleAdd(newItem);
  };

  return (
    <div className="add-inventory-item">
      <div className="halfAdd-title">Add Inventory Item</div>
      <CustomTextInput
        label="Product Name"
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
      />
      <CustomNumberInput
        label="Original Quantity"
        value={originalQuantity}
        onChange={(e) => setOriginalQuantity(e)}
      />
      <Button text="Add Item" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({
  selectedItem,
  productName,
  originalQuantity,
  remainingQuantity,
  setProductName,
  setOriginalQuantity,
  setRemainingQuantity,
  fetchInventoryItems,
}) => {
  const [isAssignOpen, setisAssignOpen] = useState(false);
  const [isSubmitOpen, setisSubmitOpen] = useState(false);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAssign = async (assignmentData) => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        `${BE_URL}/api/inventory/assign`,
        assignmentData,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );

      // Handle response here, e.g., updating state or notifying the user
      setisAssignOpen(false); // Assuming you have a modal or some UI element to close
      setresponseType("Success");
      setmsg("Inventory item assigned successfully");
      setisResponseOpen(true);

      // Optionally, refresh the inventory list or update state locally
      fetchInventoryItems(); // Assuming you have a function to refresh inventory
    } catch (error) {
      console.error("Error assigning inventory item:", error);
      setisAssignOpen(false);
      if (error.response && error.response.status === 401) {
        alert("Session expired. Please log in again.");
      } else {
        setresponseType("Error");
        setmsg("Failed to assign inventory item. Please try again.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="dc">
      <Modal isOpen={isResponseOpen} onClose={() => setisResponseOpen(false)}>
        <ResponseModal
          type={responseType}
          msg={msg}
          onClick={() => setisResponseOpen(false)}
        />
      </Modal>

      <Modal isOpen={isAssignOpen} onClose={() => setisAssignOpen(false)}>
        <ProductAssign
          productId={selectedItem.id}
          handleAssign={handleAssign}
        />
      </Modal>

      <div className="dc-header">
        <div className="dc-header-cta">
          {remainingQuantity > 0 ? (
            <Button text={`Assign`} onClick={() => setisAssignOpen(true)} />
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="dc-input">
        <CustomTextInput
          label="Product Name"
          value={productName}
          onChange={setProductName}
        />
      </div>
      <div className="dc-input">
        <CustomNumberInput
          label="Original Quantity"
          value={originalQuantity}
          onChange={(e) => setOriginalQuantity(e)}
        />
      </div>
      <div className="dc-input">
        <CustomNumberInput
          label="Remaining Quantity"
          value={remainingQuantity}
          onChange={(e) => setRemainingQuantity(e)}
        />
      </div>
    </div>
  );
};

const ProductAssign = ({ handleAssign, productId }) => {
  const [assignee, setAssignee] = useState("");
  const [assignDate, setAssignDate] = useState("");

  const handleSubmit = () => {
    if (!assignee || !assignDate) {
      alert("Assignee and Assign Date are mandatory!");
      return;
    }
    const assignmentData = {
      inventory_id: productId,
      student_roll_no: assignee,
    };
    handleAssign(assignmentData);
  };

  return (
    <div className="inventory-assign">
      <div className="halfAdd-title">Assign Inventory Item</div>
      <CustomTextInput
        label="Assignee ID / Roll No"
        placeholder="Enter Assignee ID or Roll No"
        value={assignee}
        onChange={(e) => setAssignee(e.target.value)}
      />
      <CustomTextInput
        type="date"
        label="Assign Date"
        value={assignDate}
        onChange={(e) => setAssignDate(e.target.value)}
      />
      <Button text="Assign Item" onClick={handleSubmit} />
    </div>
  );
};
