import React, { useState, useEffect } from "react";
// import SubjectForm from "./SubjectForm";
// import SubjectList from "./SubjectList";
import "./Vendors.scss"; // Assuming you have a corresponding SCSS file
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Vendors = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Finance")
    ?.dropdowns.find((dd) => dd.name === "Vendors")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [name, setname] = useState(null);
  const [category_id, setcategory_id] = useState(null);
  const [category, setcategory] = useState(null);
  const [description, setdescription] = useState(null);
  const [vendors, setvendors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [phone, setphone] = useState(null);
  const [email, setemail] = useState(null);
  const [gst_number, setgst_number] = useState(null);
  const [account_no, setaccount_no] = useState(null);
  const [ifsc, setifsc] = useState(null);
  const [categories, setcategories] = useState([]);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Fetch vendors from API
  useEffect(() => {
    fetchcategories();
    fetchvendors();
  }, []);

  useEffect(() => {
    if (!selectedItem) {
      return;
    }
    setname(selectedItem.name);
    setcategory_id(selectedItem.category_id);
    setcategory(selectedItem.category);
    setdescription(selectedItem.description ? selectedItem.description : "");
    setemail(selectedItem.email ? selectedItem.email : "");
    setphone(selectedItem.phone ? selectedItem.phone : "");
    setgst_number(selectedItem.gst_number ? selectedItem.gst_number : "");
    setaccount_no(selectedItem.account_no ? selectedItem.account_no : "");
    setifsc(selectedItem.ifsc ? selectedItem.ifsc : "");
  }, [selectedItem]);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchcategories = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(
        `${BE_URL}/api/general/categories/Vendor`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
          },
        }
      );
      setcategories(response.data); // Assuming the API returns an array of class objects
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch categories. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchvendors = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/vendors`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setvendors(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchvendors(); // Retry fetching vendors with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch vendors.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (newVendor) => {
    setIsLoading(true);
    setError(null);
    setisAddModalOpen(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/api/vendors`, newVendor, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      // Add the new subject to the vendors list
      fetchvendors();
      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(newVendor); // Retry adding subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to Add Fee Structure");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
      setisAddModalOpen(false);
    }
  };

  const handleEdit = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.put(
        `${BE_URL}/api/vendors/${selectedItem.id}`,
        {
          name: name,
          category_id: category_id,
          description: description,
          phone,
          email,
          gst_number,
          account_no,
          ifsc,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Update the vendors list with the edited subject
      const updatedvendors = vendors.map((sub) =>
        sub.id === response.data.id ? response.data : sub
      );
      setvendors(updatedvendors);
      fetchvendors();
      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);
      setSelectedItem(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleEdit(); // Retry editing subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to edit. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (subjectId) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(`${BE_URL}/api/vendors/${selectedItem.id}`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setSelectedItem(null);
      fetchvendors();
      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(subjectId); // Retry deleting subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="halfpage">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="halfpage-header">
            <div className="halfpage-header-name">Vendors</div>
          </div>
          <div className="halfpage-contents">
            <div className="halfpage-contents-list">
              <HalfList
                data={vendors}
                fields={["name", "category"]}
                setSelectedItem={setSelectedItem}
                handleAdd={handleAdd}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                elem={
                  <AddVendor
                    handleAdd={(newDivision) => handleAdd(newDivision)}
                    categories={categories}
                  />
                }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="halfpage-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={selectedItem.name}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    can_delete={permissions?.can_delete}
                    can_update={permissions?.can_update}
                  >
                    <DetailsChild
                      selectedItem={selectedItem}
                      name={name}
                      category_id={category_id}
                      category={category}
                      description={description}
                      setname={setname}
                      gst_number={gst_number}
                      account_no={account_no}
                      ifsc={ifsc}
                      setifsc={setifsc}
                      setaccount_no={setaccount_no}
                      setgst_number={setgst_number}
                      setcategory_id={setcategory_id}
                      setcategory={setcategory}
                      setdescription={setdescription}
                      categories={categories}
                      phone={phone}
                      setphone={setphone}
                      email={email}
                      setemail={setemail}
                    ></DetailsChild>
                  </HalfDetails>
                </div>
              ) : (
                <div className="halfpage-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Vendors;

const AddVendor = ({ handleAdd, categories }) => {
  const [name, setname] = useState(null);
  const [category_id, setcategory_id] = useState(null);
  const [categ, setcateg] = useState(null);
  const [gst_number, setgst_number] = useState(null);
  const [account_no, setaccount_no] = useState(null);
  const [ifsc, setifsc] = useState(null);
  const [description, setdescription] = useState(null);
  const [phone, setphone] = useState(null);
  const [email, setemail] = useState(null);

  const handleSubmit = () => {
    if (!name || !category_id) {
      alert("Vendor Name and Category is mandatory!");
      return;
    }
    if (phone.toString().length != 10) {
      alert("Invalid Phone Number!");
      return;
    }
    const newVendor = {
      category_id,
      name,
      description,
      phone,
      email,
      gst_number,
      account_no,
      ifsc,
    };
    handleAdd(newVendor);
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add a Vendor</div>
      <CustomTextInput
        label="Name"
        placeholder="Vendor Name"
        value={name}
        onChange={(e) => setname(e.target.value)}
      />
      <ObjectDropDown
        label="Select Category"
        options={categories.map(({ name, id }) => ({
          label: id,
          value: name,
        }))}
        value={categ}
        setId={setcategory_id}
        setValue={setcateg}
      />
      <CustomTextInput
        value={gst_number}
        label={`GST Number`}
        placeholder={`GST Number`}
        onChange={(e) => setgst_number(e.target.value)}
      />
      <CustomTextInput
        value={account_no}
        label={`Account No.`}
        placeholder={`Account No.`}
        onChange={(e) => setaccount_no(e.target.value)}
      />
      <CustomTextInput
        value={ifsc}
        label={`IFSC Code`}
        placeholder={`IFSC Code`}
        onChange={(e) => setifsc(e.target.value)}
      />
      <CustomTextInput
        value={description}
        label={`Description`}
        placeholder={`Description`}
        onChange={(e) => setdescription(e.target.value)}
      />
      <CustomTextInput
        value={email}
        type={`Email`}
        label={`Email`}
        placeholder={`Email`}
        onChange={(e) => setemail(e.target.value)}
      />
      <CustomNumberInput
        value={phone}
        label={`Phone`}
        placeholder={`Phone`}
        onChange={(e) => setphone(e)}
      />
      <Button text="Add Vendor" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({
  name,
  category_id,
  category,
  categories,
  email,
  phone,
  description,
  gst_number,
  account_no,
  ifsc,
  setifsc,
  setgst_number,
  setaccount_no,
  setname,
  setcategory_id,
  setcategory,
  setdescription,
  setemail,
  setphone,
}) => {
  return (
    <div className="dc">
      <div className="dc-input">
        <CustomTextInput
          value={name}
          label={`Name`}
          onChange={(e) => setname(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <ObjectDropDown
          label="Type"
          options={categories.map(({ name, id }) => ({
            label: id,
            value: name,
          }))}
          value={category}
          setId={setcategory_id}
          setValue={setcategory}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={description}
          label={`Description`}
          placeholder={`Description`}
          onChange={(e) => setdescription(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={email}
          label={`Email`}
          placeholder={`Email`}
          onChange={(e) => setemail(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomNumberInput
          value={phone}
          label={`Phone`}
          placeholder={`Phone`}
          onChange={(e) => setphone(e)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={gst_number}
          label={`GST Number`}
          placeholder={`GST Number`}
          onChange={(e) => setgst_number(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={account_no}
          label={`Account No`}
          placeholder={`Account No`}
          onChange={(e) => setaccount_no(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={ifsc}
          label={`IFSC Code`}
          placeholder={`IFSC Code`}
          onChange={(e) => setifsc(e.target.value)}
        />
      </div>
    </div>
  );
};
