import React, { useState, useEffect } from "react";
import axios from "axios";
import "./RoleDetails.scss";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const RoleDetails = () => {
  const { id } = useParams();
  const [roleData, setRoleData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
  //   let accessToken = tokens.accessToken;

  useEffect(() => {
    const fetchRoleData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `https://server.classerp.in/api/roles/${id}/permissions`,
          {
            headers: {
              Authorization: `Bearer ${tokens.accessToken}`,
            },
          }
        );
        setRoleData(formatDataForState(response.data));
        setIsLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Token might be expired, try refreshing it
          try {
            const newToken = await refreshToken();
            const response = await axios.get(
              `https://server.classerp.in/api/roles/${id}/permissions`,
              {
                headers: {
                  Authorization: `Bearer ${newToken}`,
                },
              }
            );
            setRoleData(formatDataForState(response.data));
            setIsLoading(false);
          } catch (refreshError) {
            console.error("Error after refreshing token:", refreshError);
            setIsLoading(false);
          }
        } else {
          console.error("Error fetching role data:", error);
          alert("Error fetching role data.");
          setIsLoading(false);
        }
      }
    };

    fetchRoleData();
  }, [id]);

  const refreshToken = async () => {
    // Implement logic to refresh the token
    // Example:
    try {
      const refreshResponse = await axios.post(
        "https://server.classerp.in/api/refresh",
        {
          refreshToken: localStorage.getItem("refreshToken"),
        }
      );
      const newToken = refreshResponse.data.accessToken;
      localStorage.setItem(
        "ERPTokens",
        JSON.stringify({ ...tokens, accessToken: newToken })
      );
      return newToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      throw error;
    }
  };

  // Format data to work with the state
  const formatDataForState = (data) => {
    return data.map((menuItem) => ({
      ...menuItem,
      dropdowns: menuItem.dropdowns.map((dd) => ({
        ...dd,
        permissions: {
          can_create: dd.permissions.can_create,
          can_read: dd.permissions.can_read,
          can_update: dd.permissions.can_update,
          can_delete: dd.permissions.can_delete,
        },
      })),
    }));
  };

  const handleCheckboxChange = async (
    menuId,
    ddId,
    permissionType,
    isChecked
  ) => {
    setIsLoading(true);
    // Find the updated permissions in the current state
    const updatedPermissions = roleData
      .find((menuItem) => menuItem.id === menuId)
      .dropdowns.find((dd) => dd.id === ddId).permissions;

    const permissionsUpdate = {
      ddId,
      permissions: {
        ...updatedPermissions,
        [permissionType]: isChecked,
      },
    };

    try {
      const response = await axios.put(
        `https://server.classerp.in/api/roles/${id}/permissions`,
        permissionsUpdate,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );

      // Only update state if API call was successful
      if (response.status === 200) {
        // alert("Permission updated successfully");

        // Prepare a new roleData array with the updated permissions
        const newRoleData = roleData.map((menuItem) => {
          if (menuItem.id === menuId) {
            return {
              ...menuItem,
              dropdowns: menuItem.dropdowns.map((dd) => {
                if (dd.id === ddId) {
                  return {
                    ...dd,
                    permissions: {
                      ...dd.permissions,
                      [permissionType]: isChecked,
                    },
                  };
                }
                return dd;
              }),
            };
          }
          return menuItem;
        });

        // Update local state with the new data
        setRoleData(newRoleData);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Token might be expired, try refreshing it
        await refreshToken();
        handleCheckboxChange(menuId, ddId, permissionType, isChecked);
        setIsLoading(false);
      } else {
        // Handle other types of errors
        alert("Failed to update Permission");
        console.error("Error updating permissions:", error);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="roled">
      {isLoading && <Loader />}
      <h2>Role Permissions</h2>
      {roleData.map((menuItem) => (
        <div key={menuItem.id} className="menu-item">
          <h3>{menuItem.name}</h3>
          {menuItem.dropdowns.map((dd) => (
            <div key={dd.id} className="dropdown-item">
              <span>{dd.name}</span>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={dd.permissions.can_read}
                  onChange={(e) =>
                    handleCheckboxChange(
                      menuItem.id,
                      dd.id,
                      "can_read",
                      e.target.checked
                    )
                  }
                />{" "}
                Read
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={dd.permissions.can_create}
                  onChange={(e) =>
                    handleCheckboxChange(
                      menuItem.id,
                      dd.id,
                      "can_create",
                      e.target.checked
                    )
                  }
                />{" "}
                Add
              </label>

              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={dd.permissions.can_update}
                  onChange={(e) =>
                    handleCheckboxChange(
                      menuItem.id,
                      dd.id,
                      "can_update",
                      e.target.checked
                    )
                  }
                />{" "}
                Edit
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={dd.permissions.can_delete}
                  onChange={(e) =>
                    handleCheckboxChange(
                      menuItem.id,
                      dd.id,
                      "can_delete",
                      e.target.checked
                    )
                  }
                />{" "}
                Delete
              </label>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default RoleDetails;
