import Button from "../Button/Button";
import "./ResponseModal.scss";
import React from "react";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from "react-icons/io";

const ResponseModal = ({ type, onClick, msg }) => {
  const isSuccess = type === "Success";

  return (
    <div className={`rm ${isSuccess ? "rm-success" : "rm-error"}`}>
      <div className="rm-icon">
        {isSuccess ? (
          <IoMdCheckmarkCircleOutline size="48" />
        ) : (
          <IoMdCloseCircleOutline size="48" />
        )}
      </div>
      <div className="rm-msg">{msg}</div>
      <div className="rm-button">
        <Button
          text="Ok"
          onClick={onClick}
          type={isSuccess ? `argreen` : `arred`}
        />
      </div>
    </div>
  );
};

export default ResponseModal;
