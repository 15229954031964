import React, { useState, useEffect } from "react";
import axios from "axios";
import "./UserDetails.scss"; // Assuming you have styling set up
import LeavesList from "../../components/LeavesList/LeavesList";
import { Loader } from "rsuite";
import { useLocation } from "react-router-dom";
import AssignedInventory from "../../components/AssignedInventory/AssignedInventory";
import SalarySlips from "../../components/SalarySlips/SalarySlips";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const UserDetails = ({ route, navigate }) => {
  const [leaves, setLeaves] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const userId = location.state.userId;

  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true);
      try {
        const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
        const url = `${BE_URL}/api/general/userLeaves/${userId}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        });
        setLeaves(response.data);
      } catch (error) {
        console.error("Failed to fetch leaves:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId]);

  return (
    <div className="UserDetails">
      {isLoading && <Loader />}

      <div className="UserDetails-leaves">
        <h2 className="UserDetails-title">User Leaves</h2>

        {leaves.length > 0 ? (
          <LeavesList leaveApplications={leaves} cancel_allowed={false} />
        ) : (
          <p>No leaves found for this user.</p>
        )}
      </div>
      <div className="UserDetails-inventory">
        <div className="UserDetails-inventory-title">
          <h2 className="UserDetails-title">Assigned Inventory</h2>
          <AssignedInventory type={`user`} admin={true} id={userId} />
        </div>
      </div>
      <div className="UserDetails-slips">
        <div className="UserDetails-slips-title">
          {/* <h2 className="UserDetails-title">Salary Slips</h2> */}
          <SalarySlips admin={true} userId={userId} />
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
