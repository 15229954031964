import React from "react";
import { Routes, Route } from "react-router-dom";

import "./AdminPage.scss";

import Sidebar from "../../components/Sidebar/Sidebar";
import EnquiryList from "../EnquiryList/EnquiryList";
import WIP from "../../components/WIP/WIP";
import Enquiry from "../Enquiry/Enquiry";
import EnquiryDetails from "../EnquiryDetails/EnquiryDetails";
import AdminScholarshipList from "../AdminScholarshipList/AdminScholarshipList";
import AdminScholarshipDetail from "../AdminScholarshipDetail/AdminScholarshipDetail";
import UserAdministration from "../UserAdministration/UserAdministration";
import AdminEntranceList from "../AdminEntranceList/AdminEntranceList";
import AdminEntranceDetail from "../AdminEntranceDetail/AdminEntranceDetail";
import AdmissionForm from "../AdmissionForm/AdmissionForm";
import Dashboard from "../StudentDashboard/StudentDashboard";
import UploadMarks from "../UploadMarks/UploadMarks";
import Topbar from "../../components/Topbar/Topbar";
import Subjects from "../Subjects/Subjects";
import Classes from "../Classes/Classes";
import SeniorStudents from "../SeniorStudents/SeniorStudents";
import Hostels from "../Hostels/Hostels";
import HostelDetails from "../HostelDetails/HostelDetails";
import Roles from "../Roles/Roles";
import RoleDetails from "../RoleDetails/RoleDetails";
import StudentDetails from "../StudentDetails/StudentDetails";
import Divisions from "../Divisions/Divisions";
import Timetables from "../Timetables/Timetables";
import TimetableDetails from "../TimetableDetails/TimetableDetails";
import UserProfile from "../UserProfile/UserProfile";
import Categories from "../Categories/Categories";
import Vendors from "../Vendors/Vendors";
import FeeStructure from "../FeeStructure/FeeStructure";
import Products from "../Products/Products";
import Documents from "../Documents/Documents";
import Expenses from "../Expenses/Expenses";
import Resources from "../Resources/Resources";
import Announcements from "../Announcements/Announcements";
import LeaveApplicationForm from "../LeaveApplication/LeaveApplication";
import AdminLeaves from "../AdminLeaves/AdminLeaves";
import UserDetails from "../UserDetails/UserDetails";
import StaffDashboard from "../StaffDashboard/StaffDashboard";
import SalarySlips from "../../components/SalarySlips/SalarySlips";
import IdCardPhotoList from "../IdCardPhotoList/IdCardPhotoList";
import SalaryUpload from "../SalaryUpload/SalaryUpload";
import ExamDataUpload from "../ExamDataUpload/ExamDataUpload";
import ExamMarks from "../ExamMarks/ExamMarks";
import Prices from "../Prices/Prices";
import Inventory from "../Inventory/Inventory";
import Incomes from "../Incomes/Incomes";
import StudyApp from "../StudyApp/StudyApp";
import FilesPage from "../FilesPage/FilesPage";
import ScholarshipSettings from "../ScholarshipSettings/ScholarshipSettings";
// Import other components as needed

const AdminPage = () => {
  return (
    <div className="admin">
      <div className="admin-content">
        <Routes>
          <Route path="" index element={<StaffDashboard />} />
          {/* <Route path="dashboard" index element={<Dashboard />} /> */}
          <Route path="enquiries" index element={<EnquiryList />} />
          <Route path="enquiry/:id" element={<EnquiryDetails />} />
          <Route path="scholarships" element={<AdminScholarshipList />} />
          <Route
            path="scholarship/:roll_no"
            element={<AdminScholarshipDetail />}
          />
          <Route path="entrances" element={<AdminEntranceList />} />
          <Route path="entrance/:roll_no" element={<AdminEntranceDetail />} />
          <Route path="admission-form/:roll_no" element={<AdmissionForm />} />
          <Route path="uploadMarks" element={<UploadMarks />} />

          <Route path="subjects" element={<Subjects />} />
          <Route path="classes" element={<Classes />} />
          <Route path="divisions" element={<Divisions />} />
          <Route path="timetables" element={<Timetables />} />
          <Route
            path="timetables/:timetableId"
            element={<TimetableDetails />}
          />
          <Route
            path="seniorStudents"
            element={<SeniorStudents is_Senior={true} />}
          />
          <Route
            path="juniorStudents"
            element={<SeniorStudents is_Senior={false} />}
          />
          <Route path="seniorStudent/:roll_no" element={<StudentDetails />} />
          <Route path="juniorStudent/:roll_no" element={<StudentDetails />} />
          <Route path="exam-marks" element={<ExamMarks />} />
          <Route path="exam-marks-upload" element={<ExamDataUpload />} />
          <Route path="app" element={<StudyApp />} />
          <Route path="/app/subjectFiles" element={<FilesPage />} />

          <Route path="users" element={<UserAdministration />} />
          <Route path="users/userDetails" element={<UserDetails />} />
          <Route path="announcements" element={<Announcements />} />
          <Route path="hostels" element={<Hostels />} />
          <Route path="leaveRequests" element={<AdminLeaves />} />
          <Route path="feestructures" element={<FeeStructure />} />
          <Route path="id-photoes" element={<IdCardPhotoList />} />
          <Route path="documents" element={<Documents />} />
          <Route path="hostels/:id" element={<HostelDetails />} />
          <Route path="roles" element={<Roles />} />
          <Route path="roles/:id" element={<RoleDetails />} />

          <Route path="finance/vendors" element={<Vendors />} />
          <Route path="finance/expenses" element={<Expenses />} />
          <Route path="finance/incomes" element={<Incomes />} />
          <Route path="finance/salary-upload" element={<SalaryUpload />} />

          <Route path="inventory/products" element={<Products />} />
          <Route path="inventory/inventory" element={<Inventory />} />

          <Route path="settings/categories" element={<Categories />} />
          <Route path="settings/prices" element={<Prices />} />
          <Route
            path="settings/scholarship"
            element={<ScholarshipSettings />}
          />

          <Route path="extras/resources" element={<Resources />} />
          <Route path="extras/applyleave" element={<LeaveApplicationForm />} />
          <Route
            path="extras/salarySlips"
            element={<SalarySlips admin={false} />}
          />

          {/* More routes for admin pages */}
        </Routes>
      </div>
    </div>
  );
};

export default AdminPage;
