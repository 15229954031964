import React, { useState } from "react";
import axios from "axios";
import "./IdCardPhotoUpload.scss";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const IdCardPhotoUpload = () => {
  const [rollNo, setRollNo] = useState("");
  const [className, setClassName] = useState("");
  const [sirName, setSirName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [studentName, setStudentName] = useState("");
  const [photo, setPhoto] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [errorMSG, seterrorMSG] = useState("");

  const classes = Array.from(new Array(7), (val, index) => index + 6);

  const handleCapture = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Resize image if necessary, then set to state
      resizeImage(file, (resizedFile) => {
        setPhoto(resizedFile);
      });
    }
  };

  const resizeImage = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement("img");
      img.src = e.target.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxSideLength = 1024; // Resize to max side length
        let { width, height } = img;

        if (width > height && width > maxSideLength) {
          height *= maxSideLength / width;
          width = maxSideLength;
        } else if (height > maxSideLength) {
          width *= maxSideLength / height;
          height = maxSideLength;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob((blob) => {
          callback(new File([blob], file.name, { type: file.type }));
        }, file.type);
      };
    };
    reader.readAsDataURL(file);
  };

  const verifyRollNo = async () => {
    if (!rollNo || !className) {
      seterrorMSG("Please enter both roll number and class.");
      return;
    }

    try {
      const response = await axios.post(`${BE_URL}/api/general/verifyRollNo`, {
        rollNo,
        className: className,
      });
      const { data } = response;
      if (data && data.name) {
        setStudentName(data.name);
        setIsVerified(true);
        seterrorMSG("");
      } else {
        seterrorMSG("Roll number not found or not verified.");
        setIsVerified(false);
      }
    } catch (error) {
      console.error("Error verifying roll number:", error);
      seterrorMSG("Error verifying roll number. Please try again.");
      setIsVerified(false);
    }
  };

  const uploadPhoto = async () => {
    if (!rollNo || !className || !photo) {
      seterrorMSG("Please verify the details and capture the photo.");
      return;
    }

    const formData = new FormData();
    formData.append("roll_no", rollNo);
    formData.append("student_name", studentName);
    formData.append("class", parseInt(className));
    formData.append("photo", photo);

    setIsUploading(true);

    try {
      await axios.post(`${BE_URL}/api/general/uploadIdPhoto`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert("Photo uploaded successfully");
      setIsUploading(false);
      //   setStudentName(null);
      //   setPhoto(null);
      //   setRollNo(null);
      //   setClassName(null);
      //   setIsVerified(false);
    } catch (error) {
      console.error("Error uploading photo:", error);
      seterrorMSG("Error uploading photo. Please try again.");
      setIsUploading(false);
    }
  };

  return (
    <div className="idCardPhotoUpload">
      <div className="form">
        <h2>Upload ID Card Photo</h2>
        <h6 className="instruction">
          Please upload a clear image of your face.
        </h6>
        {errorMSG && <div className="error">{errorMSG}</div>}
        <input
          type="text"
          placeholder="Phal060004"
          value={rollNo}
          onChange={(e) => setRollNo(e.target.value)}
        />
        <select
          value={className}
          onChange={(e) => setClassName(e.target.value)}
        >
          <option value="">Select Class</option>
          {classes.map((cls) => (
            <option key={cls} value={cls}>
              Class {cls}
            </option>
          ))}
        </select>
        <button onClick={verifyRollNo}>Verify</button>
        {isVerified && (
          <>
            <p className="student-verified">{studentName} - Verified</p>
            <input
              type="file"
              accept="image/*"
              capture="environment"
              onChange={handleCapture}
            />
            {photo && (
              <div className="preview">
                <img src={URL.createObjectURL(photo)} alt="Preview" />
              </div>
            )}
            <button onClick={uploadPhoto} disabled={isUploading}>
              {isUploading ? "Uploading..." : "Upload Photo"}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default IdCardPhotoUpload;

// import React, { useState } from "react";
// import axios from "axios";
// import "./IdCardPhotoUpload.scss";

// const BE_URL = "http://localhost:8000";
// // const BE_URL = "https://server.classerp.in";

// const IdCardPhotoUpload = () => {
//   const [rollNo, setRollNo] = useState("");
//   const [sirName, setSirName] = useState("");
//   const [fatherName, setFatherName] = useState("");
//   const [studentName, setStudentName] = useState("");
//   const [className, setClassName] = useState("");
//   const [photo, setPhoto] = useState(null);
//   const [isUploading, setIsUploading] = useState(false);
//   const [errorMSG, seterrorMSG] = useState("");

//   const handleCapture = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       // Resize image if necessary, then set to state
//       resizeImage(file, (resizedFile) => {
//         setPhoto(resizedFile);
//       });
//     }
//   };

//   const resizeImage = (file, callback) => {
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       const img = document.createElement("img");
//       img.src = e.target.result;
//       img.onload = () => {
//         const canvas = document.createElement("canvas");
//         const maxSideLength = 1024; // Resize to max side length
//         let { width, height } = img;

//         if (width > height && width > maxSideLength) {
//           height *= maxSideLength / width;
//           width = maxSideLength;
//         } else if (height > maxSideLength) {
//           width *= maxSideLength / height;
//           height = maxSideLength;
//         }

//         canvas.width = width;
//         canvas.height = height;
//         const ctx = canvas.getContext("2d");
//         ctx.drawImage(img, 0, 0, width, height);
//         canvas.toBlob((blob) => {
//           callback(new File([blob], file.name, { type: file.type }));
//         }, file.type);
//       };
//     };
//     reader.readAsDataURL(file);
//   };

//   const uploadPhoto = async () => {
//     if (
//       !rollNo ||
//       !sirName ||
//       !fatherName ||
//       !studentName ||
//       !className ||
//       !photo
//     ) {
//       seterrorMSG("Please fill all the fields and capture the photo.");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("roll_no", rollNo);
//     formData.append("sir_name", sirName);
//     formData.append("father_name", fatherName);
//     formData.append("student_name", studentName);
//     formData.append("class", className);
//     formData.append("photo", photo);

//     setIsUploading(true);

//     try {
//       const response = await axios.post(
//         `${BE_URL}/api/general/uploadIdPhoto`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       alert("Photo uploaded successfully");
//     } catch (error) {
//       console.error("Error uploading photo:", error);
//       seterrorMSG("Error uploading photo. Please try again.");
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   return (
//     <div className="idCardPhotoUpload">
//       <div className="form">
//         <h2>Upload ID Card Photo</h2>
//         {errorMSG && <div className="error">{errorMSG}</div>}
//         <input
//           type="text"
//           placeholder="Roll Number (e.g., Bara061209)"
//           value={rollNo}
//           onChange={(e) => setRollNo(e.target.value)}
//         />
//         <input
//           type="text"
//           placeholder="Surname (e.g., Patil)"
//           value={sirName}
//           onChange={(e) => setSirName(e.target.value)}
//         />
//         <input
//           type="text"
//           placeholder="Father's Name (e.g., Vijay)"
//           value={fatherName}
//           onChange={(e) => setFatherName(e.target.value)}
//         />
//         <input
//           type="text"
//           placeholder="Student's Name (e.g., Rohan)"
//           value={studentName}
//           onChange={(e) => setStudentName(e.target.value)}
//         />
//         <input
//           type="text"
//           placeholder="Class (e.g., 11)"
//           value={className}
//           onChange={(e) => setClassName(e.target.value)}
//         />
//         <input
//           type="file"
//           accept="image/*"
//           capture="environment"
//           onChange={handleCapture}
//         />
//         {photo && (
//           <div className="preview">
//             <img src={URL.createObjectURL(photo)} alt="Preview" />
//           </div>
//         )}
//         <button onClick={uploadPhoto} disabled={isUploading}>
//           {isUploading ? "Uploading..." : "Upload Photo"}
//         </button>
//       </div>
//       <p>
//         Please make sure the roll number follows the format similar to
//         "Bara060009".
//       </p>
//     </div>
//   );
// };

// export default IdCardPhotoUpload;
