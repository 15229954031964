import React, { useEffect, useState } from "react";
import "./InstallmentsWithDiscountsDisplay.scss";
import CustomTextInput from "../CustomTextInput/CustomTextInput";
import CustomNumberInput from "../CustomNumberInput/CustomNumberInput";
import Button from "../Button/Button";
import axios from "axios";
import DropDown from "../DropDown/DropDown";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const InstallmentsWithDiscountsDisplay = ({
  title,
  fee,
  scholarshipAmount = 0,
  additial_amount = 0,
  installments,
  setInstallments,
}) => {
  const [accounts, setaccounts] = useState([]);
  const [tot_fee, settot_fee] = useState(fee);
  const [finalFee, setfinalFee] = useState();

  useEffect(() => {
    settot_fee(fee);
    setfinalFee(
      Number(fee) - Number(scholarshipAmount) - Number(additial_amount)
    );
  }, [fee]);

  const fetchcategories = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(
        `${BE_URL}/api/general/categories/Accounts`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setaccounts(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch categories. Please try again later.");
      }
    } finally {
    }
  };

  useEffect(() => {
    fetchcategories();
  }, []);

  const handleAccountChange = (index, value) => {
    const acc = accounts.filter((item) => item.id === value);
    const newInstallments = (installments || []).map((inst, idx) => {
      if (idx === index) {
        return { ...inst, account: value };
      }
      return inst;
    });
    setInstallments(newInstallments);
  };

  useEffect(() => {
    const newfinalFee =
      Number(tot_fee) - Number(scholarshipAmount) - Number(additial_amount);

    setfinalFee(newfinalFee);
  }, [tot_fee]);

  const addDiscountRow = (index) => {
    const newInstallments = [...installments];
    if (!newInstallments[index].discount) {
      newInstallments[index].discount = { name: "", amount: null };
    }
    setInstallments(newInstallments);
  };

  const removeDiscountRow = (index) => {
    const newInstallments = [...installments];
    delete newInstallments[index].discount;
    setInstallments(newInstallments);
  };

  const handleInstallmentChange = (index, field, value) => {
    const newInstallments = installments.map((inst, idx) =>
      idx === index ? { ...inst, [field]: value } : inst
    );
    setInstallments(newInstallments);
  };

  const handleDiscountChange = (index, field, value) => {
    const newInstallments = [...installments];
    newInstallments[index].discount[field] = value;
    setInstallments(newInstallments);
  };

  const removeInstallmentRow = (indexToRemove) => {
    const installmentToRemove = installments[indexToRemove];
    if (installmentToRemove) {
      // Subtract the installment's amount (minus any discounts) from the total fee
      const discountAmount = installmentToRemove.discount
        ? installmentToRemove.discount.amount
        : 0;
      const netAmount = installmentToRemove.amount - discountAmount;
      settot_fee(tot_fee - netAmount);
    }

    const filteredInstallments = installments.filter(
      (_, index) => index !== indexToRemove
    );
    setInstallments(filteredInstallments);
  };

  const totalInstallments = installments.reduce((acc, curr) => {
    const discountAmount = curr.discount ? Number(curr.discount.amount) : 0;
    return acc + (Number(curr.amount) - discountAmount);
  }, 0);

  return (
    <div className="instDiscount">
      <div className="instDiscount-meta">
        <div className="instDiscount-meta-title">{title}</div>
        <div className="instDiscount-meta-content">
          <div>Total Fee: Rs {tot_fee}</div>
          <div>
            Scholarship Amount: Rs {scholarshipAmount ? scholarshipAmount : 0}
          </div>
          <div>
            Additional Discount: Rs {additial_amount ? additial_amount : 0}
          </div>
          <div>Final Fee: Rs {finalFee}</div>
          <div>Total Installments: Rs {totalInstallments}</div>
        </div>
      </div>

      <div className="instDiscount-row">
        <div className="instDiscount-row-header">
          <div className="instDiscount-row-header-name">Name</div>
          <div className="instDiscount-row-header-amount">Amount</div>
          <div className="instDiscount-row-header-discount">Discount</div>
          <div className="instDiscount-row-header-account">Account</div>
          <div className="instDiscount-row-header-due">Due on</div>
        </div>
      </div>

      {installments.map((inst, index) => (
        <div key={inst.id} className="instDiscount-row ">
          <div className="instDiscount-row-values">
            <div className="instDiscount-row-values-name">
              <CustomTextInput
                placeholder="Installment Name"
                value={inst.installment_name}
                onChange={(e) =>
                  handleInstallmentChange(
                    index,
                    "installment_name",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="instDiscount-row-values-amount">
              <CustomNumberInput
                placeholder="Amount"
                value={inst.amount}
                onChange={(e) =>
                  handleInstallmentChange(index, "amount", Number(e))
                }
              />
            </div>

            <div className="instDiscount-row-values-discount">
              {inst.discount && (
                <>
                  <div className="instDiscount-row-values-discount-name">
                    <CustomTextInput
                      placeholder="Discount Name"
                      value={inst.discount.name}
                      onChange={(e) =>
                        handleDiscountChange(index, "name", e.target.value)
                      }
                    />
                  </div>
                  <div className="instDiscount-row-values-discount-amount">
                    <CustomNumberInput
                      value={inst.discount.amount}
                      placeholder={`Amount`}
                      onChange={(e) =>
                        handleDiscountChange(index, "amount", Number(e))
                      }
                    />
                  </div>
                  <div className="instDiscount-row-values-discount-button">
                    <Button
                      type="minired"
                      text="X"
                      onClick={() => removeDiscountRow(index)}
                    />
                  </div>
                </>
              )}
              {!inst.discount && (
                <Button
                  text="Add Discount"
                  onClick={() => addDiscountRow(index)}
                  type={`mini`}
                />
              )}
            </div>
            <div className="instDiscount-row-values-account">
              <DropDown
                options={accounts.map((item) => item.name)}
                value={inst.account || ""}
                onChange={(e) => handleAccountChange(index, e)}
              />
            </div>
            <div className="instDiscount-row-values-due">
              <CustomTextInput
                type="date"
                placeholder="Due Date"
                value={inst.due_date ? inst.due_date.split("T")[0] : null}
                onChange={(e) =>
                  handleInstallmentChange(index, "due_date", e.target.value)
                }
              />
            </div>
            <div className="instDiscount-row-values-remove">
              <Button
                type="minired" // Adjust the button type as needed
                text="X"
                onClick={() => removeInstallmentRow(index)}
              />
            </div>
          </div>
        </div>
      ))}

      <div className="instDiscount-button">
        <Button
          type={`mini`}
          text="Add Installment"
          onClick={() =>
            setInstallments([
              ...installments,
              {
                installment_name: "",
                amount: "",
                due_date: new Date().toISOString().split("T")[0],
                discount: undefined,
              },
            ])
          }
        />
      </div>
      {finalFee !== totalInstallments && (
        <div className="instDiscount-conflict">
          The total of installments does not match the final fee.
        </div>
      )}
    </div>
  );
};

export default InstallmentsWithDiscountsDisplay;
