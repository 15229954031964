import React, { useState, useEffect } from "react";

import "./Roles.scss";
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Roles = () => {
  //RBAC Code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Management")
    ?.dropdowns.find((dd) => dd.name === "Roles")?.permissions;
  //RBAC Code block ends.

  // const API_BASE_URL = process.env.API_BASE_URL;

  const [selectedItem, setSelectedItem] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Fetch subjects from API
  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      setName(selectedItem.name);
      setDescription(selectedItem.description);
    }
  }, [selectedItem]);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/refreshToken`, {
        token: tokens.refreshToken,
      });

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchRoles = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/roles`, {
        // const response = await axios.get(
        //   "${BE_URL}/api/roles",
        //   {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setRoles(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchRoles(); // Retry fetching subjects with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch roles.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (newRole) => {
    setIsLoading(true);
    setError(null);
    setisAddModalOpen(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        `${BE_URL}/api/roles`,
        // `${BE_URL}/api/roles`,
        newRole,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      fetchRoles();
      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(newRole); // Retry adding subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else if (error.response && error.response.status === 400) {
        alert("Role already exists");
      } else {
        setresponseType("Error");
        setmsg("Failed to add. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
      setisAddModalOpen(false);
    }
  };

  const handleEdit = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.put(
        `${BE_URL}/api/roles/${selectedItem.id}`,
        // `${BE_URL}/api/roles/${selectedItem.id}`,
        {
          name: name,
          description: description,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Update the roles list with the edited role
      const updatedroles = roles.map((role) =>
        role.id === response.data.id ? response.data : role
      );
      setRoles(updatedroles);
      setSelectedItem(response.data);

      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleEdit();
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else if (error.response && error.response.status === 400) {
        alert("Role name already exists");
      } else {
        setresponseType("Error");
        setmsg("Failed to edit. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (hostelId) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(
        `${BE_URL}/api/roles/${selectedItem.id}`,
        // `${BE_URL}/api/roles/${selectedItem.id}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Remove the deleted subject from the roles list
      const updatedroles = roles.filter((sub) => sub.id !== hostelId);
      setRoles(updatedroles);
      setSelectedItem(null);

      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(hostelId); // Retry deleting subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="roles">
      {isLoading && <Loader />}
      <Modal isOpen={isResponseOpen} onClose={() => setisResponseOpen(false)}>
        <ResponseModal
          type={responseType}
          msg={msg}
          onClick={() => setisResponseOpen(false)}
        />
      </Modal>
      <div className="roles-header">
        <div className="roles-header-name">Roles</div>
        {/* <div className="roles-header-info">
          <div className="roles-header-info-card">
            <div className="roles-header-info-card-title">Total roles </div>
            <div className="roles-header-info-card-number">
              {parseInt(roles.length)}
            </div>
          </div>
          <div className="roles-header-info-card">
            <div className="roles-header-info-card-title">Total Capacity </div>
            <div className="roles-header-info-card-number">
              {parseInt(occupancy) + parseInt(vacency)}
            </div>
          </div>
          <div className="roles-header-info-card">
            <div className="roles-header-info-card-title">Vacent Beds</div>
            <div className="roles-header-info-card-number">
              {parseInt(vacency)}
            </div>
          </div>
          <div className="roles-header-info-card">
            <div className="roles-header-info-card-title">Occupied Beds </div>
            <div className="roles-header-info-card-number">
              {parseInt(occupancy)}
            </div>
          </div>
        </div> */}
      </div>
      <div className="roles-contents">
        <div className="roles-contents-list">
          <HalfList
            data={roles}
            fields={["id", "name", "description"]}
            setSelectedItem={setSelectedItem}
            isAddModalOpen={isAddModalOpen}
            can_create={permissions?.can_create}
            elem={<AddRole handleAdd={(newRole) => handleAdd(newRole)} />}
          />
          {error && <p className="error">{error}</p>}
        </div>
        <div className="roles-contents-details">
          {selectedItem ? (
            <div>
              <HalfDetails
                id={selectedItem.id}
                name={selectedItem.name}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                can_delete={permissions?.can_delete}
                can_update={permissions?.can_update}
              >
                <DetailsChild
                  id={selectedItem.id}
                  name={name}
                  description={description}
                  setName={setName}
                  setDescription={setDescription}
                ></DetailsChild>
              </HalfDetails>
            </div>
          ) : (
            <div className="roles-contents-details-noselection">
              Select an item to see details
            </div>
          )}
        </div>
      </div>
      <></>
    </div>
  );
};

export default Roles;

const AddRole = ({ handleAdd }) => {
  // const [total_floors, settotal_floors] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);

  const handleSubmit = () => {
    if (!name) {
      alert("Please fill in the Hostel Name");
      return;
    }
    const newRole = { name, description };
    handleAdd(newRole);
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add a Role</div>

      <CustomTextInput
        label={`Name`}
        placeholder=" Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      {/* <CustomTextInput
        placeholder="Total Floors"
        value={total_floors}
        onChange={(e) => settotal_floors(e.target.value)}
      /> */}
      <CustomTextInput
        label={`Description`}
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Button text="Add Role" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({ id, name, description, setName, setDescription }) => {
  const navigate = useNavigate();

  return (
    <div className="dc">
      <div className="dc-visit">
        <Button
          text={`Manage`}
          onClick={() => navigate(`/admin/roles/${id}`)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={name}
          label={`Name`}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={description}
          label={`Description`}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
    </div>
  );
};
