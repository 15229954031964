import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "./SalarySlips.scss";
import Loader from "../Loader/Loader";
import DropDown from "../DropDown/DropDown";
import Button from "../Button/Button";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const SalarySlips = ({ admin = false, userId = null }) => {
  const [salarySlips, setSalarySlips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-indexed

  const years = Array.from(new Array(10), (val, index) => currentYear - index);
  const months = Array.from(new Array(12), (val, index) => index + 1);

  // Decode user ID from token if not admin
  const getUserID = () => {
    if (!admin) {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const decodedToken = jwtDecode(tokens.accessToken);
      return decodedToken.user_id;
    }
    return userId;
  };

  const handleDownloadSalarySlip = async () => {
    if (!selectedMonth || !selectedYear) {
      alert("Month and year are required!");
      return;
    }

    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `${BE_URL}/api/general/salarySlips/download`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
          params: {
            user_id: getUserID(),
            month: selectedMonth,
            year: selectedYear,
          },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "SalarySlip.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        alert("Salary slip not found for the selected month and year.");
        setIsLoading(false);
      } else {
        alert("Something went wrong. Please try again later.");
        console.error("Error downloading salary slip:", error);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="slips">
      {isLoading && <Loader />}
      <div className="slips-title">Salary Slips</div>
      <div className="slips-container">
        <div className="slips-container-dd">
          <DropDown
            label="Month"
            options={months}
            value={selectedMonth}
            onChange={setSelectedMonth}
          />
        </div>
        <div className="slips-container-dd">
          <DropDown
            label="Year"
            options={years}
            value={selectedYear}
            onChange={setSelectedYear}
          />
        </div>

        <Button
          type={`primary`}
          text={`Download`}
          onClick={handleDownloadSalarySlip}
        />
      </div>
    </div>
  );
};

export default SalarySlips;
