import "./Enquiry.scss";

import React, { useState } from "react";
import axios from "axios";

import TextField from "../../components/TextField/TextField";
import DropDown from "../../components/DropDown/DropDown";

import { ReactComponent as Seperator } from "../../assets/images/enquirySeperator.svg";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

/////////////////////////////////////////
//Submitted Message after submitting enquiry.
const SubmitMsg = () => {
  const navigate = useNavigate();

  const submitNavigate = () => {
    navigate("/about");
  };
  return (
    <div className="submitted">
      <div className="submitted-title">Submitted Successfully.</div>
      <div className="submitted-text">
        Thank you for submitting the Enquiry form. Our counsellors will get in
        touch with you soon. In the meantime, to know more about us, visit the
        link below.
      </div>
      <div className="submitted-cta">
        <Button text={`About Us`} onClick={submitNavigate} />
      </div>
    </div>
  );
};

/////////////////////////////////////////
//Enquiry Page
const Enquiry = () => {
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [whatsapp, setWhatsapp] = useState(null);
  const [alternateNo, setalternateNo] = useState(null);
  const [place, setPlace] = useState(null);
  const [school, setSchool] = useState(null);
  const [admclass, setAdmclass] = useState("Want Admission for Class");
  const [reference, setReference] = useState(null);

  //Other Exams
  const [otherExams, setOtherExams] = useState(null);
  const [exam1, setexam1] = useState(null);
  const [exam2, setexam2] = useState(null);
  const [exam3, setexam3] = useState(null);
  //   const [exam4, setexam4] = useState(null);
  const [exam1Marks, setexam1Marks] = useState(null);
  const [exam2Marks, setexam2Marks] = useState(null);
  const [exam3Marks, setexam3Marks] = useState(null);
  //   const [exam4Marks, setexam4Marks] = useState(null);

  const classOptions = [
    "Class 6th",
    "Class 7th",
    "Class 8th",
    "Class 9th",
    "Class 10th",
    "Class 11th",
    "Class 12th",
    "Neet Repeater",
  ];

  const refOptions = ["Facebook", "Newspaper", "Friend", "Website", "Others"];
  const examOptions = [
    "MHCET",
    "NTSE",
    "8th Std Scholarship exam",
    "5th Std Scholarship exam",
    "Olympiad",
    "Other",
  ];
  const examBool = ["Yes", "No"];

  const [error, setError] = useState("");
  const [invalidFields, setInvalidFields] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!name) errors.name = true;
    if (!email) errors.email = true;
    if (!whatsapp) errors.whatsapp = true;
    if (!alternateNo) errors.alternateNo = true;
    if (!place) errors.place = true;
    if (!school) errors.school = true;
    if (admclass === "Want Admission for Class") errors.admclass = true;
    if (!reference) errors.reference = true;

    setInvalidFields(errors);
    if (Object.keys(errors).length === 0) {
      setError("");
      return true;
    } else {
      setError(
        "Please fill in the fields marked in red.  लाल रंग असलेल्या जागी माहिती भरा"
      );
      return false;
    }
  };

  const handleSubmit = async () => {
    // alert("Server maintainence is progress. Please try again in 1 hour.");
    // return;
    if (validateForm()) {
      setIsSubmitting(true);
      setIsLoading(true);
      try {
        const response = await axios.post(
          "https://server.classerp.in/submit",
          // const response = await axios.post(
          //   "http://localhost:8000/submit",
          // const response = await axios.post(
          //   "https://ams-ki52.onrender.com/submit",
          {
            name,
            email,
            whatsapp,
            alternateNo,
            place,
            school,
            admclass,
            reference,
            otherExams,
            exam1,
            exam1Marks,
            exam2,
            exam2Marks,
            exam3,
            exam3Marks,
            //   exam4,
            //   exam4Marks,
          }
        );
        console.log("Form submitted:", response.data);
        setSubmitted(true);
      } catch (error) {
        console.error("Error:", error);
        setError("There was a problem submitting your form. Please try again.");
        alert("An error occured, please try again later.");
      } finally {
        setIsSubmitting(false); // Set isSubmitting back to false when submission ends

        setIsLoading(false); // Set loading state to false after API call completes
      }
    }
  };

  return (
    <>
      {submitted ? (
        <SubmitMsg />
      ) : (
        <div className="enquiry">
          {isLoading && <Loader />}
          <div className="enquiry-left">
            <div className="enquiry-left-content">
              <div className="enquiry-left-content-ams">AMS</div>
              <div className="enquiry-left-content-title">Enquiry</div>
              <div className="enquiry-left-content-sep"></div>
              <div className="enquiry-left-content-text">
                Do you have questions, or are you curious? Fill in the enquiry
                form and we will get in touch with you to answer all your
                questions and queries.
              </div>
            </div>
            <div className="enquiry-left-contact">
              <Link
                target="_blank"
                to={`tel:+917003419670`}
                className="enquiry-left-contact-detail"
              >
                <span>Call Us - 7003419670</span>
              </Link>
              <Link
                target="_blank"
                to={`https://wa.me/+917003419670`}
                className="enquiry-left-contact-detail"
              >
                <span>Whatsapp - 7003419670</span>
              </Link>
            </div>
          </div>
          <div className="enquiry-right">
            <div className="enquiry-right-title">Enquiry Form</div>
            <div className="enquiry-right-sep">
              <Seperator />
            </div>
            <div className="enquiry-right-fields">
              {/* <TextField
            label="Full Name"
            variant="outlined"
            margin="normal"
            className="enquiry-right-fields-field"
          />
          <TextField
            label="Email"
            variant="outlined"
            margin="normal"
            className="enquiry-right-fields-field"
          /> */}
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.name ? "invalid" : ""
                }`}
              >
                <TextField
                  placeholder={`Full Name`}
                  value={name}
                  onChange={setName}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.email ? "invalid" : ""
                }`}
              >
                <TextField
                  placeholder={`Email`}
                  value={email}
                  onChange={setEmail}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.whatsapp ? "invalid" : ""
                }`}
              >
                <TextField
                  placeholder={`WhatsApp`}
                  value={whatsapp}
                  onChange={setWhatsapp}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.alternateNo ? "invalid" : ""
                }`}
              >
                <TextField
                  placeholder={`Alternate No.`}
                  value={alternateNo}
                  onChange={setalternateNo}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.place ? "invalid" : ""
                }`}
              >
                <TextField
                  placeholder={`Place`}
                  value={place}
                  onChange={setPlace}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.school ? "invalid" : ""
                }`}
              >
                <TextField
                  placeholder={`School Name`}
                  value={school}
                  onChange={setSchool}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.admclass ? "invalid" : ""
                }`}
              >
                <DropDown
                  invalid={invalidFields.admclass}
                  options={classOptions}
                  label={`Want Admission for Class`}
                  value={admclass}
                  onChange={setAdmclass}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.reference ? "invalid" : ""
                }`}
              >
                <DropDown
                  invalid={invalidFields.reference}
                  options={refOptions}
                  label={`Reference`}
                  value={reference}
                  onChange={setReference}
                />
              </div>

              <div className="enquiry-right-fields-field">
                <DropDown
                  options={examBool}
                  label={`Other Competitive Exams`}
                  value={otherExams}
                  onChange={setOtherExams}
                />
              </div>
              <div className="enquiry-right-fields-field"></div>
              {otherExams === "Yes" ? (
                <>
                  <div className="enquiry-right-fields-field">
                    <DropDown
                      options={examOptions}
                      label={`Select Exam`}
                      value={exam1}
                      onChange={setexam1}
                    />
                  </div>
                  <div className="enquiry-right-fields-field">
                    <TextField
                      placeholder={`Exam Marks`}
                      value={exam1Marks}
                      onChange={setexam1Marks}
                    />
                  </div>

                  <div className="enquiry-right-fields-field">
                    <DropDown
                      options={examOptions}
                      label={`Select Exam`}
                      value={exam2}
                      onChange={setexam2}
                    />
                  </div>
                  <div className="enquiry-right-fields-field">
                    <TextField
                      placeholder={`Exam Marks`}
                      value={exam2Marks}
                      onChange={setexam2Marks}
                    />
                  </div>

                  <div className="enquiry-right-fields-field">
                    <DropDown
                      options={examOptions}
                      label={`Select Exam`}
                      value={exam3}
                      onChange={setexam3}
                    />
                  </div>
                  <div className="enquiry-right-fields-field">
                    <TextField
                      placeholder={`Exam Marks`}
                      value={exam3Marks}
                      onChange={setexam3Marks}
                    />
                  </div>

                  {/* <div className="enquiry-right-fields-field">
                    <DropDown
                      options={examOptions}
                      value={`Select Exam`}
                      onChange={setexam4}
                    />
                  </div>
                  <div className="enquiry-right-fields-field">
                    <TextField
                      placeholder={`Exam Marks`}
                      value={exam4Marks}
                      onChange={setexam4Marks}
                    />
                  </div> */}
                </>
              ) : (
                ""
              )}
            </div>
            {error && <div className="enquiry-right-error">{error}</div>}
            <div className="enquiry-right-button">
              {isSubmitting ? (
                <Button
                  text={`Submit`}
                  disabled={true}
                  onClick={handleSubmit}
                />
              ) : (
                <Button text={`Submit`} onClick={handleSubmit} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Enquiry;
