import React, { useState, useEffect } from "react";
import "./Resources.scss"; // Ensure you have this SCSS file in the same folder
import axios from "axios";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Resources = () => {
  const [documents, setDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchDocuments();
  }, []);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchDocuments = async () => {
    setLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await axios.get("http://localhost:8000/api/documents", {
      const response = await axios.get(`${BE_URL}/api/general/resources`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setDocuments(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchDocuments(); // Retry fetching documents with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch documents.");
      }
    } finally {
      setLoading(false);
    }
  };

  const filteredDocuments = documents.filter((doc) =>
    doc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderDocuments = () => {
    return documents
      .filter((doc) =>
        doc.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .map((doc) => (
        <div key={doc.id} className="document">
          <div className="document-name">{doc.name}</div>
          <div className="document-size">
            Size: {Number(doc.size_bytes) / 1000} Kbs
          </div>

          <a
            href={doc.s3_url}
            rel="noopener noreferrer"
            className="download-button"
          >
            Download
          </a>
        </div>
      ));
  };

  return (
    <div className="resources">
      <div className="resources-title">Resources</div>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search Resources..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="documents-container">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : (
          renderDocuments()
        )}
      </div>
    </div>
  );
};

export default Resources;
