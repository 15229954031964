import React from "react";
import "./CustomTimePicker.scss"; // Make sure to create this SCSS file

const CustomTimePicker = ({ value, onChange, label = "Select Time" }) => {
  // Function to handle time change
  const handleTimeChange = (event) => {
    const newTime = event.target.value;
    onChange(newTime);
  };

  return (
    <div className="custom-time-picker">
      <label htmlFor="time">{label}</label>
      <input
        type="time"
        name="time"
        value={value}
        onChange={handleTimeChange}
        className="time-input"
      />
    </div>
  );
};

export default CustomTimePicker;
