import React from "react";
import "./EntranceTable.scss";
import { useNavigate } from "react-router-dom";

const EntranceTable = ({ data, navigateBaseUrl }) => {
  const navigate = useNavigate();

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="entrance-table">
      {data.map((item) => (
        <div
          key={item.id}
          className={`entrance-row ${item.percent ? "entrance-row-green" : ""}`}
          onClick={() => navigate(`${navigateBaseUrl}/${item.roll_no}`)}
        >
          <div className="row-id">{item.roll_no}</div>
          <div className="row-name">{`${item.first_name} ${item.middle_name} ${item.last_name}`}</div>
          <div className="row-whatsapp">{item.aadhar}</div>
          <div className="row-class">{item.adm_class}</div>
          <div className="row-timestamp">
            {formatDateAndTime(item.timestamp)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EntranceTable;
