import "./UserProfile.scss";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import user from "../../assets/images/user.jpg";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import { clearUser } from "../../state/auth/authSlice";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";

import { Uploader, Avatar } from "rsuite";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

function UserProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userData, setUserData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [islogoutOpen, setIslogoutOpen] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  // const handleFileChange = (event) => {
  //   setSelectedFile(event.target.files[0]);
  // };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/profile`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setUserData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle token refresh here
        const refreshed = await refreshToken();
        if (refreshed) {
          fetchUserData(); // Retry fetching user data with the new token
        } else {
          // Handle failed refresh token scenario (e.g., redirect to login)
          handleLogout(); // Assuming you have a logout function
        }
      } else {
        console.error("Failed to fetch user data:", error);
        // Optionally set an error state to display an error message
      }
    }
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/refreshToken`, {
        refreshToken: tokens.refreshToken,
      });
      if (response.data.accessToken) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return true;
      } else {
        throw new Error("Failed to refresh token.");
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      localStorage.removeItem("ERPTokens"); // Clear tokens from storage
      dispatch(clearUser()); // Clear user data from Redux store
      navigate("/login"); // Redirect to login page
      return false;
    }
  };

  const handleFileChange = (fileInfo) => {
    console.log(fileInfo);
    if (fileInfo.length > 0) {
      const lastFile = fileInfo[fileInfo.length - 1];
      setSelectedFile(lastFile.blobFile);
    }
  };

  const handleProfilePicUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("profilePic", selectedFile);

      try {
        const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
        await axios.post(`${BE_URL}/upload-profile-pic`, formData, {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        });
        fetchUserData(); // Refresh user data to show updated profile picture
      } catch (error) {
        console.error("Error uploading profile picture:", error);
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("ERPTokens");
    dispatch(clearUser());
    navigate("/login");
  };

  return (
    <div className="profile">
      {/* Modal for changing password. */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ChangePassword onDone={closeModal} />
      </Modal>

      {/* Modal for confirming logout */}
      <Modal
        isOpen={islogoutOpen}
        onClose={() => {
          setIslogoutOpen(false);
        }}
      >
        <ConfirmationModal
          onConfirm={handleLogout}
          onCancel={() => {
            setIslogoutOpen(false);
          }}
          ques="Are you sure you want to logout?"
        />
      </Modal>
      <div className="profile-user">
        {/* User Info - Adjust according to your data structure */}
        <div className="profile-user-image">
          {userData?.image_url ? (
            <img
              src={userData.image_url}
              alt="Profile"
              className="user-profile-pic"
            />
          ) : (
            <Avatar circle src={user} size="lg" />
          )}
          <Uploader
            autoUpload={false}
            onChange={handleFileChange}
            accept="image/*"
          >
            <Button text="Select Image" type={`mini`} />
          </Uploader>
          <Button text={`Upload`} onClick={handleProfilePicUpload}></Button>
        </div>
        <div className="profile-user-details">
          <div className="profile-user-details-name">{userData?.name}</div>
          <div className="profile-user-details-email">
            Email: {userData?.email}
          </div>
          {/* <div className="profile-user-details-username">
            Username: {userData?.username}
          </div> */}
          <div className="profile-user-details-username">
            Phone: {userData?.phone_number}
          </div>
        </div>

        <div className="profile-user-buttons">
          <Button text="Change Password" type={`arrow`} onClick={openModal} />
          <Button
            text="Logout"
            type={`red`}
            onClick={() => {
              setIslogoutOpen(true);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default UserProfile;

const ConfirmationModal = ({ onConfirm, onCancel, ques }) => {
  return (
    <div className="cm">
      <div className="cm-ques">{ques}</div>
      <div className="cm-button">
        <Button text="Yes" onClick={onConfirm} type={`red`} />
        <Button text="No" onClick={onCancel} type={`arrow`} />
      </div>
    </div>
  );
};
