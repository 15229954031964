import React, { useState, useEffect } from "react";
import axios from "axios";
import "./StudentDashboard.scss";
import { useNavigate } from "react-router-dom";
import user from "../../assets/images/user.jpg";
import logo from "../../assets/images/logo.png";

import AnnouncementsDisplay from "../../components/AnnouncementsDisplay/AnnouncementsDisplay";
import AssignedInventory from "../../components/AssignedInventory/AssignedInventory";
import StudentFee from "../../components/StudentFee/StudentFee";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const StudentDashboard = () => {
  const [studentDetails, setStudentDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [timetable, setTimetable] = useState({ details: [] });
  const navigate = useNavigate();

  // Additional step: Determine today's day of the week
  const today = new Date().toLocaleString("en-us", { weekday: "long" });

  // Filter classes for today
  const todayClasses = timetable.details.filter(
    (classDetail) => classDetail.day_of_week === today
  );

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };
  const fetchTimetableDetails = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `${BE_URL}/api/general/studenttimetable`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setTimetable(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await refreshToken();
        fetchTimetableDetails(); // Retry after refreshing token
      } else {
        alert("Failed to load timetable details. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
        const response = await axios.get(`${BE_URL}/api/general/student`, {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        });

        if (response.data) {
          setStudentDetails(response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          const newTokens = await refreshToken();
          if (newTokens) {
            fetchStudentDetails();
          } else {
            alert("User logged out.");
            navigate("/login");
          }
        } else {
          console.error("Error fetching scholarship details:", error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchStudentDetails();
    fetchTimetableDetails();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="stdash">
      <div className="stdash-contents">
        <div className="stdash-contents-info">
          {/* <div className="stdash-contents-info-brand">
            <img src={logo} alt="Chaitanyas Academy Logo" />
            <div>Chaitanyas Academy</div>
          </div> */}
          <div className="stdash-contents-info-name">
            <h3>
              {studentDetails.first_name} {studentDetails.middle_name}{" "}
              {studentDetails.last_name}
            </h3>{" "}
          </div>
          <img
            src={studentDetails.photo || user}
            alt="student"
            className="student-photo"
          />
          <div className="stdash-contents-info-details">
            <div className="info-container">
              <div className="info-group">
                <div className="info-group-card">
                  <div className="info-group-card-header">Class </div>
                  <div className="info-group-card-info">
                    {studentDetails.adm_class} - {studentDetails.division}
                  </div>
                </div>
                <div className="info-group-card">
                  <div className="info-group-card-header">Roll No</div>
                  <div className="info-group-card-info">
                    {studentDetails.roll_no}
                  </div>
                </div>
              </div>
              <div className="info-group">
                {studentDetails.scholarship_amt && (
                  <div className="info-group-card">
                    <div className="info-group-card-header">Scholarship</div>
                    <div className="info-group-card-info">
                      &#x20B9; {studentDetails.scholarship_amt}
                    </div>
                  </div>
                )}
                {studentDetails.additial_amount && (
                  <div className="info-group-card">
                    <div className="info-group-card-header">Discount</div>
                    <div className="info-group-card-info">
                      &#x20B9; {studentDetails.additial_amount}
                    </div>
                  </div>
                )}
              </div>
              {studentDetails.hostel && (
                <div className="info-group-cardfull">
                  <div className="info-group-card-header">Hostel</div>
                  <div className="info-group-card-info">
                    {studentDetails.hostel_name}, {studentDetails.room_number},
                    Bed {studentDetails.bed_number}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="stdash-contents-classes">
          <div className="stdash-contents-classes-title">Classes Today</div>
          <div className="stdash-contents-classes-data">
            {todayClasses.length > 0 ? (
              todayClasses.map((entry, index) => (
                <div key={index} className="classCard">
                  <div className="classCard-time">{`${entry.start_time.slice(
                    0,
                    -3
                  )} - ${entry.end_time.slice(0, -3)}`}</div>
                  <div className="classCard-subject">{entry.sub_name}</div>
                  with
                  <div className="classCard-teacher">{entry.name}</div>
                </div>
              ))
            ) : (
              <p>No classes today.</p>
            )}
          </div>
        </div>
        <div className="stdash-contents-ancmnts">
          <div className="stdash-contents-ancmnts-title">Announcements</div>
          <div className="stdash-contents-ancmnts-title">
            <AnnouncementsDisplay />
          </div>
        </div>
        <div className="stdash-contents-exam">
          <div className="stdash-contents-exam-title">Assinged Inventory</div>
          <AssignedInventory type={"student"} />
        </div>
      </div>
      <div className="stdash-fee">
        <StudentFee />
      </div>
    </div>
  );
};

export default StudentDashboard;
