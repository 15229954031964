import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import "./SalaryUpload.scss";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const SalaryUpload = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Finance")
    ?.dropdowns.find((dd) => dd.name === "Salary Upload")?.permissions;
  //RBAC Code block ends.

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const downloadSampleExcel = async () => {
    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
    const response = await axios.get(`${BE_URL}/api/downloadSalaryExcel`, {
      headers: { Authorization: `Bearer ${tokens.accessToken}` },
      responseType: "blob", // Correct placement inside the configuration object
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "SampleSalaryUpload.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const uploadFile = async () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.post(`${BE_URL}/api/uploadSalary`, formData, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      alert("File uploaded successfully.");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file. Please try again.");
    }
  };

  return (
    <>
      {permissions?.can_read && (
        <div className="bulkSalaryUpload">
          <h2>Bulk Salary Upload</h2>
          <button className="downloadButton" onClick={downloadSampleExcel}>
            Download Sample Excel
          </button>
          <input type="file" onChange={handleFileChange} accept=".xlsx" />
          <button className="uploadButton" onClick={uploadFile}>
            Upload Excel File
          </button>

          <div className="instructions">
            <h3>Instructions</h3>
            <ul>
              <li>
                Please do not change the User Id, Name and Employee Id fields.
              </li>
              <li>Keep the dates in YYYY/MM/DD format. Eg. 2024/03/24.</li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default SalaryUpload;
