import React, { useState, useEffect } from "react";
// import SubjectForm from "./SubjectForm";
// import SubjectList from "./SubjectList";
import "./Divisions.scss"; // Assuming you have a corresponding SCSS file
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Divisions = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Students")
    ?.dropdowns.find((dd) => dd.name === "Divisions")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [division_name, setdivision_name] = useState(null);
  const [class_id, setclass_id] = useState(null);
  const [class_name, setclass_name] = useState(null);
  const [division_desc, setdivision_desc] = useState(null);
  const [divisions, setdivisions] = useState([]);
  const [teacher_id, setteacher_id] = useState(null);
  const [users, setusers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [classes, setClasses] = useState([]);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Fetch divisions from API
  useEffect(() => {
    fetchClasses();
    fetchdivisions();
    fetchUsers();
  }, []);

  useEffect(() => {
    if (!selectedItem) {
      return;
    }
    setdivision_name(selectedItem.division_name);
    setclass_id(selectedItem.class_id);
    setclass_name(selectedItem.class_name);
    setdivision_desc(selectedItem.division_desc);
    setteacher_id(selectedItem.teacher_id);
    console.log("Selected item changed ", selectedItem);
  }, [selectedItem]);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await axios.get("http://localhost:8000/getAllUsers", {
      const response = await axios.get(`${BE_URL}/api/general/getAllUsers`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });
      setusers(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Token expired, refresh token
        const newTokens = await refreshToken();
        if (newTokens) {
          return fetchUsers(); // Retry fetching users with new token
        } else {
          // Redirect to login or show an error
          alert("Session expired. Please login again.");
          // navigate("/login");
        }
      } else {
        console.error("Error fetching users:", error);
        alert("Failed to fetch user data. Please try again.");
      }
    }
    setIsLoading(false);
  };

  const fetchClasses = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(`${BE_URL}/api/classes`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
        },
      });
      setClasses(response.data); // Assuming the API returns an array of class objects
    } catch (error) {
      console.error("Error fetching classes:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch classes. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchdivisions = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await axios.get("http://localhost:8000/api/divisions", {
      const response = await axios.get(`${BE_URL}/api/divisions`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setdivisions(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchdivisions(); // Retry fetching divisions with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch divisions.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (newSubject) => {
    setIsLoading(true);
    setError(null);
    setisAddModalOpen(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/api/divisions`, newSubject, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      // Add the new subject to the divisions list
      fetchdivisions();
      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(newSubject); // Retry adding subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to add. Please try again.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
      setisAddModalOpen(false);
    }
  };

  const handleEdit = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.put(
        `${BE_URL}/api/divisions/${selectedItem.id}`,
        {
          division_name: division_name,
          class_id: class_id,
          division_desc: division_desc,
          teacher_id,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // Update the divisions list with the edited subject
      fetchdivisions();
      setSelectedItem(response.data);
      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleEdit(); // Retry editing subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to edit. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (subjectId) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(`${BE_URL}/api/divisions/${selectedItem.id}`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      // Remove the deleted subject from the divisions list
      const updateddivisions = divisions.filter((sub) => sub.id !== subjectId);
      setdivisions(updateddivisions);
      setSelectedItem(null);

      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(subjectId); // Retry deleting subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="divisions">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="divisions-header">
            <div className="divisions-header-name">Divisions</div>
          </div>
          <div className="divisions-contents">
            <div className="divisions-contents-list">
              <HalfList
                data={divisions}
                fields={["division_name", "class_name"]}
                setSelectedItem={setSelectedItem}
                handleAdd={handleAdd}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                elem={
                  <AddDivision
                    handleAdd={(newDivision) => handleAdd(newDivision)}
                    classes={classes}
                    users={users}
                  />
                }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="divisions-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={selectedItem.division_name}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    can_delete={permissions?.can_delete}
                    can_update={permissions?.can_update}
                  >
                    <DetailsChild
                      selectedItem={selectedItem}
                      division_name={division_name}
                      class_id={class_id}
                      class_name={class_name}
                      teacher_id={teacher_id}
                      division_desc={division_desc}
                      setdivision_name={setdivision_name}
                      setclass_id={setclass_id}
                      setclass_name={setclass_name}
                      setdivision_desc={setdivision_desc}
                      setteacher_id={setteacher_id}
                      classes={classes}
                      users={users}
                    ></DetailsChild>
                  </HalfDetails>
                </div>
              ) : (
                <div className="divisions-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Divisions;

const AddDivision = ({ handleAdd, classes, users }) => {
  const [division_name, setdivision_name] = useState(null);
  const [class_id, setclass_id] = useState(null);
  const [teacher_id, setteacher_id] = useState(null);
  const [class_val, setclass_val] = useState(null);
  const [division_desc, setdivision_desc] = useState(null);

  const handleSubmit = () => {
    if (!division_name || !class_id) {
      alert("Division Name and Class is mandatory!");
      return;
    }
    const newDivision = {
      class_id,
      division_name,
      division_desc,
      teacher_id,
    };
    handleAdd(newDivision);
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add a Division</div>
      <CustomTextInput
        placeholder="Division Name"
        value={division_name}
        onChange={(e) => setdivision_name(e.target.value)}
      />
      <ObjectDropDown
        label="Select Class"
        options={classes.map(({ class_name, id }) => ({
          label: id,
          value: class_name.toString(),
        }))}
        value={class_val}
        setId={setclass_id}
        setValue={setclass_val}
      />
      <CustomTextInput
        value={division_desc}
        label={`Division Description`}
        placeholder={`Division Description`}
        onChange={(e) => setdivision_desc(e.target.value)}
      />
      <CustomDropDown
        data={users}
        searchable={true}
        label="Teacher (Leaves Approver)"
        placeholder="Teacher"
        onChange={setteacher_id}
        value={teacher_id}
      />
      <Button text="Add Division" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({
  division_name,
  class_id,
  class_name,
  classes,
  teacher_id,
  division_desc,
  setdivision_name,
  setclass_id,
  setclass_name,
  setdivision_desc,
  setteacher_id,
  users,
}) => {
  console.log(division_name);

  return (
    <div className="dc">
      <div className="dc-input">
        <CustomTextInput
          value={division_name}
          label={`Division Name`}
          onChange={(e) => setdivision_name(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <ObjectDropDown
          label="Class"
          options={classes.map(({ class_name, id }) => ({
            label: id,
            value: class_name.toString(),
          }))}
          value={class_name}
          setId={setclass_id}
          setValue={setclass_name}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={division_desc}
          label={`Division Description`}
          placeholder={`Division Description`}
          onChange={(e) => setdivision_desc(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomDropDown
          data={users}
          searchable={true}
          label="Teacher (Leaves Approver)"
          placeholder="Teacher"
          onChange={setteacher_id}
          value={teacher_id}
        />
      </div>
    </div>
  );
};
