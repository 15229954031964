import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "axios";
import "./StudyApp.scss";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const StudyApp = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Students")
    ?.dropdowns.find((dd) => dd.name === "Study App")?.permissions;
  //RBAC Code block ends.

  const navigate = useNavigate();
  const [classes, setClasses] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null); // Track active accordion
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = () => {
    setIsLoading(true);
    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
    axios
      .get(`${BE_URL}/api/sma/folders`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      })
      .then((response) => {
        setClasses(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        alert("Something went wrong. Failed to fetch folders.");
        setError("Failed to fetch classes");
        setIsLoading(false);
      });
  };

  const handleAddFolder = async (classFolder) => {
    const folderName = prompt("Enter the name of the new subject folder:");
    if (!folderName) return; // Exit if no folder name is provided

    setIsLoading(true);

    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
    const path = `${classFolder}/${folderName}/`; // Construct the path where the folder will be added

    try {
      const response = await axios.post(
        `${BE_URL}/api/sma/folders-files/add-folder`,
        { folderPath: path },
        { headers: { Authorization: `Bearer ${tokens.accessToken}` } }
      );
      if (response.status === 200) {
        fetchClasses(); // Refresh the class list after adding a new folder
        alert("Folder added successfully.");
        setIsLoading(false);
      } else {
        throw new Error("Failed to add folder");
        setIsLoading(false);
      }
    } catch (error) {
      alert("Failed to add folder. Please try again later.");
      setError("Failed to add folder");
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleDelete = async (folderPath) => {
    if (!window.confirm("Are you sure you want to delete?")) {
      return;
    }
    setIsLoading(true);
    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
    try {
      const response = await axios.delete(
        `${BE_URL}/api/sma/folders-files/delete-object`,
        {
          data: { path: folderPath }, // Correctly placed inside the config object
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      if (response.status === 200) {
        fetchClasses(); // Refresh list after deletion
        setIsLoading(false);
      } else {
        throw new Error("Failed to delete folder");
        setIsLoading(false);
      }
    } catch (error) {
      alert("Failed to delete folder. Please try again later");
      setError("Failed to delete folder");
      setIsLoading(false);
      console.error(error);
    }
  };

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle or close if already open
  };

  return (
    <>
      {permissions?.can_read && (
        <div className="study">
          {isLoading && <Loader />}
          <div className="study-header">
            <div className="study-header-title">Study App Management</div>
          </div>
          <div className="study-contents">
            {classes.map((classFolder, index) => (
              <div key={index} className="study-contents-class">
                <div
                  className="study-contents-class-meta"
                  onClick={() => toggleAccordion(index)}
                >
                  <div className="study-contents-class-meta-name">
                    {classFolder.folder}
                  </div>
                  {permissions?.can_create && (
                    <div className="study-contents-class-meta-add">
                      <Button
                        text="Add Folder"
                        onClick={() => handleAddFolder(classFolder.folder)}
                        type="primary"
                      />
                    </div>
                  )}
                </div>
                <div className="study-contents-class-subjects">
                  {activeIndex === index ? (
                    <div className={`study-contents-class-subjects-list`}>
                      {classFolder.contents.map((subject) => (
                        <div
                          key={subject.folder}
                          className="subjects-list-item"
                        >
                          <div className="subjects-list-item-name">
                            {subject.folder}
                          </div>
                          <div className="subjects-list-item-buttons">
                            <Button
                              text="View Files"
                              type="argreen"
                              onClick={() =>
                                navigate("subjectFiles", {
                                  state: {
                                    classFolder: classFolder.folder,
                                    subjectFolder: subject.folder,
                                  },
                                })
                              }
                            />

                            {permissions?.can_delete && (
                              <Button
                                onClick={() =>
                                  handleDelete(
                                    `${classFolder.folder}/${subject.folder}/`
                                  )
                                }
                                text={`Delete`}
                                type={`arred`}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default StudyApp;
