// CustomDropdown.js
import React, { useState } from "react";
// import "./DropDown.scss";

import { ReactComponent as Arrow } from "../../assets/images/arrowdd.svg";

function ObjectDropDown({ invalid, options, value, setId, setValue, label }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  value = value === true ? "Yes" : value === false ? "No" : value;

  // Find the currently selected option based on the value
  const selectedOption = options.find((option) => option.value === value);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (id, value) => {
    setId(id);
    setValue(value);
    setIsOpen(false);
    setIsSelected(true);
  };

  return (
    <>
      <div className="dropdown-label">{label}</div>
      <div
        className={invalid ? "dropdown-invalid dropdown" : "dropdown"}
        onClick={handleToggle}
      >
        <div className={isSelected ? "selected-option" : "select-option"}>
          {value ? value : selectedOption ? selectedOption.value : label}
          {/* {value ? value : selectedOption ? selectedOption.value : label} */}
          <span className={`arrow ${isOpen ? "open" : ""}`}>
            <Arrow />
          </span>
        </div>
        {isOpen && (
          <div className="options">
            {options &&
              options.map((option, index) => (
                <div
                  key={index}
                  className="option"
                  onClick={() => handleOptionClick(option.label, option.value)} // Pass the value to the handler
                >
                  {option.value}
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
}

export default ObjectDropDown;
